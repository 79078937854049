import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Chart from "../../components/Chart";
import {
  getGatewayGroup,
  getGatewayGroupUpdates,
} from "../../actions/gatewayActions";
import { LinkContainer } from "react-router-bootstrap";
import moment from "moment";
import "moment-timezone";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";

function AdminBoilerGroupScreen() {
  const params = useParams();
  const productId = params.id;
  const groupId = params.group;
  const timeframe = params.timeframe;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [globalTimezone, setGlobalTimezone] = useGlobal("globalTimezone");
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);

  const handleChange = (date) => {
    setIsOpen(!isOpen);
    setStartDate(date);

    let real_date = formatDate(date);
    setDateSelected(true);
    dispatch(getGatewayGroup(productId, groupId, 1, real_date));
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const formatDate = (date) => {
    let real_date =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);

    return real_date;
  };

  const [data_0, setData_0] = useState([]);
  const [data_1, setData_1] = useState([]);
  const [data_2, setData_2] = useState([]);
  const [data_3, setData_3] = useState([]);
  const [data_4, setData_4] = useState([]);
  const [data_5, setData_5] = useState([]);
  const [data_6, setData_6] = useState([]);
  const [data_7, setData_7] = useState([]);
  const [data_8, setData_8] = useState([]);
  const [data_9, setData_9] = useState([]);
  const [data_10, setData_10] = useState([]);
  const [data_11, setData_11] = useState([]);

  const [data_0_max, set_Data_0_Max] = useState(0);
  const [data_1_max, set_Data_1_Max] = useState(0);
  const [data_2_max, set_Data_2_Max] = useState(0);
  const [data_3_max, set_Data_3_Max] = useState(0);
  const [data_4_max, set_Data_4_Max] = useState(0);
  const [data_5_max, set_Data_5_Max] = useState(0);
  const [data_6_max, set_Data_6_Max] = useState(0);
  const [data_7_max, set_Data_7_Max] = useState(0);
  const [data_8_max, set_Data_8_Max] = useState(0);
  const [data_9_max, set_Data_9_Max] = useState(0);
  const [data_10_max, set_Data_10_Max] = useState(0);
  const [data_11_max, set_Data_11_Max] = useState(0);

  const [data_0_min, set_Data_0_Min] = useState(0);
  const [data_1_min, set_Data_1_Min] = useState(0);
  const [data_2_min, set_Data_2_Min] = useState(0);
  const [data_3_min, set_Data_3_Min] = useState(0);
  const [data_4_min, set_Data_4_Min] = useState(0);
  const [data_5_min, set_Data_5_Min] = useState(0);
  const [data_6_min, set_Data_6_Min] = useState(0);
  const [data_7_min, set_Data_7_Min] = useState(0);
  const [data_8_min, set_Data_8_Min] = useState(0);
  const [data_9_min, set_Data_9_Min] = useState(0);
  const [data_10_min, set_Data_10_Min] = useState(0);
  const [data_11_min, set_Data_11_Min] = useState(0);

  const [data_name, setData_Name] = useState([]);

  const [data_unit, setData_Unit] = useState([]);

  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [timezone, setTimezone] = useState({});

  const [currentDate, setCurrentDate] = useState();

  useEffect(() => {
    const timeZoneFromStorage = localStorage.getItem("timeZone")
      ? JSON.parse(localStorage.getItem("timeZone"))
      : null;

    if (
      timeZoneFromStorage != null &&
      timezone.value != timeZoneFromStorage.value
    ) {
      setTimezone(timeZoneFromStorage);
    }
  }, [globalTimezone]);

  const changeDatetimeByTimezone = (datetime, timezone) => {
    if (timezone == null) {
      timezone = "Europe/Moscow";
    }
    return moment.utc(datetime).tz(timezone).format();
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const gatewayGroup = useSelector((state) => state.gatewayGroup);
  const { loading, error, success, gatewaygroup } = gatewayGroup;

  const gatewayGroupUpdate = useSelector((state) => state.gatewayGroupUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    gatewaygroupupdate,
  } = gatewayGroupUpdate;

  useEffect(() => {
    if (updateSuccess) {
      setUpdateData(gatewaygroupupdate);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (success) {
      setUpdateData(gatewaygroup);
      setUpdating(true);
    }
  }, [success]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userInfo && userInfo.is_staff && !dateSelected) {
        console.log(dateSelected);
        dispatch(getGatewayGroupUpdates(productId, groupId, timeframe));
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [dateSelected]);

  useEffect(() => {
    if (userInfo && userInfo.is_staff) {
      dispatch(getGatewayGroup(productId, groupId, timeframe));
    } else if (!userInfo) {
      navigate("/login");
    } else if (!userInfo.is_staff) {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo, productId]);

  useEffect(() => {
    if (success) {
      let max_0 = 0;
      let min_0 = 0;

      let max_1 = 0;
      let min_1 = 0;

      let max_2 = 0;
      let min_2 = 0;

      let max_3 = 0;
      let min_3 = 0;

      let max_4 = 0;
      let min_4 = 0;

      let max_5 = 0;
      let min_5 = 0;

      let max_6 = 0;
      let min_6 = 0;

      let max_7 = 0;
      let min_7 = 0;

      let max_8 = 0;
      let min_8 = 0;

      let max_9 = 0;
      let min_9 = 0;

      let max_10 = 0;
      let min_10 = 0;

      let max_11 = 0;
      let min_11 = 0;

      let data_0 = [];
      let data_1 = [];
      let data_2 = [];
      let data_3 = [];
      let data_4 = [];
      let data_5 = [];
      let data_6 = [];
      let data_7 = [];
      let data_8 = [];
      let data_9 = [];
      let data_10 = [];
      let data_11 = [];

      let time_array_1 = [];
      let time_array_2 = [];
      let time_array_3 = [];
      let time_array_4 = [];
      let time_array_5 = [];
      let time_array_6 = [];
      let time_array_7 = [];
      let time_array_8 = [];
      let time_array_9 = [];
      let time_array_10 = [];
      let time_array_11 = [];
      let time_array_12 = [];

      let data_array_1 = [];
      let data_array_2 = [];
      let data_array_3 = [];
      let data_array_4 = [];
      let data_array_5 = [];
      let data_array_6 = [];
      let data_array_7 = [];
      let data_array_8 = [];
      let data_array_9 = [];
      let data_array_10 = [];
      let data_array_11 = [];
      let data_array_12 = [];

      let data_names = [];
      let data_units = [];

      let datas = gatewaygroup.datas;
      let iterator = 0;

      Object.keys(datas).forEach((key) => {
        let data_name = datas[key]["name"];
        let data_mult = datas[key]["multiplier"];
        let data_unit = datas[key]["unit"];

        let data_timestamps = datas[key]["time"];
        let data = datas[key]["data"];

        data_names.push(data_name);
        data_units.push(data_unit);

        data_timestamps.forEach((timestamp) => {
          if (iterator == 0) {
            time_array_1.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 1) {
            time_array_2.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 2) {
            time_array_3.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 3) {
            time_array_4.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 4) {
            time_array_5.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 5) {
            time_array_6.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 6) {
            time_array_7.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 7) {
            time_array_8.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 8) {
            time_array_9.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 9) {
            time_array_10.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 10) {
            time_array_11.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 11) {
            time_array_12.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          }
        });

        data.forEach((data_point) => {
          if (iterator == 0) {
            data_array_1.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_0) {
              max_0 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_0) {
              min_0 = data_point[0] * data_mult;
            }
          } else if (iterator == 1) {
            data_array_2.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_1) {
              max_1 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_1) {
              min_1 = data_point[0] * data_mult;
            }
          } else if (iterator == 2) {
            data_array_3.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_2) {
              max_2 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_2) {
              min_2 = data_point[0] * data_mult;
            }
          } else if (iterator == 3) {
            data_array_4.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_3) {
              max_3 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_3) {
              min_3 = data_point[0] * data_mult;
            }
          } else if (iterator == 4) {
            data_array_5.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_4) {
              max_4 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_4) {
              min_4 = data_point[0] * data_mult;
            }
          } else if (iterator == 5) {
            data_array_6.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_5) {
              max_5 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_5) {
              min_5 = data_point[0] * data_mult;
            }
          } else if (iterator == 6) {
            data_array_7.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_6) {
              max_6 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_6) {
              min_6 = data_point[0] * data_mult;
            }
          } else if (iterator == 7) {
            data_array_8.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_7) {
              max_7 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_7) {
              min_7 = data_point[0] * data_mult;
            }
          } else if (iterator == 8) {
            data_array_9.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_8) {
              max_8 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_8) {
              min_8 = data_point[0] * data_mult;
            }
          } else if (iterator == 9) {
            data_array_10.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_9) {
              max_9 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_9) {
              min_9 = data_point[0] * data_mult;
            }
          } else if (iterator == 10) {
            data_array_11.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_10) {
              max_10 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_10) {
              min_10 = data_point[0] * data_mult;
            }
          } else if (iterator == 11) {
            data_array_12.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_11) {
              max_11 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_11) {
              min_11 = data_point[0] * data_mult;
            }
          }
        });
        iterator += 1;
      });

      for (let i = 0; i < data_array_1.length; i++) {
        let obj_0 = {};
        obj_0["name"] = time_array_1[i];
        obj_0[data_names[0] + " " + data_units[0]] = data_array_1[i];

        data_0.push(obj_0);
      }

      for (let i = 0; i < data_array_2.length; i++) {
        let obj_1 = {};
        obj_1["name"] = time_array_2[i];
        obj_1[data_names[1] + " " + data_units[1]] = data_array_2[i];

        data_1.push(obj_1);
      }

      for (let i = 0; i < data_array_3.length; i++) {
        let obj_2 = {};
        obj_2["name"] = time_array_3[i];
        obj_2[data_names[2] + " " + data_units[2]] = data_array_3[i];

        data_2.push(obj_2);
      }

      for (let i = 0; i < data_array_4.length; i++) {
        let obj_3 = {};
        obj_3["name"] = time_array_4[i];
        obj_3[data_names[3] + " " + data_units[3]] = data_array_4[i];

        data_3.push(obj_3);
      }

      for (let i = 0; i < data_array_5.length; i++) {
        let obj_4 = {};
        obj_4["name"] = time_array_5[i];
        obj_4[data_names[4] + " " + data_units[4]] = data_array_5[i];

        data_4.push(obj_4);
      }

      for (let i = 0; i < data_array_6.length; i++) {
        let obj_5 = {};
        obj_5["name"] = time_array_6[i];
        obj_5[data_names[5] + " " + data_units[5]] = data_array_6[i];

        data_5.push(obj_5);
      }

      for (let i = 0; i < data_array_7.length; i++) {
        let obj_6 = {};
        obj_6["name"] = time_array_7[i];
        obj_6[data_names[6] + " " + data_units[6]] = data_array_7[i];

        data_6.push(obj_6);
      }

      for (let i = 0; i < data_array_8.length; i++) {
        let obj_7 = {};
        obj_7["name"] = time_array_8[i];
        obj_7[data_names[7] + " " + data_units[7]] = data_array_8[i];

        data_7.push(obj_7);
      }

      for (let i = 0; i < data_array_9.length; i++) {
        let obj_8 = {};
        obj_8["name"] = time_array_9[i];
        obj_8[data_names[8] + " " + data_units[8]] = data_array_9[i];

        data_8.push(obj_8);
      }

      for (let i = 0; i < data_array_10.length; i++) {
        let obj_9 = {};
        obj_9["name"] = time_array_10[i];
        obj_9[data_names[9] + " " + data_units[9]] = data_array_10[i];

        data_9.push(obj_9);
      }

      for (let i = 0; i < data_array_11.length; i++) {
        let obj_10 = {};
        obj_10["name"] = time_array_11[i];
        obj_10[data_names[10] + " " + data_units[10]] = data_array_11[i];

        data_10.push(obj_10);
      }

      for (let i = 0; i < data_array_12.length; i++) {
        let obj_11 = {};
        obj_11["name"] = time_array_12[i];
        obj_11[data_names[11] + " " + data_units[11]] = data_array_12[i];

        data_11.push(obj_11);
      }

      setData_Name(data_names);
      setData_Unit(data_units);

      setData_0(data_0.reverse());
      setData_1(data_1.reverse());
      setData_2(data_2.reverse());
      setData_3(data_3.reverse());
      setData_4(data_4.reverse());
      setData_5(data_5.reverse());
      setData_6(data_6.reverse());
      setData_7(data_7.reverse());
      setData_8(data_8.reverse());
      setData_9(data_9.reverse());
      setData_10(data_10.reverse());
      setData_11(data_11.reverse());

      set_Data_0_Max(Math.ceil(max_0.toFixed(2)));
      set_Data_1_Max(Math.ceil(max_1.toFixed(2)));
      set_Data_2_Max(Math.ceil(max_2.toFixed(2)));
      set_Data_3_Max(Math.ceil(max_3.toFixed(2)));
      set_Data_4_Max(Math.ceil(max_4.toFixed(2)));
      set_Data_5_Max(Math.ceil(max_5.toFixed(2)));
      set_Data_6_Max(Math.ceil(max_6.toFixed(2)));
      set_Data_7_Max(Math.ceil(max_7.toFixed(2)));
      set_Data_8_Max(Math.ceil(max_8.toFixed(2)));
      set_Data_9_Max(Math.ceil(max_9.toFixed(2)));
      set_Data_10_Max(Math.ceil(max_10.toFixed(2)));
      set_Data_11_Max(Math.ceil(max_11.toFixed(2)));

      set_Data_0_Min(Math.floor(min_0.toFixed(2)));
      set_Data_1_Min(Math.floor(min_1.toFixed(2)));
      set_Data_2_Min(Math.floor(min_2.toFixed(2)));
      set_Data_3_Min(Math.floor(min_3.toFixed(2)));
      set_Data_4_Min(Math.floor(min_4.toFixed(2)));
      set_Data_5_Min(Math.floor(min_5.toFixed(2)));
      set_Data_6_Min(Math.floor(min_6.toFixed(2)));
      set_Data_7_Min(Math.floor(min_7.toFixed(2)));
      set_Data_8_Min(Math.floor(min_8.toFixed(2)));
      set_Data_9_Min(Math.floor(min_9.toFixed(2)));
      set_Data_10_Min(Math.floor(min_10.toFixed(2)));
      set_Data_11_Min(Math.floor(min_11.toFixed(2)));

      setCurrentDate(
        changeDatetimeByTimezone(
          gatewaygroup.latest_date[0],
          timezone.value
        ).substring(0, 10)
      );
    }
  }, [success, gatewaygroup, timezone]);

  useEffect(() => {
    if (updateSuccess) {
      let max_0 = 0;
      let min_0 = 0;

      let max_1 = 0;
      let min_1 = 0;

      let max_2 = 0;
      let min_2 = 0;

      let max_3 = 0;
      let min_3 = 0;

      let data_0 = [];
      let data_1 = [];
      let data_2 = [];
      let data_3 = [];

      let time_array_1 = [];
      let time_array_2 = [];
      let time_array_3 = [];
      let time_array_4 = [];

      let data_array_1 = [];
      let data_array_2 = [];
      let data_array_3 = [];
      let data_array_4 = [];

      let data_names = [];
      let data_units = [];

      let datas = gatewaygroupupdate.datas;
      let iterator = 0;

      Object.keys(datas).forEach((key) => {
        let data_name = datas[key]["name"];
        let data_mult = datas[key]["multiplier"];
        let data_unit = datas[key]["unit"];

        let data_timestamps = datas[key]["time"];
        let data = datas[key]["data"];

        data_names.push(data_name);
        data_units.push(data_unit);

        data_timestamps.forEach((timestamp) => {
          if (iterator == 0) {
            time_array_1.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 1) {
            time_array_2.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 2) {
            time_array_3.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          } else if (iterator == 3) {
            time_array_4.push(
              changeDatetimeByTimezone(timestamp[0], timezone.value).substring(
                11,
                19
              )
            );
          }
        });

        data.forEach((data_point) => {
          if (iterator == 0) {
            data_array_1.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_0) {
              max_0 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_0) {
              min_0 = data_point[0] * data_mult;
            }
          } else if (iterator == 1) {
            data_array_2.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_1) {
              max_1 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_1) {
              min_1 = data_point[0] * data_mult;
            }
          } else if (iterator == 2) {
            data_array_3.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_2) {
              max_2 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_2) {
              min_2 = data_point[0] * data_mult;
            }
          } else if (iterator == 3) {
            data_array_4.push((data_point[0] * data_mult).toFixed(2));
            if (data_point[0] * data_mult > max_3) {
              max_3 = data_point[0] * data_mult;
            }
            if (data_point[0] * data_mult < min_3) {
              min_3 = data_point[0] * data_mult;
            }
          }
        });
        iterator += 1;
      });

      for (let i = 0; i < data_array_1.length; i++) {
        let obj_0 = {};
        obj_0["name"] = time_array_1[i];
        obj_0[data_names[0] + " " + data_units[0]] = data_array_1[i];

        data_0.push(obj_0);
      }

      for (let i = 0; i < data_array_2.length; i++) {
        let obj_1 = {};
        obj_1["name"] = time_array_2[i];
        obj_1[data_names[1] + " " + data_units[1]] = data_array_2[i];

        data_1.push(obj_1);
      }

      for (let i = 0; i < data_array_3.length; i++) {
        let obj_2 = {};
        obj_2["name"] = time_array_3[i];
        obj_2[data_names[2] + " " + data_units[2]] = data_array_3[i];

        data_2.push(obj_2);
      }

      for (let i = 0; i < data_array_4.length; i++) {
        let obj_3 = {};
        obj_3["name"] = time_array_4[i];
        obj_3[data_names[3] + " " + data_units[3]] = data_array_4[i];

        data_3.push(obj_3);
      }

      setData_0(data_0.reverse());
      setData_1(data_1.reverse());
      setData_2(data_2.reverse());
      setData_3(data_3.reverse());

      set_Data_0_Max(Math.ceil(max_0.toFixed(2)));
      set_Data_1_Max(Math.ceil(max_1.toFixed(2)));
      set_Data_2_Max(Math.ceil(max_2.toFixed(2)));
      set_Data_3_Max(Math.ceil(max_3.toFixed(2)));

      set_Data_0_Min(Math.floor(min_0.toFixed(2)));
      set_Data_1_Min(Math.floor(min_1.toFixed(2)));
      set_Data_2_Min(Math.floor(min_2.toFixed(2)));
      set_Data_3_Min(Math.floor(min_3.toFixed(2)));

      setCurrentDate(
        changeDatetimeByTimezone(
          gatewaygroupupdate.latest_date[0],
          timezone.value
        ).substring(0, 10)
      );
    }
  }, [updateSuccess, gatewaygroupupdate, timezone]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        success && (
          <>
            <h1>
              {gatewaygroup.device_name}

              <LinkContainer to={`/staff/boiler/${productId}/overview`}>
                <Button variant="transparent" style={{ color: "red" }}>
                  <i className="fas fa-arrow-left"></i>
                </Button>
              </LinkContainer>
            </h1>

            {gatewaygroup.data_total == 0 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={12}>
                  <Message variant="danger">{"Data Bulunamadı"}</Message>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 1 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={12}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 2 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={12}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={12}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 3 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_2}
                    type={10}
                    data_name={data_name[2] + " " + data_unit[2]}
                    data_min={data_2_min}
                    data_max={data_2_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 4 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_2}
                    type={10}
                    data_name={data_name[2] + " " + data_unit[2]}
                    data_min={data_2_min}
                    data_max={data_2_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_3}
                    type={10}
                    data_name={data_name[3] + " " + data_unit[3]}
                    data_min={data_3_min}
                    data_max={data_3_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 5 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_2}
                    type={10}
                    data_name={data_name[2] + " " + data_unit[2]}
                    data_min={data_2_min}
                    data_max={data_2_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_3}
                    type={10}
                    data_name={data_name[3] + " " + data_unit[3]}
                    data_min={data_3_min}
                    data_max={data_3_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_4}
                    type={10}
                    data_name={data_name[4] + " " + data_unit[4]}
                    data_min={data_4_min}
                    data_max={data_4_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 6 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_2}
                    type={10}
                    data_name={data_name[2] + " " + data_unit[2]}
                    data_min={data_2_min}
                    data_max={data_2_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_3}
                    type={10}
                    data_name={data_name[3] + " " + data_unit[3]}
                    data_min={data_3_min}
                    data_max={data_3_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_4}
                    type={10}
                    data_name={data_name[4] + " " + data_unit[4]}
                    data_min={data_4_min}
                    data_max={data_4_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_5}
                    type={10}
                    data_name={data_name[5] + " " + data_unit[5]}
                    data_min={data_5_min}
                    data_max={data_5_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 7 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_2}
                    type={10}
                    data_name={data_name[2] + " " + data_unit[2]}
                    data_min={data_2_min}
                    data_max={data_2_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_3}
                    type={10}
                    data_name={data_name[3] + " " + data_unit[3]}
                    data_min={data_3_min}
                    data_max={data_3_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_4}
                    type={10}
                    data_name={data_name[4] + " " + data_unit[4]}
                    data_min={data_4_min}
                    data_max={data_4_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_5}
                    type={10}
                    data_name={data_name[5] + " " + data_unit[5]}
                    data_min={data_5_min}
                    data_max={data_5_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_6}
                    type={10}
                    data_name={data_name[6] + " " + data_unit[6]}
                    data_min={data_6_min}
                    data_max={data_6_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 8 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_2}
                    type={10}
                    data_name={data_name[2] + " " + data_unit[2]}
                    data_min={data_2_min}
                    data_max={data_2_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_3}
                    type={10}
                    data_name={data_name[3] + " " + data_unit[3]}
                    data_min={data_3_min}
                    data_max={data_3_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_4}
                    type={10}
                    data_name={data_name[4] + " " + data_unit[4]}
                    data_min={data_4_min}
                    data_max={data_4_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_5}
                    type={10}
                    data_name={data_name[5] + " " + data_unit[5]}
                    data_min={data_5_min}
                    data_max={data_5_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_6}
                    type={10}
                    data_name={data_name[6] + " " + data_unit[6]}
                    data_min={data_6_min}
                    data_max={data_6_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_7}
                    type={10}
                    data_name={data_name[7] + " " + data_unit[7]}
                    data_min={data_7_min}
                    data_max={data_7_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 9 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_2}
                    type={10}
                    data_name={data_name[2] + " " + data_unit[2]}
                    data_min={data_2_min}
                    data_max={data_2_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_3}
                    type={10}
                    data_name={data_name[3] + " " + data_unit[3]}
                    data_min={data_3_min}
                    data_max={data_3_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_4}
                    type={10}
                    data_name={data_name[4] + " " + data_unit[4]}
                    data_min={data_4_min}
                    data_max={data_4_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_5}
                    type={10}
                    data_name={data_name[5] + " " + data_unit[5]}
                    data_min={data_5_min}
                    data_max={data_5_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_6}
                    type={10}
                    data_name={data_name[6] + " " + data_unit[6]}
                    data_min={data_6_min}
                    data_max={data_6_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_7}
                    type={10}
                    data_name={data_name[7] + " " + data_unit[7]}
                    data_min={data_7_min}
                    data_max={data_7_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_8}
                    type={10}
                    data_name={data_name[8] + " " + data_unit[8]}
                    data_min={data_8_min}
                    data_max={data_8_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 10 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_2}
                    type={10}
                    data_name={data_name[2] + " " + data_unit[2]}
                    data_min={data_2_min}
                    data_max={data_2_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_3}
                    type={10}
                    data_name={data_name[3] + " " + data_unit[3]}
                    data_min={data_3_min}
                    data_max={data_3_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_4}
                    type={10}
                    data_name={data_name[4] + " " + data_unit[4]}
                    data_min={data_4_min}
                    data_max={data_4_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_5}
                    type={10}
                    data_name={data_name[5] + " " + data_unit[5]}
                    data_min={data_5_min}
                    data_max={data_5_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_6}
                    type={10}
                    data_name={data_name[6] + " " + data_unit[6]}
                    data_min={data_6_min}
                    data_max={data_6_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_7}
                    type={10}
                    data_name={data_name[7] + " " + data_unit[7]}
                    data_min={data_7_min}
                    data_max={data_7_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_8}
                    type={10}
                    data_name={data_name[8] + " " + data_unit[8]}
                    data_min={data_8_min}
                    data_max={data_8_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_9}
                    type={10}
                    data_name={data_name[9] + " " + data_unit[9]}
                    data_min={data_9_min}
                    data_max={data_9_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 11 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_2}
                    type={10}
                    data_name={data_name[2] + " " + data_unit[2]}
                    data_min={data_2_min}
                    data_max={data_2_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_3}
                    type={10}
                    data_name={data_name[3] + " " + data_unit[3]}
                    data_min={data_3_min}
                    data_max={data_3_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_4}
                    type={10}
                    data_name={data_name[4] + " " + data_unit[4]}
                    data_min={data_4_min}
                    data_max={data_4_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_5}
                    type={10}
                    data_name={data_name[5] + " " + data_unit[5]}
                    data_min={data_5_min}
                    data_max={data_5_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_6}
                    type={10}
                    data_name={data_name[6] + " " + data_unit[6]}
                    data_min={data_6_min}
                    data_max={data_6_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_7}
                    type={10}
                    data_name={data_name[7] + " " + data_unit[7]}
                    data_min={data_7_min}
                    data_max={data_7_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_8}
                    type={10}
                    data_name={data_name[8] + " " + data_unit[8]}
                    data_min={data_8_min}
                    data_max={data_8_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_9}
                    type={10}
                    data_name={data_name[9] + " " + data_unit[9]}
                    data_min={data_9_min}
                    data_max={data_9_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_10}
                    type={10}
                    data_name={data_name[10] + " " + data_unit[10]}
                    data_min={data_10_min}
                    data_max={data_10_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            {gatewaygroup.data_total == 12 && (
              <Row className="justify-content-md-center">
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_0}
                    type={10}
                    data_name={data_name[0] + " " + data_unit[0]}
                    data_min={data_0_min}
                    data_max={data_0_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_1}
                    type={10}
                    data_name={data_name[1] + " " + data_unit[1]}
                    data_min={data_1_min}
                    data_max={data_1_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_2}
                    type={10}
                    data_name={data_name[2] + " " + data_unit[2]}
                    data_min={data_2_min}
                    data_max={data_2_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_3}
                    type={10}
                    data_name={data_name[3] + " " + data_unit[3]}
                    data_min={data_3_min}
                    data_max={data_3_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_4}
                    type={10}
                    data_name={data_name[4] + " " + data_unit[4]}
                    data_min={data_4_min}
                    data_max={data_4_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_5}
                    type={10}
                    data_name={data_name[5] + " " + data_unit[5]}
                    data_min={data_5_min}
                    data_max={data_5_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_6}
                    type={10}
                    data_name={data_name[6] + " " + data_unit[6]}
                    data_min={data_6_min}
                    data_max={data_6_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_7}
                    type={10}
                    data_name={data_name[7] + " " + data_unit[7]}
                    data_min={data_7_min}
                    data_max={data_7_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_8}
                    type={10}
                    data_name={data_name[8] + " " + data_unit[8]}
                    data_min={data_8_min}
                    data_max={data_8_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_9}
                    type={10}
                    data_name={data_name[9] + " " + data_unit[9]}
                    data_min={data_9_min}
                    data_max={data_9_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_10}
                    type={10}
                    data_name={data_name[10] + " " + data_unit[10]}
                    data_min={data_10_min}
                    data_max={data_10_max}
                  ></Chart>
                </Col>
                <Col xs={12} lg={6}>
                  <Chart
                    data={data_11}
                    type={10}
                    data_name={data_name[11] + " " + data_unit[11]}
                    data_min={data_11_min}
                    data_max={data_11_max}
                  ></Chart>
                </Col>
              </Row>
            )}
            <Row>
              {timeframe <= 1
                ? !dateSelected && (
                    <Col xs={12}>
                      <LinkContainer
                        to={`/staff/boiler/${productId}/${groupId}/${
                          Number(timeframe) + 1
                        }`}
                      >
                        <Button variant="transparent" style={{ color: "red" }}>
                          <i className="fas fa-arrow-left"></i>
                        </Button>
                      </LinkContainer>
                    </Col>
                  )
                : !dateSelected && (
                    <Col xs={12}>
                      <LinkContainer
                        to={`/staff/boiler/${productId}/${groupId}/${
                          Number(timeframe) + 1
                        }`}
                      >
                        <Button variant="transparent" style={{ color: "red" }}>
                          <i className="fas fa-arrow-left"></i>
                        </Button>
                      </LinkContainer>
                      <LinkContainer
                        to={`/staff/boiler/${productId}/${groupId}/${
                          Number(timeframe) - 1
                        }`}
                      >
                        <Button
                          className="float-end"
                          variant="transparent"
                          style={{ color: "red" }}
                        >
                          <i className="fas fa-arrow-right"></i>
                        </Button>
                      </LinkContainer>
                    </Col>
                  )}
            </Row>
            <Row className="my-2">
              <Col xs={12} className="text-center">
                <Button variant="primary" onClick={handleClick}>
                  {format(startDate, "yyyy-MM-dd")}
                </Button>
                {isOpen && (
                  <DatePicker
                    selected={startDate}
                    onChange={handleChange}
                    dateFormat="yyyy-MM-dd"
                    inline
                  />
                )}
              </Col>
            </Row>
            {!dateSelected && (
              <div className="w-100 text-center">
                <h4>
                  {t("latest_date")}: {currentDate}
                </h4>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
}

export default AdminBoilerGroupScreen;
