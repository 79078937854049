import { Container } from "react-bootstrap";
import { Routes, Route, HashRouter as Router } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import HomeScreen from "./screens/static_screens/HomeScreen";

import LoginScreen from "./screens/user_screens/LoginScreen";
import RegisterScreen from "./screens/user_screens/RegisterScreen";

import UsersScreen from "./screens/user_screens/UsersScreen";
import UserEditScreen from "./screens/user_screens/UserEditScreen";

import AdminSensorsScreen from "./screens/sensor_screens/AdminSensorsScreen";
import UserSensorsScreen from "./screens/sensor_screens/UserSensorsScreen";
import AdminSensorDetailsScreen from "./screens/sensor_screens/AdminSensorDetailsScreen";
import UserSensorDetailsScreen from "./screens/sensor_screens/UserSensorDetailsScreen";
import AdminSensorEditScreen from "./screens/sensor_screens/AdminSensorEditScreen";
import UserSensorEditScreen from "./screens/sensor_screens/UserSensorEditScreen";
import AdminCreateSensorScreen from "./screens/sensor_screens/AdminCreateSensorScreen";
import UserCreateSensorScreen from "./screens/sensor_screens/UserCreateSensorScreen";
import BasicSensorScreen from "./screens/sensor_screens/BasicSensorScreen";

import AdminGatewaysScreen from "./screens/gateway_screens/AdminGatewaysScreen";
import AdminGatewayDetailsScreen from "./screens/gateway_screens/AdminGatewayDetailsScreen";
import AdminGatewayEditScreen from "./screens/gateway_screens/AdminGatewayEditScreen";
import AdminCreateGatewayScreen from "./screens/gateway_screens/AdminCreateGatewayScreen";
import AdminGatewayDiagramScreen from "./screens/gateway_screens/AdminGatewayDiagramScreen";
import AdminGatewayGroupScreen from "./screens/gateway_screens/AdminGatewayGroupScreen";
import UserGatewaysScreen from "./screens/gateway_screens/UserGatewaysScreen";
import UserGatewayDetailsScreen from "./screens/gateway_screens/UserGatewayDetailsScreen";
import UserGatewayEditScreen from "./screens/gateway_screens/UserGatewayEditScreen";
import UserCreateGatewayScreen from "./screens/gateway_screens/UserCreateGatewayScreen";
import UserGatewayDiagramScreen from "./screens/gateway_screens/UserGatewayDiagramScreen";
import UserGatewayGroupScreen from "./screens/gateway_screens/UserGatewayGroupScreen";

import AdminBoilersScreen from "./screens/boiler_screens/AdminBoilersScreen";
import UserBoilersScreen from "./screens/boiler_screens/UserBoilersScreen";
import AdminBoilerDiagramScreen from "./screens/boiler_screens/AdminBoilerDiagramScreen";
import UserBoilerDiagramScreen from "./screens/boiler_screens/UserBoilerDiagramScreen";
import AdminBoilerGroupScreen from "./screens/boiler_screens/AdminBoilerGroupScreen";
import UserBoilerGroupScreen from "./screens/boiler_screens/UserBoilerGroupScreen";
import AdminBoilerEditScreen from "./screens/boiler_screens/AdminBoilerEditScreen";
import UserBoilerEditScreen from "./screens/boiler_screens/UserBoilerEditScreen";
import AdminCreateBoilerScreen from "./screens/boiler_screens/AdminCreateBoilerScreen";
import UserCreateBoilerScreen from "./screens/boiler_screens/UserCreateBoilerScreen";
import AdminBoilerGUIScreen from "./screens/boiler_screens/AdminBoilerGUIScreen";
import UserBoilerGUIScreen from "./screens/boiler_screens/UserBoilerGUIScreen";

import AdminNeffesScreen from "./screens/neffes_screens/AdminNeffesScreen";
import AdminCreateNeffesScreen from "./screens/neffes_screens/AdminCreateNeffesScreen";
import AdminNeffesEditScreen from "./screens/neffes_screens/AdminNeffesEditScreen";
import AdminNeffesDetailsScreen from "./screens/neffes_screens/AdminNeffesDetailsScreen";
import UserNeffesScreen from "./screens/neffes_screens/UserNeffesScreen";
import UserCreateNeffesScreen from "./screens/neffes_screens/UserCreateNeffesScreen";
import UserNeffesEditScreen from "./screens/neffes_screens/UserNeffesEditScreen";
import UserNeffesDetailsScreen from "./screens/neffes_screens/UserNeffesDetailsScreen";

import AdminEIRsScreen from "./screens/eir_screens/AdminEIRsScreen";
import AdminEIREditScreen from "./screens/eir_screens/AdminEIREditScreen";
import AdminCreateEIRScreen from "./screens/eir_screens/AdminCreateEIRScreen";
import AdminEIRDetailsScreen from "./screens/eir_screens/AdminEIRDetailsScreen";
import UserEIRsScreen from "./screens/eir_screens/UserEIRsScreen";
import UserEIRDetailsScreen from "./screens/eir_screens/UserEIRDetailsScreen";
import UserEIREditScreen from "./screens/eir_screens/UserEIREditScreen";
import UserCreateEIRScreen from "./screens/eir_screens/UserCreateEIRScreen";

import AdminProjectsScreen from "./screens/service_screens/AdminProjectsScreen";
import UnitsScreen from "./screens/service_screens/UnitsScreen";
import UnitPictureScreen from "./screens/service_screens/UnitPictureScreen";
import AddPictureScreen from "./screens/service_screens/AddPictureScreen";
import UserProjectsScreen from "./screens/service_screens/UserProjectsScreen";
import CreateProjectScreen from "./screens/service_screens/CreateProjectScreen";
import CreateUnitScreen from "./screens/service_screens/CreateUnitScreen";
import UserCreateUnitScreen from "./screens/service_screens/UserCreateUnitScreen";

import ProductsScreen from "./screens/product_screens/ProductsScreen";
import SingleProductScreen from "./screens/product_screens/SingleProductScreen";

import ComplexSensorDetails from "./screens/demo_screens/ComplexSensorDetails";
import Co2SensorDetails from "./screens/demo_screens/Co2SensorDetails";


import PsychoTest from "./screens/test/psycho_test";


import { i18n } from "./translations/i18n";

function App() {
  return (
    <Router>
      <Header />
      <main className="py-3">
        <Container fluid className="px-5">
          <Routes>
            <Route path="/" element={<HomeScreen />} />


            <Route path="/test" element={<PsychoTest />} />


            <Route path="/login" element={<LoginScreen />} />
            <Route path="/register" element={<RegisterScreen />} />

            <Route path="/staff/users" element={<UsersScreen />} />
            <Route path="/staff/user/:id/edit" element={<UserEditScreen />} />
            <Route path="/staff/sensors" element={<AdminSensorsScreen />} />
            <Route path="/user/sensors" element={<UserSensorsScreen />} />
            <Route
              path="/staff/sensor/:id/details"
              element={<AdminSensorDetailsScreen />}
            />
            <Route
              path="/staff/sensor/:id/edit"
              element={<AdminSensorEditScreen />}
            />
            <Route
              path="/staff/createsensor"
              element={<AdminCreateSensorScreen />}
            />
            <Route
              path="/devices/:macid/simple"
              element={<BasicSensorScreen />}
            />
            <Route path="/staff/gateways" element={<AdminGatewaysScreen />} />
            <Route path="/staff/boilers" element={<AdminBoilersScreen />} />
            <Route
              path="/staff/gateway/:id/details"
              element={<AdminGatewayDetailsScreen />}
            />
            <Route
              path="/staff/gateway/:id/edit"
              element={<AdminGatewayEditScreen />}
            />
            <Route
              path="/staff/boiler/:id/edit"
              element={<AdminBoilerEditScreen />}
            />
            <Route
              path="/staff/creategateway"
              element={<AdminCreateGatewayScreen />}
            />
            <Route
              path="/staff/createboiler"
              element={<AdminCreateBoilerScreen />}
            />
            <Route
              path="/staff/gateway/:id/overview/"
              element={<AdminGatewayDiagramScreen />}
            />
            <Route
              path="/staff/boiler/:id/overview/"
              element={<AdminBoilerDiagramScreen />}
            />
            <Route
              path="/staff/gateway/:id/:group/:timeframe"
              element={<AdminGatewayGroupScreen />}
            />
            <Route
              path="/staff/boiler/:id/:group/:timeframe"
              element={<AdminBoilerGroupScreen />}
            />
            <Route
              path="/staff/boiler/:id/gui"
              element={<AdminBoilerGUIScreen />}
            />
            <Route
              path="/user/boiler/:id/gui"
              element={<UserBoilerGUIScreen />}
            />

            <Route
              path="/staff/neffes-devices"
              element={<AdminNeffesScreen />}
            />

            <Route
              path="/staff/neffes/:id/edit"
              element={<AdminNeffesEditScreen />}
            />

            <Route
              path="/staff/createneffes"
              element={<AdminCreateNeffesScreen />}
            />

            <Route
              path="/staff/neffes/:id/details"
              element={<AdminNeffesDetailsScreen />}
            />

            <Route path="/staff/projects" element={<AdminProjectsScreen />} />

            <Route path="/staff/projects/:id" element={<UnitsScreen />} />

            <Route
              path="/staff/projects/:projectid/:unitid"
              element={<UnitPictureScreen />}
            />

            <Route
              path="/staff/projects/:projectid/:unitid/createcomment"
              element={<AddPictureScreen />}
            />

            <Route
              path="/staff/createproject"
              element={<CreateProjectScreen />}
            />

            <Route
              path="/staff/:projectid/createunit"
              element={<CreateUnitScreen />}
            />

            <Route
              path="/user/:projectid/createunit"
              element={<UserCreateUnitScreen />}
            />

            <Route
              path="/user/sensor/:id/details"
              element={<UserSensorDetailsScreen />}
            />
            <Route
              path="/user/sensor/:id/edit"
              element={<UserSensorEditScreen />}
            />
            <Route
              path="/user/createsensor"
              element={<UserCreateSensorScreen />}
            />
            <Route path="/user/gateways" element={<UserGatewaysScreen />} />
            <Route path="/user/boilers" element={<UserBoilersScreen />} />
            <Route
              path="/user/gateway/:id/details"
              element={<UserGatewayDetailsScreen />}
            />
            <Route
              path="/user/gateway/:id/edit"
              element={<UserGatewayEditScreen />}
            />
            <Route
              path="/user/boiler/:id/edit"
              element={<UserBoilerEditScreen />}
            />
            <Route
              path="/user/creategateway"
              element={<UserCreateGatewayScreen />}
            />
            <Route
              path="/user/createboiler"
              element={<UserCreateBoilerScreen />}
            />
            <Route
              path="/user/gateway/:id/overview/"
              element={<UserGatewayDiagramScreen />}
            />
            <Route
              path="/user/boiler/:id/overview/"
              element={<UserBoilerDiagramScreen />}
            />
            <Route
              path="/user/gateway/:id/:group/:timeframe"
              element={<UserGatewayGroupScreen />}
            />
            <Route
              path="/user/boiler/:id/:group/:timeframe"
              element={<UserBoilerGroupScreen />}
            />

            <Route path="/user/neffes-devices" element={<UserNeffesScreen />} />
            <Route
              path="/user/createneffes"
              element={<UserCreateNeffesScreen />}
            />
            <Route
              path="/user/neffes/:id/edit"
              element={<UserNeffesEditScreen />}
            />
            <Route
              path="/user/neffes/:id/details"
              element={<UserNeffesDetailsScreen />}
            />

            <Route path="/staff/eir-devices" element={<AdminEIRsScreen />} />
            <Route
              path="/staff/eir/:id/edit"
              element={<AdminEIREditScreen />}
            />
            <Route path="/staff/createeir" element={<AdminCreateEIRScreen />} />
            <Route
              path="/staff/eir/:id/details"
              element={<AdminEIRDetailsScreen />}
            />

            <Route path="/user/eir-devices" element={<UserEIRsScreen />} />
            <Route path="/user/eir/:id/edit" element={<UserEIREditScreen />} />
            <Route path="/user/createeir" element={<UserCreateEIRScreen />} />
            <Route
              path="/user/eir/:id/details"
              element={<UserEIRDetailsScreen />}
            />

            <Route path="/user/projects" element={<UserProjectsScreen />} />
            <Route path="/user/projects/:id" element={<UnitsScreen />} />

            <Route
              path="/demo/complex-sensor"
              element={<ComplexSensorDetails />}
            />
            <Route path="/demo/co2-sensor" element={<Co2SensorDetails />} />
            <Route path="/products" element={<ProductsScreen />} />
            <Route path="/product/:id" element={<SingleProductScreen />} />
          </Routes>
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
