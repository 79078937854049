import {
  USER_PROJECT_REQUEST,
  USER_PROJECT_SUCCESS,
  USER_PROJECT_FAIL,
  USER_PROJECT_RESET,
  ADMIN_PROJECT_REQUEST,
  ADMIN_PROJECT_SUCCESS,
  ADMIN_PROJECT_FAIL,
  ADMIN_PROJECT_RESET,
  UNIT_REQUEST,
  UNIT_SUCCESS,
  UNIT_FAIL,
  UNIT_PICTURE_REQUEST,
  UNIT_PICTURE_SUCCESS,
  UNIT_PICTURE_FAIL,
} from "../constants/serviceConstants";

export const userProjectReducer = (state = { projects: {} }, action) => {
  switch (action.type) {
    case USER_PROJECT_REQUEST:
      return { loading: true, success: false };

    case USER_PROJECT_SUCCESS:
      return { loading: false, success: true, projects: action.payload };

    case USER_PROJECT_FAIL:
      return { loading: false, success: true, error: action.payload };

    case USER_PROJECT_RESET:
      return { projects: {} };

    default:
      return state;
  }
};

export const adminProjectReducer = (state = { projects: {} }, action) => {
  switch (action.type) {
    case ADMIN_PROJECT_REQUEST:
      return { loading: true, success: false };

    case ADMIN_PROJECT_SUCCESS:
      return { loading: false, success: true, projects: action.payload };

    case ADMIN_PROJECT_FAIL:
      return { loading: false, success: true, error: action.payload };

    case ADMIN_PROJECT_RESET:
      return { projects: {} };

    default:
      return state;
  }
};

export const unitReducer = (state = { units: {} }, action) => {
  switch (action.type) {
    case UNIT_REQUEST:
      return { loading: true, success: false };

    case UNIT_SUCCESS:
      return { loading: false, success: true, units: action.payload };

    case UNIT_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const unitPictureReducer = (state = { unitpictures: {} }, action) => {
  switch (action.type) {
    case UNIT_PICTURE_REQUEST:
      return { loading: true, success: false };

    case UNIT_PICTURE_SUCCESS:
      return { loading: false, success: true, unitpictures: action.payload };

    case UNIT_PICTURE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};
