import {
  USER_GATEWAY_REQUEST,
  USER_GATEWAY_SUCCESS,
  USER_GATEWAY_FAIL,
  USER_GATEWAY_RESET,
  ADMIN_GATEWAY_REQUEST,
  ADMIN_GATEWAY_SUCCESS,
  ADMIN_GATEWAY_FAIL,
  ADMIN_GATEWAY_RESET,
  GATEWAY_DATA_REQUEST,
  GATEWAY_DATA_SUCCESS,
  GATEWAY_DATA_FAIL,
  GATEWAY_UPDATE_REQUEST,
  GATEWAY_UPDATE_SUCCESS,
  GATEWAY_UPDATE_FAIL,
  GATEWAY_DIAGRAM_REQUEST,
  GATEWAY_DIAGRAM_SUCCESS,
  GATEWAY_DIAGRAM_FAIL,
  GATEWAY_DIAGRAM_UPDATE_REQUEST,
  GATEWAY_DIAGRAM_UPDATE_SUCCESS,
  GATEWAY_DIAGRAM_UPDATE_FAIL,
  GATEWAY_GROUP_REQUEST,
  GATEWAY_GROUP_SUCCESS,
  GATEWAY_GROUP_FAIL,
  GATEWAY_GROUP_UPDATE_REQUEST,
  GATEWAY_GROUP_UPDATE_SUCCESS,
  GATEWAY_GROUP_UPDATE_FAIL,
  USER_BOILER_REQUEST,
  USER_BOILER_SUCCESS,
  USER_BOILER_FAIL,
  USER_BOILER_RESET,
  ADMIN_BOILER_REQUEST,
  ADMIN_BOILER_SUCCESS,
  ADMIN_BOILER_FAIL,
  ADMIN_BOILER_RESET,
  BOILER_ERRORS_REQUEST,
  BOILER_ERRORS_SUCCESS,
  BOILER_ERRORS_FAIL,
  BOILER_ERRORS_RESET,
} from "../constants/gatewayConstants";

export const userGatewayReducer = (state = { gateways: {} }, action) => {
  switch (action.type) {
    case USER_GATEWAY_REQUEST:
      return { loading: true, success: false };

    case USER_GATEWAY_SUCCESS:
      return { loading: false, success: true, gateways: action.payload };

    case USER_GATEWAY_FAIL:
      return { loading: false, success: true, error: action.payload };

    case USER_GATEWAY_RESET:
      return { gateways: {} };

    default:
      return state;
  }
};

export const adminGatewayReducer = (state = { gateways: {} }, action) => {
  switch (action.type) {
    case ADMIN_GATEWAY_REQUEST:
      return { loading: true, success: false };

    case ADMIN_GATEWAY_SUCCESS:
      return { loading: false, success: true, gateways: action.payload };

    case ADMIN_GATEWAY_FAIL:
      return { loading: false, success: true, error: action.payload };

    case ADMIN_GATEWAY_RESET:
      return { gateways: {} };

    default:
      return state;
  }
};

export const gatewayDetailsReducer = (
  state = { gatewaydetails: {} },
  action
) => {
  switch (action.type) {
    case GATEWAY_DATA_REQUEST:
      return { loading: true, success: false };

    case GATEWAY_DATA_SUCCESS:
      return { loading: false, success: true, gatewaydetails: action.payload };

    case GATEWAY_DATA_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const gatewayUpdateReducer = (
  state = { gatewayupdates: {} },
  action
) => {
  switch (action.type) {
    case GATEWAY_UPDATE_REQUEST:
      return { loading: true, success: false };

    case GATEWAY_UPDATE_SUCCESS:
      return { loading: false, success: true, gatewayupdates: action.payload };

    case GATEWAY_UPDATE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const gatewayDiagramReducer = (
  state = { gatewaydiagram: {} },
  action
) => {
  switch (action.type) {
    case GATEWAY_DIAGRAM_REQUEST:
      return { loading: true, success: false };

    case GATEWAY_DIAGRAM_SUCCESS:
      return { loading: false, success: true, gatewaydiagram: action.payload };

    case GATEWAY_DIAGRAM_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const gatewayDiagramUpdateReducer = (
  state = { gatewaydiagramupdates: {} },
  action
) => {
  switch (action.type) {
    case GATEWAY_DIAGRAM_UPDATE_REQUEST:
      return { loading: true, success: false };

    case GATEWAY_DIAGRAM_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        gatewaydiagramupdates: action.payload,
      };

    case GATEWAY_DIAGRAM_UPDATE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const gatewayGroupReducer = (state = { gatewaygroup: {} }, action) => {
  switch (action.type) {
    case GATEWAY_GROUP_REQUEST:
      return { loading: true, success: false };

    case GATEWAY_GROUP_SUCCESS:
      return { loading: false, success: true, gatewaygroup: action.payload };

    case GATEWAY_GROUP_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const gatewayGroupUpdateReducer = (
  state = { gatewaygroupupdate: {} },
  action
) => {
  switch (action.type) {
    case GATEWAY_GROUP_UPDATE_REQUEST:
      return { loading: true, success: false };

    case GATEWAY_GROUP_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        gatewaygroupupdate: action.payload,
      };

    case GATEWAY_GROUP_UPDATE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const userBoilerReducer = (state = { boilers: {} }, action) => {
  switch (action.type) {
    case USER_BOILER_REQUEST:
      return { loading: true, success: false };

    case USER_BOILER_SUCCESS:
      return { loading: false, success: true, boilers: action.payload };

    case USER_BOILER_FAIL:
      return { loading: false, success: true, error: action.payload };

    case USER_BOILER_RESET:
      return { boilers: {} };

    default:
      return state;
  }
};

export const adminBoilerReducer = (state = { boilers: {} }, action) => {
  switch (action.type) {
    case ADMIN_BOILER_REQUEST:
      return { loading: true, success: false };

    case ADMIN_BOILER_SUCCESS:
      return { loading: false, success: true, boilers: action.payload };

    case ADMIN_BOILER_FAIL:
      return { loading: false, success: true, error: action.payload };

    case ADMIN_BOILER_RESET:
      return { boilers: {} };

    default:
      return state;
  }
};

export const boilerErrorsReducer = (state = { boilererrors: {} }, action) => {
  switch (action.type) {
    case BOILER_ERRORS_REQUEST:
      return { loading: true, success: false };

    case BOILER_ERRORS_SUCCESS:
      return { loading: false, success: true, boilererrors: action.payload };

    case BOILER_ERRORS_FAIL:
      return { loading: false, success: true, error: action.payload };

    case BOILER_ERRORS_RESET:
      return { boilers: {} };

    default:
      return state;
  }
};
