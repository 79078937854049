import {
  USER_NEFFES_REQUEST,
  USER_NEFFES_SUCCESS,
  USER_NEFFES_FAIL,
  USER_NEFFES_RESET,
  ADMIN_NEFFES_REQUEST,
  ADMIN_NEFFES_SUCCESS,
  ADMIN_NEFFES_FAIL,
  ADMIN_NEFFES_RESET,
  NEFFES_DATA_REQUEST,
  NEFFES_DATA_SUCCESS,
  NEFFES_DATA_FAIL,
  NEFFES_UPDATE_REQUEST,
  NEFFES_UPDATE_SUCCESS,
  NEFFES_UPDATE_FAIL,
  USER_NEFFES_GROUP_REQUEST,
  USER_NEFFES_GROUP_SUCCESS,
  USER_NEFFES_GROUP_FAIL,
  USER_NEFFES_GROUP_RESET,
  ADMIN_NEFFES_GROUP_REQUEST,
  ADMIN_NEFFES_GROUP_SUCCESS,
  ADMIN_NEFFES_GROUP_FAIL,
  ADMIN_NEFFES_GROUP_RESET,
  NEFFES_GROUP_DATA_REQUEST,
  NEFFES_GROUP_DATA_SUCCESS,
  NEFFES_GROUP_DATA_FAIL,
  NEFFES_GROUP_UPDATE_REQUEST,
  NEFFES_GROUP_UPDATE_SUCCESS,
  NEFFES_GROUP_UPDATE_FAIL,
} from "../constants/neffesConstants";
import axios from "axios";

export const getAdminNeffesDevices = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_NEFFES_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/neffes/neffes-admin/`, config);

    dispatch({
      type: ADMIN_NEFFES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_NEFFES_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserNeffes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_NEFFES_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/neffes/neffes-user/`, config);

    dispatch({
      type: USER_NEFFES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_NEFFES_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getNeffesDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEFFES_DATA_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/neffes/${id}/neffes-details/`,
      config
    );

    dispatch({
      type: NEFFES_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEFFES_DATA_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getNeffesUpdates = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEFFES_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/neffes/${id}/neffes-details/`,
      config
    );

    dispatch({
      type: NEFFES_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEFFES_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getAdminNeffesGroups = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_NEFFES_GROUP_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/neffes/neffes-group-admin/`, config);

    dispatch({
      type: ADMIN_NEFFES_GROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_NEFFES_GROUP_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserNeffesGroups = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_NEFFES_GROUP_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/neffes/neffes-group-user/`, config);

    dispatch({
      type: USER_NEFFES_GROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_NEFFES_GROUP_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getNeffesGroupDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEFFES_GROUP_DATA_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/neffes/${id}/neffes-group-details/`,
      config
    );

    dispatch({
      type: NEFFES_GROUP_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEFFES_GROUP_DATA_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getNeffesGroupUpdates = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEFFES_GROUP_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/neffes/${id}/neffes-group-details/`,
      config
    );

    dispatch({
      type: NEFFES_GROUP_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEFFES_GROUP_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
