import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { getSensorDetails } from "../../actions/deviceActions";
import FormContainer from "../../components/FormContainer";
import { useTranslation } from "react-i18next";

function UserSensorEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const productId = params.id;

  const [newName, setNewName] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const sensorDetails = useSelector((state) => state.sensorDetails);
  const { loading, error, success, sensordetails } = sensorDetails;

  useEffect(() => {
    if (userInfo && (!sensordetails || sensordetails.id != productId)) {
      dispatch(getSensorDetails(productId));
    } else if (!userInfo) {
      navigate("/login");
    }
    if (success) {
      setNewName(sensordetails.name);
    }
  }, [dispatch, navigate, userInfo, success, sensordetails, productId]);

  const editSensor = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const postData = {
        newName,
      };

      await axios.post(`/api/devices/${productId}/edit/`, postData, config);
    } catch (error) {}

    navigate(`/user/sensor/${productId}/details`);
  };

  return (
    <div>
      <h1>{t("sensor_details")}</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        success && (
          <FormContainer>
            <Form onSubmit={editSensor}>
              <Form.Group controlId="text">
                <Form.Label>{t("name")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("enter_name")}
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button className="mt-2" type="submit" variant="primary">
                {t("save")}
              </Button>
            </Form>
          </FormContainer>
        )
      )}
    </div>
  );
}

export default UserSensorEditScreen;
