import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { getAdminProjects } from "../../actions/serviceActions";
import { useTranslation } from "react-i18next";

function AdminProjectsScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const adminProjects = useSelector((state) => state.adminProjects);
  const { loading, error, success, projects } = adminProjects;

  useEffect(() => {
    if (userInfo && userInfo.is_staff) {
      dispatch(getAdminProjects());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  return (
    <div>
      <Row className="align-items-center">
        <Col>
          <h1>{t("projects")}</h1>
        </Col>
        <Col className="text-end">
          <LinkContainer to={"/staff/createproject"}>
            <Button className="my-3  w-100">
              <i className="fas fa-plus" style={{ color: "green" }}></i>
              {t("create_project")}
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      {!success ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped responsive hover className="table-sm">
          <thead>
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">{t("name")}</th>
              <th className="text-center">{t("comp_name")}</th>
              <th className="text-center">{t("details")}</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((sensor) => (
              <tr key={sensor.id}>
                <td className="align-middle text-center">{sensor.id}</td>
                <td className="align-middle text-center">{sensor.name}</td>
                <td className="align-middle text-center">
                  {sensor.customer.name}
                </td>
                <td className="align-middle text-center">
                  <LinkContainer to={`/staff/projects/${sensor.id}`}>
                    <Button
                      variant="transparent"
                      className="btn-sm"
                      style={{ color: "red" }}
                    >
                      <i className="fas fa-magnifying-glass"></i>
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default AdminProjectsScreen;
