import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Chart from "../../components/Chart";
import { getCO2Details, getCO2Updates } from "../../actions/demoActions";
import { LinkContainer } from "react-router-bootstrap";
import moment from "moment";
import "moment-timezone";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";

function Co2SensorDetails() {
  const params = useParams();
  const productId = params.id;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data_1, setData_1] = useState([]);
  const [data_2, setData_2] = useState([]);
  const [data_3, setData_3] = useState([]);
  const [data_4, setData_4] = useState([]);
  const [sensorPm, setSensorPm] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [timezone, setTimezone] = useState({});
  const [globalTimezone, setGlobalTimezone] = useGlobal("globalTimezone");

  useEffect(() => {
    const timeZoneFromStorage = localStorage.getItem("timeZone")
      ? JSON.parse(localStorage.getItem("timeZone"))
      : null;

    if (
      timeZoneFromStorage != null &&
      timezone.value != timeZoneFromStorage.value
    ) {
      setTimezone(timeZoneFromStorage);
    }
  }, [globalTimezone]);

  const changeDatetimeByTimezone = (datetime, timezone) => {
    if (timezone == null) {
      timezone = "Europe/Moscow";
    }
    return moment.utc(datetime).tz(timezone).format();
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const co2Details = useSelector((state) => state.co2Details);
  const { loading, error, success, co2details } = co2Details;

  const co2Updates = useSelector((state) => state.co2Updates);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    co2updates,
  } = co2Updates;

  useEffect(() => {
    if (updateSuccess) {
      setUpdateData(co2updates);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (success) {
      setUpdateData(co2details);
      setUpdating(true);
    }
  }, [success]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userInfo) {
        dispatch(getCO2Updates());
      }
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(getCO2Details());
  }, [dispatch, navigate, userInfo, productId]);

  useEffect(() => {
    if (success) {
      let data_1 = [];
      let data_2 = [];
      let data_3 = [];
      let data_4 = [];
      co2details.related_data.forEach((data) => {
        let obj_1 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          pm_1: Number(data.data_1),
          pm_25: Number(data.data_2),
          pm_10: Number(data.data_3),
        };
        let obj_2 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          co2: data.data_4,
        };
        let obj_3 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          sıcaklık: data.data_5,
        };
        let obj_4 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          nem: data.data_6,
        };

        data_1.push(obj_1);
        data_2.push(obj_2);
        data_3.push(obj_3);
        data_4.push(obj_4);
      });

      setData_1(data_1.reverse());
      setData_2(data_2.reverse());
      setData_3(data_3.reverse());
      setData_4(data_4.reverse());

      if (data_1[data_1.length - 1].pm_1 == 9999) {
        setSensorPm(false);
      } else {
        setSensorPm(true);
      }
    }
  }, [success, co2details, timezone]);

  useEffect(() => {
    if (updateSuccess) {
      let data_1 = [];
      let data_2 = [];
      let data_3 = [];
      let data_4 = [];
      co2updates.related_data.forEach((data) => {
        let obj_1 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          pm_1: Number(data.data_1),
          pm_25: Number(data.data_2),
          pm_10: Number(data.data_3),
        };
        let obj_2 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          co2: data.data_4,
        };
        let obj_3 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          sıcaklık: data.data_5,
        };
        let obj_4 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          nem: data.data_6,
        };

        data_1.push(obj_1);
        data_2.push(obj_2);
        data_3.push(obj_3);
        data_4.push(obj_4);
      });

      setData_1(data_1.reverse());
      setData_2(data_2.reverse());
      setData_3(data_3.reverse());
      setData_4(data_4.reverse());

      if (data_1[data_1.length - 1].pm_1 == 9999) {
        setSensorPm(false);
      } else {
        setSensorPm(true);
      }
    }
  }, [updateSuccess, co2updates, timezone]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        updating && (
          <>
            <h1>{t("demo_co2_sensor")}</h1>
            {sensorPm ? (
              <Row>
                <Col xs={12} lg={6}>
                  <Chart data={data_1} type={1} />
                </Col>
                <Col xs={12} lg={6}>
                  <Chart data={data_2} type={2} />
                </Col>
                <Col xs={12} lg={6}>
                  <Chart data={data_3} type={3} />
                </Col>
                <Col xs={12} lg={6}>
                  <Chart data={data_4} type={4} />
                </Col>
              </Row>
            ) : (
              <>
                <Row className="justify-content-md-center">
                  <Col xs={12} lg={6}>
                    <Chart data={data_2} type={2} />
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col xs={12} lg={6}>
                    <Chart data={data_3} type={3} />
                  </Col>
                  <Col xs={12} lg={6}>
                    <Chart data={data_4} type={4} />
                  </Col>
                </Row>
              </>
            )}
            <Table striped responsive hover className="table-sm">
              <thead>
                <tr>
                  {sensorPm ? (
                    <>
                      <th className="text-center">PM-1</th>
                      <th className="text-center">PM-2.5</th>
                      <th className="text-center">PM-10</th>
                      <th className="text-center">CO2</th>
                      <th className="text-center">{t("temperature")}</th>
                      <th className="text-center">{t("humidity")}</th>
                      <th className="text-center">{t("time_date")}</th>
                    </>
                  ) : (
                    <>
                      <th className="text-center">CO2</th>
                      <th className="text-center">{t("temperature")}</th>
                      <th className="text-center">{t("humidity")}</th>
                      <th className="text-center">{t("time_date")}</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {updateData.related_data.map((data) => (
                  <tr key={data.id}>
                    {sensorPm ? (
                      <>
                        <td className="align-middle text-center">
                          {data.data_1} {co2details.data_1_unit.data_unit}
                        </td>
                        <td className="align-middle text-center">
                          {data.data_2} {co2details.data_2_unit.data_unit}
                        </td>
                        <td className="align-middle text-center">
                          {data.data_3} {co2details.data_3_unit.data_unit}
                        </td>
                        <td className="align-middle text-center">
                          {data.data_4} {co2details.data_4_unit.data_unit}
                        </td>
                        <td className="align-middle text-center">
                          {data.data_5} {co2details.data_5_unit.data_unit}
                        </td>
                        <td className="align-middle text-center">
                          {data.data_6} {co2details.data_6_unit.data_unit}
                        </td>
                        <td className="align-middle text-center">
                          {changeDatetimeByTimezone(
                            data.posted,
                            timezone.value
                          ).substring(11, 19)}{" "}
                          -{" "}
                          {changeDatetimeByTimezone(
                            data.posted,
                            timezone.value
                          ).substring(0, 10)}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="align-middle text-center">
                          {data.data_4} {co2details.data_4_unit.data_unit}
                        </td>
                        <td className="align-middle text-center">
                          {data.data_5} {co2details.data_5_unit.data_unit}
                        </td>
                        <td className="align-middle text-center">
                          {data.data_6} {co2details.data_6_unit.data_unit}
                        </td>
                        <td className="align-middle text-center">
                          {changeDatetimeByTimezone(
                            data.posted,
                            timezone.value
                          ).substring(11, 19)}{" "}
                          -{" "}
                          {changeDatetimeByTimezone(
                            data.posted,
                            timezone.value
                          ).substring(0, 10)}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )
      )}
    </div>
  );
}

export default Co2SensorDetails;
