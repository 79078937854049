export const USER_PROJECT_REQUEST = "USER_PROJECT_REQUEST";
export const USER_PROJECT_SUCCESS = "USER_PROJECT_SUCCESS";
export const USER_PROJECT_FAIL = "USER_PROJECT_FAIL";
export const USER_PROJECT_RESET = "USER_PROJECT_RESET";

export const ADMIN_PROJECT_REQUEST = "ADMIN_PROJECT_REQUEST";
export const ADMIN_PROJECT_SUCCESS = "ADMIN_PROJECT_SUCCESS";
export const ADMIN_PROJECT_FAIL = "ADMIN_PROJECT_FAIL";
export const ADMIN_PROJECT_RESET = "ADMIN_PROJECT_RESET";

export const UNIT_REQUEST = "UNIT_REQUEST";
export const UNIT_SUCCESS = "UNIT_SUCCESS";
export const UNIT_FAIL = "UNIT_FAIL";

export const UNIT_PICTURE_REQUEST = "UNIT_PICTURE_REQUEST";
export const UNIT_PICTURE_SUCCESS = "UNIT_PICTURE_SUCCESS";
export const UNIT_PICTURE_FAIL = "UNIT_PICTURE_FAIL";
