import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getGatewayDiagram,
  getGatewayDiagramUpdates,
} from "../../actions/gatewayActions";
import Loader from "../../components/Loader";
import moment from "moment";
import "moment-timezone";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";
import ReactFlow, { useNodesState, useEdgesState } from "react-flow-renderer";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Row, Col } from "react-bootstrap";
import FlowChart from "../../components/FlowChart";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";
import Rinnai_Boiler_Component from "../../svgs/Rinnai_Boiler_Component";

function UserBoilerGUIScreen() {
  const params = useParams();
  const productId = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [globalTimezone, setGlobalTimezone] = useGlobal("globalTimezone");

  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [timezone, setTimezone] = useState({});
  const [dateSelected, setDateSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [dateString, setDateString] = useState("");
  const [excelCreation, setExcelCreation] = useState(false);

  useEffect(() => {
    const timeZoneFromStorage = localStorage.getItem("timeZone")
      ? JSON.parse(localStorage.getItem("timeZone"))
      : null;

    if (
      timeZoneFromStorage != null &&
      timezone.value != timeZoneFromStorage.value
    ) {
      setTimezone(timeZoneFromStorage);
    }
  }, [globalTimezone]);
  const changeDatetimeByTimezone = (datetime, timezone) => {
    if (timezone == null) {
      timezone = "Europe/Moscow";
    }
    return moment.utc(datetime).tz(timezone).format();
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const gatewayDiagram = useSelector((state) => state.gatewayDiagram);
  const { loading, error, success, gatewaydiagram } = gatewayDiagram;

  const gatewayDiagramUpdate = useSelector(
    (state) => state.gatewayDiagramUpdate
  );
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    gatewaydiagramupdates,
  } = gatewayDiagramUpdate;

  useEffect(() => {
    if (updateSuccess) {
      setUpdateData(gatewaydiagramupdates);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (success) {
      setUpdateData(gatewaydiagram);
      setUpdating(true);
    }
  }, [success]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userInfo && userInfo.is_staff) {
        dispatch(getGatewayDiagramUpdates(productId));
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userInfo) {
      dispatch(getGatewayDiagram(productId));
    } else if (!userInfo) {
      navigate("/login");
    } else if (!userInfo.is_staff) {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo, productId]);

  useEffect(() => {
    if (updating && success) {
      document.getElementById("data_0").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_1").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_2").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_3").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_4").style =
        "font-size: 6px; stroke-width: 0.3;";

      document.getElementById("data_7").style =
        "font-size: 6px; stroke-width: 0.3;";

      document.getElementById("data_11").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_12").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_13").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_14").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_15").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_16").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_17").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_18").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_19").style =
        "font-size: 6px; stroke-width: 0.3;";
      document.getElementById("data_0").innerHTML = updateData.data_arr[0];
      document.getElementById("data_1").innerHTML = updateData.data_arr[1];
      document.getElementById("data_2").innerHTML = updateData.data_arr[2];
      document.getElementById("data_3").innerHTML = updateData.data_arr[3];
      document.getElementById("data_4").innerHTML = (
        updateData.data_arr[4] * 0.1
      ).toFixed(1);

      if (updateData.data_arr[5] == "RUNNING") {
        document.getElementById("data_5_red").style = "display:none";
        document.getElementById("data_5_green").style = "";
      } else if (updateData.data_arr[5] == "STOPPED") {
        document.getElementById("data_5_green").style = "display:none";
        document.getElementById("data_5_red").style = "";
      }

      if (updateData.data_arr[6] == "RUNNING") {
        document.getElementById("data_6_red").style = "display:none";
        document.getElementById("data_6_green").style = "";
      } else if (updateData.data_arr[6] == "STOPPED") {
        document.getElementById("data_6_green").style = "display:none";
        document.getElementById("data_6_red").style = "";
      }

      document.getElementById("data_7").innerHTML =
        updateData.data_arr[7] + updateData.unit_arr[7];

      if (updateData.data_arr[8] == "RUNNING") {
        document.getElementById("data_8_red").style = "display:none";
        document.getElementById("data_8_green").style = "";
      } else if (updateData.data_arr[8] == "STOPPED") {
        document.getElementById("data_8_green").style = "display:none";
        document.getElementById("data_8_red").style = "";
      }

      if (updateData.data_arr[9] == "RUNNING") {
        document.getElementById("data_9_red").style = "display:none";
        document.getElementById("data_9_green").style = "";
      } else if (updateData.data_arr[9] == "STOPPED") {
        document.getElementById("data_9_green").style = "display:none";
        document.getElementById("data_9_red").style = "";
      }

      if (updateData.data_arr[10] == "RUNNING") {
        document.getElementById("data_10_red").style = "display:none";
        document.getElementById("data_10_green").style = "";
      } else if (updateData.data_arr[10] == "STOPPED") {
        document.getElementById("data_10_green").style = "display:none";
        document.getElementById("data_10_red").style = "";
      }
      document.getElementById("data_11").innerHTML = updateData.data_arr[11];
      document.getElementById("data_12").innerHTML =
        updateData.data_arr[12] + updateData.unit_arr[12];

      if (updateData.data_arr[12] > 0) {
        document.getElementById("data_12_img").style = "";
      } else {
        document.getElementById("data_12_img").style = "display:none";
      }

      document.getElementById("data_13").innerHTML =
        updateData.data_arr[13] + updateData.unit_arr[13];
      document.getElementById("data_14").innerHTML =
        updateData.data_arr[14] + updateData.unit_arr[14];
      document.getElementById("data_15").innerHTML = updateData.data_arr[15];
      document.getElementById("data_16").innerHTML = updateData.data_arr[16];
      document.getElementById("data_17").innerHTML = updateData.data_arr[17];
      document.getElementById("data_18").innerHTML = updateData.data_arr[18];
      document.getElementById("data_19").innerHTML = updateData.data_arr[19];

      let scale_x = (updateData.data_arr[7] * 0.01).toFixed(2);
      document.getElementById(
        "g57546"
      ).style.transform = `scale(${scale_x}, 1)`;
    }
  }, [updateData, updating, success]);

  const excelOutput = () => {
    fetchExcel();
    if (dateSelected) {
      console.log(dateString);
      axios({
        url: `/api/output/gateway/${productId}/excel_out/${dateString}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${gatewaydiagram.name + " " + dateString}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      });
    } else {
      let date_string = formatDate(startDate);
      setDateString(date_string);
      console.log(date_string);
      axios({
        url: `/api/output/gateway/${productId}/excel_out/${formatDate(
          startDate
        )}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${gatewaydiagram.name + "  " + date_string}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleChange = (date) => {
    setIsOpen(!isOpen);
    setStartDate(date);

    let real_date = formatDate(date);
    setDateSelected(true);
    setDateString(real_date);
  };

  const formatDate = (date) => {
    let real_date =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);

    return real_date;
  };

  const fetchExcel = () => {
    setExcelCreation(true);

    setTimeout(() => {
      setExcelCreation(false);
    }, 30000);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <Col>
              <h1>
                {gatewaydiagram.name}

                <LinkContainer to={`/user/boilers`}>
                  <Button variant="transparent" style={{ color: "red" }}>
                    <i className="fas fa-arrow-left"></i>
                  </Button>
                </LinkContainer>
              </h1>
            </Col>
            <Col className="text-end" xs={3}>
              {excelCreation ? (
                <Loader />
              ) : (
                <>
                  <Button
                    className="my-3"
                    variant="transparent"
                    style={{ color: "red" }}
                    onClick={(e) => excelOutput()}
                  >
                    <i
                      className="fas fa-file-excel"
                      style={{ color: "green" }}
                    ></i>
                  </Button>
                  <Button
                    className="my-3  w-75"
                    variant="primary"
                    onClick={handleClick}
                  >
                    {format(startDate, "yyyy-MM-dd")}
                  </Button>
                  {isOpen && (
                    <DatePicker
                      selected={startDate}
                      onChange={handleChange}
                      dateFormat="yyyy-MM-dd"
                      inline
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col className="text-center">
              <Rinnai_Boiler_Component device_id={productId} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default UserBoilerGUIScreen;
