import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import UnitComment from "../../components/UnitComment";
import { getPictures } from "../../actions/serviceActions";
import { useTranslation } from "react-i18next";

function UnitPictureScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const projectId = params.projectid;
  const unitId = params.unitid;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const unitPictures = useSelector((state) => state.unitPictures);
  const { loading, error, success, unitpictures } = unitPictures;

  useEffect(() => {
    if (userInfo) {
      dispatch(getPictures(projectId, unitId));
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  return (
    <div>
      <Row className="align-items-center">
        <Col>
          <h1>{t("comments")}</h1>
        </Col>
        {userInfo.customer.id == 1 && (
          <Col className="text-end">
            <LinkContainer
              to={`/staff/projects/${projectId}/${unitId}/createcomment`}
            >
              <Button className="my-3  w-100">
                <i className="fas fa-plus" style={{ color: "green" }}></i>
                {t("create_comment")}
              </Button>
            </LinkContainer>
          </Col>
        )}
      </Row>
      {!success ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <Row className="mb-5">
            {unitpictures.map((product) => (
              <Col key={product.id} sm={12} lg={3}>
                <UnitComment product={product} />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}

export default UnitPictureScreen;
