import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { combineReducers } from "redux";

function Product({ product, language }) {
  if (language == "en") {
    return (
      <Card className="my-3 p-3 rounded h-100">
        <Card.Body className="centered-card">
          <Link to={`/product/${product.id}`}>
            <Card.Img className="align-items-center" src={product.image} />
          </Link>
        </Card.Body>
        <Card.Footer>
          <Card.Text as="div">
            <div>
              <Link to={`/product/${product.id}`}>
                <Card.Title as="div">
                  <strong>{product.name}</strong>
                </Card.Title>
              </Link>
              <pre className="my-3">{product.description_en}</pre>
            </div>
          </Card.Text>
        </Card.Footer>
      </Card>
    );
  }
  if (language == "tr") {
    return (
      <Card className="my-3 p-3 rounded h-100">
        <Card.Body className="centered-card">
          <Link to={`/product/${product.id}`}>
            <Card.Img className="align-items-center" src={product.image} />
          </Link>
        </Card.Body>
        <Card.Footer>
          <Card.Text as="div">
            <div>
              <Link to={`/product/${product.id}`}>
                <Card.Title as="div">
                  <strong>{product.name}</strong>
                </Card.Title>
              </Link>
              <pre className="my-3">{product.description_tr}</pre>
            </div>
          </Card.Text>
        </Card.Footer>
      </Card>
    );
  }
}

export default Product;
