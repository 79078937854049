import {
  USER_GATEWAY_REQUEST,
  USER_GATEWAY_SUCCESS,
  USER_GATEWAY_FAIL,
  USER_GATEWAY_RESET,
  ADMIN_GATEWAY_REQUEST,
  ADMIN_GATEWAY_SUCCESS,
  ADMIN_GATEWAY_FAIL,
  ADMIN_GATEWAY_RESET,
  GATEWAY_DATA_REQUEST,
  GATEWAY_DATA_SUCCESS,
  GATEWAY_DATA_FAIL,
  GATEWAY_UPDATE_REQUEST,
  GATEWAY_UPDATE_SUCCESS,
  GATEWAY_UPDATE_FAIL,
  GATEWAY_DIAGRAM_REQUEST,
  GATEWAY_DIAGRAM_SUCCESS,
  GATEWAY_DIAGRAM_FAIL,
  GATEWAY_DIAGRAM_UPDATE_REQUEST,
  GATEWAY_DIAGRAM_UPDATE_SUCCESS,
  GATEWAY_DIAGRAM_UPDATE_FAIL,
  GATEWAY_GROUP_REQUEST,
  GATEWAY_GROUP_SUCCESS,
  GATEWAY_GROUP_FAIL,
  GATEWAY_GROUP_UPDATE_REQUEST,
  GATEWAY_GROUP_UPDATE_SUCCESS,
  GATEWAY_GROUP_UPDATE_FAIL,
  USER_BOILER_REQUEST,
  USER_BOILER_SUCCESS,
  USER_BOILER_FAIL,
  USER_BOILER_RESET,
  ADMIN_BOILER_REQUEST,
  ADMIN_BOILER_SUCCESS,
  ADMIN_BOILER_FAIL,
  ADMIN_BOILER_RESET,
  BOILER_ERRORS_REQUEST,
  BOILER_ERRORS_SUCCESS,
  BOILER_ERRORS_FAIL,
  BOILER_ERRORS_RESET,
} from "../constants/gatewayConstants";
import axios from "axios";

export const getAdminGateways = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_GATEWAY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/gateway/gateways-admin/`, config);

    dispatch({
      type: ADMIN_GATEWAY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GATEWAY_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserGateways = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_GATEWAY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/gateway/gateways-user/`, config);

    dispatch({
      type: USER_GATEWAY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_GATEWAY_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getGatewayDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GATEWAY_DATA_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/gateway/${id}/details/`, config);

    dispatch({
      type: GATEWAY_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GATEWAY_DATA_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getGatewayUpdates = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GATEWAY_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/gateway/${id}/details/`, config);

    dispatch({
      type: GATEWAY_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GATEWAY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getGatewayDiagram = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GATEWAY_DIAGRAM_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/gateway/${id}/diagram/`, config);

    dispatch({
      type: GATEWAY_DIAGRAM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GATEWAY_DIAGRAM_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getGatewayDiagramUpdates = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GATEWAY_DIAGRAM_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/gateway/${id}/diagram/`, config);

    dispatch({
      type: GATEWAY_DIAGRAM_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GATEWAY_DIAGRAM_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getGatewayGroup =
  (id, group, timeframe, date = "none") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GATEWAY_GROUP_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/gateway/${id}/${group}/${timeframe}/${date}/diagram/`,
        config
      );

      dispatch({
        type: GATEWAY_GROUP_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GATEWAY_GROUP_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const getGatewayGroupUpdates =
  (id, group, timeframe, date = "none") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GATEWAY_GROUP_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/gateway/${id}/${group}/${timeframe}/${date}/diagram/`,
        config
      );

      dispatch({
        type: GATEWAY_GROUP_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GATEWAY_GROUP_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const getAdminBoilers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_BOILER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/gateway/boilers-admin/`, config);

    dispatch({
      type: ADMIN_BOILER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_BOILER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserBoilers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_BOILER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/gateway/boilers-user/`, config);

    dispatch({
      type: USER_BOILER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_BOILER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getBoilerErrors = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOILER_ERRORS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/gateway/boiler/${id}/errors/`,
      config
    );

    dispatch({
      type: BOILER_ERRORS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOILER_ERRORS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
