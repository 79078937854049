import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";

function Footer() {
  const { t } = useTranslation();

  const [timezone, setTimezone] = useState({});

  const [globalTimezone, setGlobalTimezone] = useGlobal("globalTimezone");

  useEffect(() => {
    const tz_json = {
      value: timezone.value,
      label: timezone.label,
      offset: timezone.offset,
      abbrev: timezone.abbrev,
      altname: timezone.altName,
    };

    if (timezone.value != null) {
      localStorage.setItem("timeZone", JSON.stringify(tz_json));
    }
    const timeZoneFromStorage = localStorage.getItem("timeZone")
      ? JSON.parse(localStorage.getItem("timeZone"))
      : null;

    if (timezone.value == null && timeZoneFromStorage != null) {
      setTimezone(timeZoneFromStorage);
      setGlobalTimezone(timeZoneFromStorage);
    }
  }, [timezone]);

  useEffect(() => {
    setGlobalTimezone(timezone);
  }, [timezone]);

  return (
    <footer>
      <Container>
        <div className="select-wrapper">
          <TimezoneSelect value={timezone} onChange={setTimezone} />
        </div>
        <Row>
          <Col className="text-center py-3">{t("footer")}</Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
