import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Table, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Chart from "../../components/Chart";
import { LinkContainer } from "react-router-bootstrap";
import {
  getNeffesDetails,
  getNeffesUpdates,
} from "../../actions/neffesActions";
import RangeSlider from "react-bootstrap-range-slider";
import FormContainer from "../../components/FormContainer";
import { useTranslation } from "react-i18next";

function AdminNeffesDetailsScreen() {
  const params = useParams();
  const productId = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [webOvr, setWebOvr] = useState(false);
  const [webPower, setWebPower] = useState(false);
  const [webUvc, setWebUvc] = useState(false);
  const [webSpeed, setWebSpeed] = useState(1);
  const [controlling, setControlling] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const neffesDetailsState = useSelector((state) => state.neffesDetails);
  const {
    loading,
    error,
    success,
    neffesDetails: neffesdetails,
  } = neffesDetailsState;

  const neffesUpdate = useSelector((state) => state.neffesUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    neffesupdates,
  } = neffesUpdate;

  useEffect(() => {
    if (updateSuccess) {
      setUpdateData(neffesupdates);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (success) {
      setUpdateData(neffesdetails);
      setWebOvr(neffesdetails.web_override);
      setWebPower(neffesdetails.web_control);
      setWebUvc(neffesdetails.web_uvc);
      setWebSpeed(neffesdetails.web_speed);
      setUpdating(true);
    }
  }, [success]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userInfo && userInfo.is_staff) {
        dispatch(getNeffesUpdates(productId));
      }
    }, 120000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.is_staff) {
      dispatch(getNeffesDetails(productId));
    } else if (!userInfo) {
      navigate("/login");
    } else if (!userInfo.is_staff) {
      navigate("/");
    }
    if (success) {
      setWebOvr(neffesdetails.web_override);
      setWebPower(neffesdetails.web_control);
      setWebUvc(neffesdetails.web_uvc);
      setWebSpeed(neffesupdates.web_speed);
    }
  }, [dispatch, navigate, userInfo, productId]);

  useEffect(() => {
    if (controlling) {
      const sendData = async () => {
        try {
          const config = {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${userInfo.token}`,
            },
          };

          const postData = {
            id: productId,
            web_override: webOvr,
            web_control: webPower,
            web_speed: webSpeed,
            web_uvc: webUvc,
          };

          await axios.post(`/api/neffes/neffes-control/`, postData, config);
        } catch (error) {}
      };

      sendData();
      setControlling(false);

      setTimeout(() => {
        dispatch(getNeffesUpdates(productId));
      }, 3000);
    }
  }, [webOvr, webPower, webSpeed, webUvc, controlling]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        updating && (
          <>
            <h1>
              {neffesdetails.name}
              <LinkContainer to={`/staff/neffes/${neffesdetails.id}/edit`}>
                <Button variant="transparent" style={{ color: "red" }}>
                  <i className="fas fa-pen-to-square"></i>
                </Button>
              </LinkContainer>
            </h1>
            <FormContainer>
              <Form>
                <Row>
                  <Col className="text-center my-3" xs={12} xl={6}>
                    <h4>{t("web_override")}</h4>
                    <Form.Check
                      type="switch"
                      checked={webOvr}
                      onChange={(e) => {
                        setWebOvr(e.target.checked);
                        setControlling(true);
                      }}
                    />
                  </Col>
                  <Col className="text-center my-3" xs={12} xl={6}>
                    <h4>{t("web_power")}</h4>
                    <Form.Check
                      type="switch"
                      checked={webPower}
                      onChange={(e) => {
                        setWebPower(e.target.checked);
                        setControlling(true);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center my-3" xs={12}>
                    <h4>{t("web_uvc")}</h4>
                    <Form.Check
                      type="switch"
                      checked={webUvc}
                      onChange={(e) => {
                        setWebUvc(e.target.checked);
                        setControlling(true);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <RangeSlider
                      className="w-100"
                      value={webSpeed}
                      min={1}
                      max={5}
                      tooltip="off"
                      onChange={(e) => {
                        setWebSpeed(e.target.value);
                      }}
                      onAfterChange={(e) => {
                        setControlling(true);
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            </FormContainer>
            <Row>
              <Col className="text-center my-3" xs={12}>
                <h4>{t("status")}</h4>
              </Col>
              <Col className="text-center my-3" xs={12} xl={6}>
                <h4>{t("power")}</h4>
                {updateData.device_state ? (
                  <div className="my-1 led-green"></div>
                ) : (
                  <div className="my-1 led-red"></div>
                )}
              </Col>
              <Col className="text-center my-3" xs={12} xl={6}>
                <h4>{t("uvc")}</h4>
                {updateData.device_uvc && updateData.device_state ? (
                  <div className="my-1 led-green"></div>
                ) : (
                  <div className="my-1 led-red"></div>
                )}
              </Col>
              <Col className="text-center my-3" xs={12}>
                <h4>{t("fan_speed")}</h4>
                {updateData.device_state ? (
                  <div>{updateData.device_speed * 20 + 20}%</div>
                ) : (
                  <div>0%</div>
                )}
              </Col>
              <Col className="text-center my-3" xs={12} xl={6}>
                <h4>{t("uvc_hours")}</h4>
                <div>{Math.floor(updateData.device_uvc_timer / 60)} Hours</div>
              </Col>
              <Col className="text-center my-3" xs={12} xl={6}>
                <h4>{t("work_hours")}</h4>
                <div>
                  {Math.floor(updateData.device_filter_timer / 60)} Hours
                </div>
              </Col>
            </Row>
          </>
        )
      )}
    </div>
  );
}

export default AdminNeffesDetailsScreen;
