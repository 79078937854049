export const USER_NEFFES_REQUEST = "USER_NEFFES_REQUEST";
export const USER_NEFFES_SUCCESS = "USER_NEFFES_SUCCESS";
export const USER_NEFFES_FAIL = "USER_NEFFES_FAIL";
export const USER_NEFFES_RESET = "USER_NEFFES_RESET";

export const ADMIN_NEFFES_REQUEST = "ADMIN_NEFFES_REQUEST";
export const ADMIN_NEFFES_SUCCESS = "ADMIN_NEFFES_SUCCESS";
export const ADMIN_NEFFES_FAIL = "ADMIN_NEFFES_FAIL";
export const ADMIN_NEFFES_RESET = "ADMIN_NEFFES_RESET";

export const NEFFES_DATA_REQUEST = "NEFFES_DATA_REQUEST";
export const NEFFES_DATA_SUCCESS = "NEFFES_DATA_SUCCESS";
export const NEFFES_DATA_FAIL = "NEFFES_DATA_FAIL";

export const NEFFES_UPDATE_REQUEST = "NEFFES_UPDATE_REQUEST";
export const NEFFES_UPDATE_SUCCESS = "NEFFES_UPDATE_SUCCESS";
export const NEFFES_UPDATE_FAIL = "NEFFES_UPDATE_FAIL";

export const USER_NEFFES_GROUP_REQUEST = "USER_NEFFES_GROUP_REQUEST";
export const USER_NEFFES_GROUP_SUCCESS = "USER_NEFFES_GROUP_SUCCESS";
export const USER_NEFFES_GROUP_FAIL = "USER_NEFFES_GROUP_FAIL";
export const USER_NEFFES_GROUP_RESET = "USER_NEFFES_GROUP_RESET";

export const ADMIN_NEFFES_GROUP_REQUEST = "ADMIN_NEFFES_GROUP_REQUEST";
export const ADMIN_NEFFES_GROUP_SUCCESS = "ADMIN_NEFFES_GROUP_SUCCESS";
export const ADMIN_NEFFES_GROUP_FAIL = "ADMIN_NEFFES_GROUP_FAIL";
export const ADMIN_NEFFES_GROUP_RESET = "ADMIN_NEFFES_GROUP_RESET";

export const NEFFES_GROUP_DATA_REQUEST = "NEFFES_GROUP_DATA_REQUEST";
export const NEFFES_GROUP_DATA_SUCCESS = "NEFFES_GROUP_DATA_SUCCESS";
export const NEFFES_GROUP_DATA_FAIL = "NEFFES_GROUP_DATA_FAIL";

export const NEFFES_GROUP_UPDATE_REQUEST = "NEFFES_GROUP_UPDATE_REQUEST";
export const NEFFES_GROUP_UPDATE_SUCCESS = "NEFFES_GROUP_UPDATE_SUCCESS";
export const NEFFES_GROUP_UPDATE_FAIL = "NEFFES_GROUP_UPDATE_FAIL";
