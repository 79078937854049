import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Row, Col, Button, Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Chart from "../../components/Chart";
import {
  getGatewayDetails,
  getGatewayUpdates,
} from "../../actions/gatewayActions";
import { LinkContainer } from "react-router-bootstrap";
import moment from "moment";
import "moment-timezone";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";

function AdminGatewayDetailsScreen() {
  const params = useParams();
  const productId = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [globalTimezone, setGlobalTimezone] = useGlobal("globalTimezone");

  const [data_0, setData_0] = useState([]);
  const [data_1, setData_1] = useState([]);
  const [data_2, setData_2] = useState([]);
  const [data_3, setData_3] = useState([]);
  const [data_4, setData_4] = useState([]);
  const [data_5, setData_5] = useState([]);
  const [data_6, setData_6] = useState([]);
  const [data_7, setData_7] = useState([]);
  const [data_8, setData_8] = useState([]);
  const [data_9, setData_9] = useState([]);
  const [data_10, setData_10] = useState([]);
  const [data_11, setData_11] = useState([]);
  const [data_12, setData_12] = useState([]);
  const [data_13, setData_13] = useState([]);
  const [data_14, setData_14] = useState([]);
  const [data_15, setData_15] = useState([]);
  const [data_16, setData_16] = useState([]);
  const [data_17, setData_17] = useState([]);
  const [data_18, setData_18] = useState([]);
  const [data_19, setData_19] = useState([]);
  const [data_20, setData_20] = useState([]);
  const [data_21, setData_21] = useState([]);
  const [data_22, setData_22] = useState([]);
  const [data_23, setData_23] = useState([]);
  const [data_24, setData_24] = useState([]);
  const [data_25, setData_25] = useState([]);
  const [data_26, setData_26] = useState([]);
  const [data_27, setData_27] = useState([]);
  const [data_28, setData_28] = useState([]);
  const [data_29, setData_29] = useState([]);
  const [data_30, setData_30] = useState([]);
  const [data_31, setData_31] = useState([]);
  const [data_32, setData_32] = useState([]);
  const [data_33, setData_33] = useState([]);
  const [data_34, setData_34] = useState([]);
  const [data_35, setData_35] = useState([]);
  const [data_36, setData_36] = useState([]);
  const [data_37, setData_37] = useState([]);
  const [data_38, setData_38] = useState([]);
  const [data_39, setData_39] = useState([]);
  const [data_40, setData_40] = useState([]);
  const [data_41, setData_41] = useState([]);
  const [data_42, setData_42] = useState([]);
  const [data_43, setData_43] = useState([]);
  const [data_44, setData_44] = useState([]);
  const [data_45, setData_45] = useState([]);
  const [data_46, setData_46] = useState([]);
  const [data_47, setData_47] = useState([]);
  const [data_48, setData_48] = useState([]);
  const [data_49, setData_49] = useState([]);
  const [data_50, setData_50] = useState([]);
  const [data_51, setData_51] = useState([]);
  const [data_52, setData_52] = useState([]);
  const [data_53, setData_53] = useState([]);
  const [data_54, setData_54] = useState([]);
  const [data_55, setData_55] = useState([]);
  const [data_56, setData_56] = useState([]);
  const [data_57, setData_57] = useState([]);
  const [data_58, setData_58] = useState([]);
  const [data_59, setData_59] = useState([]);
  const [data_60, setData_60] = useState([]);
  const [data_61, setData_61] = useState([]);
  const [data_62, setData_62] = useState([]);
  const [data_63, setData_63] = useState([]);
  const [data_64, setData_64] = useState([]);
  const [data_65, setData_65] = useState([]);
  const [data_66, setData_66] = useState([]);
  const [data_67, setData_67] = useState([]);
  const [data_68, setData_68] = useState([]);
  const [data_69, setData_69] = useState([]);
  const [data_70, setData_70] = useState([]);
  const [data_71, setData_71] = useState([]);
  const [data_72, setData_72] = useState([]);
  const [data_73, setData_73] = useState([]);
  const [data_74, setData_74] = useState([]);
  const [data_75, setData_75] = useState([]);
  const [data_76, setData_76] = useState([]);
  const [data_77, setData_77] = useState([]);
  const [data_78, setData_78] = useState([]);
  const [data_79, setData_79] = useState([]);
  const [data_80, setData_80] = useState([]);
  const [data_81, setData_81] = useState([]);
  const [data_82, setData_82] = useState([]);
  const [data_83, setData_83] = useState([]);
  const [data_84, setData_84] = useState([]);
  const [data_85, setData_85] = useState([]);
  const [data_86, setData_86] = useState([]);
  const [data_87, setData_87] = useState([]);
  const [data_88, setData_88] = useState([]);
  const [data_89, setData_89] = useState([]);
  const [data_90, setData_90] = useState([]);
  const [data_91, setData_91] = useState([]);
  const [data_92, setData_92] = useState([]);
  const [data_93, setData_93] = useState([]);
  const [data_94, setData_94] = useState([]);
  const [data_95, setData_95] = useState([]);
  const [data_96, setData_96] = useState([]);
  const [data_97, setData_97] = useState([]);
  const [data_98, setData_98] = useState([]);
  const [data_99, setData_99] = useState([]);
  const [max_0, setmax_0] = useState(0);
  const [max_1, setmax_1] = useState(0);
  const [max_2, setmax_2] = useState(0);
  const [max_3, setmax_3] = useState(0);
  const [max_4, setmax_4] = useState(0);
  const [max_5, setmax_5] = useState(0);
  const [max_6, setmax_6] = useState(0);
  const [max_7, setmax_7] = useState(0);
  const [max_8, setmax_8] = useState(0);
  const [max_9, setmax_9] = useState(0);
  const [max_10, setmax_10] = useState(0);
  const [max_11, setmax_11] = useState(0);
  const [max_12, setmax_12] = useState(0);
  const [max_13, setmax_13] = useState(0);
  const [max_14, setmax_14] = useState(0);
  const [max_15, setmax_15] = useState(0);
  const [max_16, setmax_16] = useState(0);
  const [max_17, setmax_17] = useState(0);
  const [max_18, setmax_18] = useState(0);
  const [max_19, setmax_19] = useState(0);
  const [max_20, setmax_20] = useState(0);
  const [max_21, setmax_21] = useState(0);
  const [max_22, setmax_22] = useState(0);
  const [max_23, setmax_23] = useState(0);
  const [max_24, setmax_24] = useState(0);
  const [max_25, setmax_25] = useState(0);
  const [max_26, setmax_26] = useState(0);
  const [max_27, setmax_27] = useState(0);
  const [max_28, setmax_28] = useState(0);
  const [max_29, setmax_29] = useState(0);
  const [max_30, setmax_30] = useState(0);
  const [max_31, setmax_31] = useState(0);
  const [max_32, setmax_32] = useState(0);
  const [max_33, setmax_33] = useState(0);
  const [max_34, setmax_34] = useState(0);
  const [max_35, setmax_35] = useState(0);
  const [max_36, setmax_36] = useState(0);
  const [max_37, setmax_37] = useState(0);
  const [max_38, setmax_38] = useState(0);
  const [max_39, setmax_39] = useState(0);
  const [max_40, setmax_40] = useState(0);
  const [max_41, setmax_41] = useState(0);
  const [max_42, setmax_42] = useState(0);
  const [max_43, setmax_43] = useState(0);
  const [max_44, setmax_44] = useState(0);
  const [max_45, setmax_45] = useState(0);
  const [max_46, setmax_46] = useState(0);
  const [max_47, setmax_47] = useState(0);
  const [max_48, setmax_48] = useState(0);
  const [max_49, setmax_49] = useState(0);
  const [max_50, setmax_50] = useState(0);
  const [max_51, setmax_51] = useState(0);
  const [max_52, setmax_52] = useState(0);
  const [max_53, setmax_53] = useState(0);
  const [max_54, setmax_54] = useState(0);
  const [max_55, setmax_55] = useState(0);
  const [max_56, setmax_56] = useState(0);
  const [max_57, setmax_57] = useState(0);
  const [max_58, setmax_58] = useState(0);
  const [max_59, setmax_59] = useState(0);
  const [max_60, setmax_60] = useState(0);
  const [max_61, setmax_61] = useState(0);
  const [max_62, setmax_62] = useState(0);
  const [max_63, setmax_63] = useState(0);
  const [max_64, setmax_64] = useState(0);
  const [max_65, setmax_65] = useState(0);
  const [max_66, setmax_66] = useState(0);
  const [max_67, setmax_67] = useState(0);
  const [max_68, setmax_68] = useState(0);
  const [max_69, setmax_69] = useState(0);
  const [max_70, setmax_70] = useState(0);
  const [max_71, setmax_71] = useState(0);
  const [max_72, setmax_72] = useState(0);
  const [max_73, setmax_73] = useState(0);
  const [max_74, setmax_74] = useState(0);
  const [max_75, setmax_75] = useState(0);
  const [max_76, setmax_76] = useState(0);
  const [max_77, setmax_77] = useState(0);
  const [max_78, setmax_78] = useState(0);
  const [max_79, setmax_79] = useState(0);
  const [max_80, setmax_80] = useState(0);
  const [max_81, setmax_81] = useState(0);
  const [max_82, setmax_82] = useState(0);
  const [max_83, setmax_83] = useState(0);
  const [max_84, setmax_84] = useState(0);
  const [max_85, setmax_85] = useState(0);
  const [max_86, setmax_86] = useState(0);
  const [max_87, setmax_87] = useState(0);
  const [max_88, setmax_88] = useState(0);
  const [max_89, setmax_89] = useState(0);
  const [max_90, setmax_90] = useState(0);
  const [max_91, setmax_91] = useState(0);
  const [max_92, setmax_92] = useState(0);
  const [max_93, setmax_93] = useState(0);
  const [max_94, setmax_94] = useState(0);
  const [max_95, setmax_95] = useState(0);
  const [max_96, setmax_96] = useState(0);
  const [max_97, setmax_97] = useState(0);
  const [max_98, setmax_98] = useState(0);
  const [max_99, setmax_99] = useState(0);

  const [min_0, setmin_0] = useState(0);
  const [min_1, setmin_1] = useState(0);
  const [min_2, setmin_2] = useState(0);
  const [min_3, setmin_3] = useState(0);
  const [min_4, setmin_4] = useState(0);
  const [min_5, setmin_5] = useState(0);
  const [min_6, setmin_6] = useState(0);
  const [min_7, setmin_7] = useState(0);
  const [min_8, setmin_8] = useState(0);
  const [min_9, setmin_9] = useState(0);
  const [min_10, setmin_10] = useState(0);
  const [min_11, setmin_11] = useState(0);
  const [min_12, setmin_12] = useState(0);
  const [min_13, setmin_13] = useState(0);
  const [min_14, setmin_14] = useState(0);
  const [min_15, setmin_15] = useState(0);
  const [min_16, setmin_16] = useState(0);
  const [min_17, setmin_17] = useState(0);
  const [min_18, setmin_18] = useState(0);
  const [min_19, setmin_19] = useState(0);
  const [min_20, setmin_20] = useState(0);
  const [min_21, setmin_21] = useState(0);
  const [min_22, setmin_22] = useState(0);
  const [min_23, setmin_23] = useState(0);
  const [min_24, setmin_24] = useState(0);
  const [min_25, setmin_25] = useState(0);
  const [min_26, setmin_26] = useState(0);
  const [min_27, setmin_27] = useState(0);
  const [min_28, setmin_28] = useState(0);
  const [min_29, setmin_29] = useState(0);
  const [min_30, setmin_30] = useState(0);
  const [min_31, setmin_31] = useState(0);
  const [min_32, setmin_32] = useState(0);
  const [min_33, setmin_33] = useState(0);
  const [min_34, setmin_34] = useState(0);
  const [min_35, setmin_35] = useState(0);
  const [min_36, setmin_36] = useState(0);
  const [min_37, setmin_37] = useState(0);
  const [min_38, setmin_38] = useState(0);
  const [min_39, setmin_39] = useState(0);
  const [min_40, setmin_40] = useState(0);
  const [min_41, setmin_41] = useState(0);
  const [min_42, setmin_42] = useState(0);
  const [min_43, setmin_43] = useState(0);
  const [min_44, setmin_44] = useState(0);
  const [min_45, setmin_45] = useState(0);
  const [min_46, setmin_46] = useState(0);
  const [min_47, setmin_47] = useState(0);
  const [min_48, setmin_48] = useState(0);
  const [min_49, setmin_49] = useState(0);
  const [min_50, setmin_50] = useState(0);
  const [min_51, setmin_51] = useState(0);
  const [min_52, setmin_52] = useState(0);
  const [min_53, setmin_53] = useState(0);
  const [min_54, setmin_54] = useState(0);
  const [min_55, setmin_55] = useState(0);
  const [min_56, setmin_56] = useState(0);
  const [min_57, setmin_57] = useState(0);
  const [min_58, setmin_58] = useState(0);
  const [min_59, setmin_59] = useState(0);
  const [min_60, setmin_60] = useState(0);
  const [min_61, setmin_61] = useState(0);
  const [min_62, setmin_62] = useState(0);
  const [min_63, setmin_63] = useState(0);
  const [min_64, setmin_64] = useState(0);
  const [min_65, setmin_65] = useState(0);
  const [min_66, setmin_66] = useState(0);
  const [min_67, setmin_67] = useState(0);
  const [min_68, setmin_68] = useState(0);
  const [min_69, setmin_69] = useState(0);
  const [min_70, setmin_70] = useState(0);
  const [min_71, setmin_71] = useState(0);
  const [min_72, setmin_72] = useState(0);
  const [min_73, setmin_73] = useState(0);
  const [min_74, setmin_74] = useState(0);
  const [min_75, setmin_75] = useState(0);
  const [min_76, setmin_76] = useState(0);
  const [min_77, setmin_77] = useState(0);
  const [min_78, setmin_78] = useState(0);
  const [min_79, setmin_79] = useState(0);
  const [min_80, setmin_80] = useState(0);
  const [min_81, setmin_81] = useState(0);
  const [min_82, setmin_82] = useState(0);
  const [min_83, setmin_83] = useState(0);
  const [min_84, setmin_84] = useState(0);
  const [min_85, setmin_85] = useState(0);
  const [min_86, setmin_86] = useState(0);
  const [min_87, setmin_87] = useState(0);
  const [min_88, setmin_88] = useState(0);
  const [min_89, setmin_89] = useState(0);
  const [min_90, setmin_90] = useState(0);
  const [min_91, setmin_91] = useState(0);
  const [min_92, setmin_92] = useState(0);
  const [min_93, setmin_93] = useState(0);
  const [min_94, setmin_94] = useState(0);
  const [min_95, setmin_95] = useState(0);
  const [min_96, setmin_96] = useState(0);
  const [min_97, setmin_97] = useState(0);
  const [min_98, setmin_98] = useState(0);
  const [min_99, setmin_99] = useState(0);

  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [timezone, setTimezone] = useState({});

  useEffect(() => {
    const timeZoneFromStorage = localStorage.getItem("timeZone")
      ? JSON.parse(localStorage.getItem("timeZone"))
      : null;

    if (
      timeZoneFromStorage != null &&
      timezone.value != timeZoneFromStorage.value
    ) {
      setTimezone(timeZoneFromStorage);
    }
  }, [globalTimezone]);

  const changeDatetimeByTimezone = (datetime, timezone) => {
    if (timezone == null) {
      timezone = "Europe/Moscow";
    }
    return moment.utc(datetime).tz(timezone).format();
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const gatewayDetails = useSelector((state) => state.gatewayDetails);
  const { loading, error, success, gatewaydetails } = gatewayDetails;

  const gatewayUpdate = useSelector((state) => state.gatewayUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    gatewayupdates,
  } = gatewayUpdate;

  useEffect(() => {
    const interval = setInterval(() => {
      if (userInfo) {
        dispatch(getGatewayUpdates(productId));
      }
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      setUpdateData(gatewayupdates);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (success) {
      setUpdateData(gatewaydetails);
      setUpdating(true);
    }
  }, [success]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getGatewayDetails(productId));
    } else if (!userInfo) {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo, productId]);

  useEffect(() => {
    if (success) {
      let data_0 = [];
      let data_1 = [];
      let data_2 = [];
      let data_3 = [];
      let data_4 = [];
      let data_5 = [];
      let data_6 = [];
      let data_7 = [];
      let data_8 = [];
      let data_9 = [];
      let data_10 = [];
      let data_11 = [];
      let data_12 = [];
      let data_13 = [];
      let data_14 = [];
      let data_15 = [];
      let data_16 = [];
      let data_17 = [];
      let data_18 = [];
      let data_19 = [];
      let data_20 = [];
      let data_21 = [];
      let data_22 = [];
      let data_23 = [];
      let data_24 = [];
      let data_25 = [];
      let data_26 = [];
      let data_27 = [];
      let data_28 = [];
      let data_29 = [];
      let data_30 = [];
      let data_31 = [];
      let data_32 = [];
      let data_33 = [];
      let data_34 = [];
      let data_35 = [];
      let data_36 = [];
      let data_37 = [];
      let data_38 = [];
      let data_39 = [];
      let data_40 = [];
      let data_41 = [];
      let data_42 = [];
      let data_43 = [];
      let data_44 = [];
      let data_45 = [];
      let data_46 = [];
      let data_47 = [];
      let data_48 = [];
      let data_49 = [];
      let data_50 = [];
      let data_51 = [];
      let data_52 = [];
      let data_53 = [];
      let data_54 = [];
      let data_55 = [];
      let data_56 = [];
      let data_57 = [];
      let data_58 = [];
      let data_59 = [];
      let data_60 = [];
      let data_61 = [];
      let data_62 = [];
      let data_63 = [];
      let data_64 = [];
      let data_65 = [];
      let data_66 = [];
      let data_67 = [];
      let data_68 = [];
      let data_69 = [];
      let data_70 = [];
      let data_71 = [];
      let data_72 = [];
      let data_73 = [];
      let data_74 = [];
      let data_75 = [];
      let data_76 = [];
      let data_77 = [];
      let data_78 = [];
      let data_79 = [];
      let data_80 = [];
      let data_81 = [];
      let data_82 = [];
      let data_83 = [];
      let data_84 = [];
      let data_85 = [];
      let data_86 = [];
      let data_87 = [];
      let data_88 = [];
      let data_89 = [];
      let data_90 = [];
      let data_91 = [];
      let data_92 = [];
      let data_93 = [];
      let data_94 = [];
      let data_95 = [];
      let data_96 = [];
      let data_97 = [];
      let data_98 = [];
      let data_99 = [];
      gatewaydetails.data_chart.forEach((data) => {
        let obj_0 = {};
        obj_0["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_0[
          gatewaydetails.data_0_name +
            " " +
            gatewaydetails.data_0_unit.data_unit
        ] = Number(data.data_0 * gatewaydetails.data_0_multiplier).toFixed(2);

        let obj_1 = {};
        obj_1["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_1[
          gatewaydetails.data_1_name +
            " " +
            gatewaydetails.data_1_unit.data_unit
        ] = Number(data.data_1 * gatewaydetails.data_1_multiplier).toFixed(2);

        let obj_2 = {};
        obj_2["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_2[
          gatewaydetails.data_2_name +
            " " +
            gatewaydetails.data_2_unit.data_unit
        ] = Number(data.data_2 * gatewaydetails.data_2_multiplier).toFixed(2);

        let obj_3 = {};
        obj_3["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_3[
          gatewaydetails.data_3_name +
            " " +
            gatewaydetails.data_3_unit.data_unit
        ] = Number(data.data_3 * gatewaydetails.data_3_multiplier).toFixed(2);

        let obj_4 = {};
        obj_4["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_4[
          gatewaydetails.data_4_name +
            " " +
            gatewaydetails.data_4_unit.data_unit
        ] = Number(data.data_4 * gatewaydetails.data_4_multiplier).toFixed(2);

        let obj_5 = {};
        obj_5["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_5[
          gatewaydetails.data_5_name +
            " " +
            gatewaydetails.data_5_unit.data_unit
        ] = Number(data.data_5 * gatewaydetails.data_5_multiplier).toFixed(2);

        let obj_6 = {};
        obj_6["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_6[
          gatewaydetails.data_6_name +
            " " +
            gatewaydetails.data_6_unit.data_unit
        ] = Number(data.data_6 * gatewaydetails.data_6_multiplier).toFixed(2);

        let obj_7 = {};
        obj_7["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_7[
          gatewaydetails.data_7_name +
            " " +
            gatewaydetails.data_7_unit.data_unit
        ] = Number(data.data_7 * gatewaydetails.data_7_multiplier).toFixed(2);

        let obj_8 = {};
        obj_8["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_8[
          gatewaydetails.data_8_name +
            " " +
            gatewaydetails.data_8_unit.data_unit
        ] = Number(data.data_8 * gatewaydetails.data_8_multiplier).toFixed(2);

        let obj_9 = {};
        obj_9["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_9[
          gatewaydetails.data_9_name +
            " " +
            gatewaydetails.data_9_unit.data_unit
        ] = Number(data.data_9 * gatewaydetails.data_9_multiplier).toFixed(2);

        let obj_10 = {};
        obj_10["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_10[
          gatewaydetails.data_10_name +
            " " +
            gatewaydetails.data_10_unit.data_unit
        ] = Number(data.data_10 * gatewaydetails.data_10_multiplier).toFixed(2);

        let obj_11 = {};
        obj_11["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_11[
          gatewaydetails.data_11_name +
            " " +
            gatewaydetails.data_11_unit.data_unit
        ] = Number(data.data_11 * gatewaydetails.data_11_multiplier).toFixed(2);

        let obj_12 = {};
        obj_12["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_12[
          gatewaydetails.data_12_name +
            " " +
            gatewaydetails.data_12_unit.data_unit
        ] = Number(data.data_12 * gatewaydetails.data_12_multiplier).toFixed(2);

        let obj_13 = {};
        obj_13["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_13[
          gatewaydetails.data_13_name +
            " " +
            gatewaydetails.data_13_unit.data_unit
        ] = Number(data.data_13 * gatewaydetails.data_13_multiplier).toFixed(2);

        let obj_14 = {};
        obj_14["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_14[
          gatewaydetails.data_14_name +
            " " +
            gatewaydetails.data_14_unit.data_unit
        ] = Number(data.data_14 * gatewaydetails.data_14_multiplier).toFixed(2);

        let obj_15 = {};
        obj_15["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_15[
          gatewaydetails.data_15_name +
            " " +
            gatewaydetails.data_15_unit.data_unit
        ] = Number(data.data_15 * gatewaydetails.data_15_multiplier).toFixed(2);

        let obj_16 = {};
        obj_16["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_16[
          gatewaydetails.data_16_name +
            " " +
            gatewaydetails.data_16_unit.data_unit
        ] = Number(data.data_16 * gatewaydetails.data_16_multiplier).toFixed(2);

        let obj_17 = {};
        obj_17["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_17[
          gatewaydetails.data_17_name +
            " " +
            gatewaydetails.data_17_unit.data_unit
        ] = Number(data.data_17 * gatewaydetails.data_17_multiplier).toFixed(2);

        let obj_18 = {};
        obj_18["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_18[
          gatewaydetails.data_18_name +
            " " +
            gatewaydetails.data_18_unit.data_unit
        ] = Number(data.data_18 * gatewaydetails.data_18_multiplier).toFixed(2);

        let obj_19 = {};
        obj_19["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_19[
          gatewaydetails.data_19_name +
            " " +
            gatewaydetails.data_19_unit.data_unit
        ] = Number(data.data_19 * gatewaydetails.data_19_multiplier).toFixed(2);

        let obj_20 = {};
        obj_20["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_20[
          gatewaydetails.data_20_name +
            " " +
            gatewaydetails.data_20_unit.data_unit
        ] = Number(data.data_20 * gatewaydetails.data_20_multiplier).toFixed(2);

        let obj_21 = {};
        obj_21["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_21[
          gatewaydetails.data_21_name +
            " " +
            gatewaydetails.data_21_unit.data_unit
        ] = Number(data.data_21 * gatewaydetails.data_21_multiplier).toFixed(2);

        let obj_22 = {};
        obj_22["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_22[
          gatewaydetails.data_22_name +
            " " +
            gatewaydetails.data_22_unit.data_unit
        ] = Number(data.data_22 * gatewaydetails.data_22_multiplier).toFixed(2);

        let obj_23 = {};
        obj_23["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_23[
          gatewaydetails.data_23_name +
            " " +
            gatewaydetails.data_23_unit.data_unit
        ] = Number(data.data_23 * gatewaydetails.data_23_multiplier).toFixed(2);

        let obj_24 = {};
        obj_24["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_24[
          gatewaydetails.data_24_name +
            " " +
            gatewaydetails.data_24_unit.data_unit
        ] = Number(data.data_24 * gatewaydetails.data_24_multiplier).toFixed(2);

        let obj_25 = {};
        obj_25["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_25[
          gatewaydetails.data_25_name +
            " " +
            gatewaydetails.data_25_unit.data_unit
        ] = Number(data.data_25 * gatewaydetails.data_25_multiplier).toFixed(2);

        let obj_26 = {};
        obj_26["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_26[
          gatewaydetails.data_26_name +
            " " +
            gatewaydetails.data_26_unit.data_unit
        ] = Number(data.data_26 * gatewaydetails.data_26_multiplier).toFixed(2);

        let obj_27 = {};
        obj_27["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_27[
          gatewaydetails.data_27_name +
            " " +
            gatewaydetails.data_27_unit.data_unit
        ] = Number(data.data_27 * gatewaydetails.data_27_multiplier).toFixed(2);

        let obj_28 = {};
        obj_28["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_28[
          gatewaydetails.data_28_name +
            " " +
            gatewaydetails.data_28_unit.data_unit
        ] = Number(data.data_28 * gatewaydetails.data_28_multiplier).toFixed(2);

        let obj_29 = {};
        obj_29["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_29[
          gatewaydetails.data_29_name +
            " " +
            gatewaydetails.data_29_unit.data_unit
        ] = Number(data.data_29 * gatewaydetails.data_29_multiplier).toFixed(2);

        let obj_30 = {};
        obj_30["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_30[
          gatewaydetails.data_30_name +
            " " +
            gatewaydetails.data_30_unit.data_unit
        ] = Number(data.data_30 * gatewaydetails.data_30_multiplier).toFixed(2);

        let obj_31 = {};
        obj_31["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_31[
          gatewaydetails.data_31_name +
            " " +
            gatewaydetails.data_31_unit.data_unit
        ] = Number(data.data_31 * gatewaydetails.data_31_multiplier).toFixed(2);

        let obj_32 = {};
        obj_32["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_32[
          gatewaydetails.data_32_name +
            " " +
            gatewaydetails.data_32_unit.data_unit
        ] = Number(data.data_32 * gatewaydetails.data_32_multiplier).toFixed(2);

        let obj_33 = {};
        obj_33["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_33[
          gatewaydetails.data_33_name +
            " " +
            gatewaydetails.data_33_unit.data_unit
        ] = Number(data.data_33 * gatewaydetails.data_33_multiplier).toFixed(2);

        let obj_34 = {};
        obj_34["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_34[
          gatewaydetails.data_34_name +
            " " +
            gatewaydetails.data_34_unit.data_unit
        ] = Number(data.data_34 * gatewaydetails.data_34_multiplier).toFixed(2);

        let obj_35 = {};
        obj_35["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_35[
          gatewaydetails.data_35_name +
            " " +
            gatewaydetails.data_35_unit.data_unit
        ] = Number(data.data_35 * gatewaydetails.data_35_multiplier).toFixed(2);

        let obj_36 = {};
        obj_36["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_36[
          gatewaydetails.data_36_name +
            " " +
            gatewaydetails.data_36_unit.data_unit
        ] = Number(data.data_36 * gatewaydetails.data_36_multiplier).toFixed(2);

        let obj_37 = {};
        obj_37["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_37[
          gatewaydetails.data_37_name +
            " " +
            gatewaydetails.data_37_unit.data_unit
        ] = Number(data.data_37 * gatewaydetails.data_37_multiplier).toFixed(2);

        let obj_38 = {};
        obj_38["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_38[
          gatewaydetails.data_38_name +
            " " +
            gatewaydetails.data_38_unit.data_unit
        ] = Number(data.data_38 * gatewaydetails.data_38_multiplier).toFixed(2);

        let obj_39 = {};
        obj_39["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_39[
          gatewaydetails.data_39_name +
            " " +
            gatewaydetails.data_39_unit.data_unit
        ] = Number(data.data_39 * gatewaydetails.data_39_multiplier).toFixed(2);

        let obj_40 = {};
        obj_40["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_40[
          gatewaydetails.data_40_name +
            " " +
            gatewaydetails.data_40_unit.data_unit
        ] = Number(data.data_40 * gatewaydetails.data_40_multiplier).toFixed(2);

        let obj_41 = {};
        obj_41["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 419);
        obj_41[
          gatewaydetails.data_41_name +
            " " +
            gatewaydetails.data_41_unit.data_unit
        ] = Number(data.data_41 * gatewaydetails.data_41_multiplier).toFixed(2);

        let obj_42 = {};
        obj_42["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_42[
          gatewaydetails.data_42_name +
            " " +
            gatewaydetails.data_42_unit.data_unit
        ] = Number(data.data_42 * gatewaydetails.data_42_multiplier).toFixed(2);

        let obj_43 = {};
        obj_43["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_43[
          gatewaydetails.data_43_name +
            " " +
            gatewaydetails.data_43_unit.data_unit
        ] = Number(data.data_43 * gatewaydetails.data_43_multiplier).toFixed(2);

        let obj_44 = {};
        obj_44["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_44[
          gatewaydetails.data_44_name +
            " " +
            gatewaydetails.data_44_unit.data_unit
        ] = Number(data.data_44 * gatewaydetails.data_44_multiplier).toFixed(2);

        let obj_45 = {};
        obj_45["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_45[
          gatewaydetails.data_45_name +
            " " +
            gatewaydetails.data_45_unit.data_unit
        ] = Number(data.data_45 * gatewaydetails.data_45_multiplier).toFixed(2);

        let obj_46 = {};
        obj_46["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_46[
          gatewaydetails.data_46_name +
            " " +
            gatewaydetails.data_46_unit.data_unit
        ] = Number(data.data_46 * gatewaydetails.data_46_multiplier).toFixed(2);

        let obj_47 = {};
        obj_47["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_47[
          gatewaydetails.data_47_name +
            " " +
            gatewaydetails.data_47_unit.data_unit
        ] = Number(data.data_47 * gatewaydetails.data_47_multiplier).toFixed(2);

        let obj_48 = {};
        obj_48["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_48[
          gatewaydetails.data_48_name +
            " " +
            gatewaydetails.data_48_unit.data_unit
        ] = Number(data.data_48 * gatewaydetails.data_48_multiplier).toFixed(2);

        let obj_49 = {};
        obj_49["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_49[
          gatewaydetails.data_49_name +
            " " +
            gatewaydetails.data_49_unit.data_unit
        ] = Number(data.data_49 * gatewaydetails.data_49_multiplier).toFixed(2);

        let obj_50 = {};
        obj_50["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_50[
          gatewaydetails.data_50_name +
            " " +
            gatewaydetails.data_50_unit.data_unit
        ] = Number(data.data_50 * gatewaydetails.data_50_multiplier).toFixed(2);

        let obj_51 = {};
        obj_51["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_51[
          gatewaydetails.data_51_name +
            " " +
            gatewaydetails.data_51_unit.data_unit
        ] = Number(data.data_51 * gatewaydetails.data_51_multiplier).toFixed(2);

        let obj_52 = {};
        obj_52["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_52[
          gatewaydetails.data_52_name +
            " " +
            gatewaydetails.data_52_unit.data_unit
        ] = Number(data.data_52 * gatewaydetails.data_52_multiplier).toFixed(2);

        let obj_53 = {};
        obj_53["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_53[
          gatewaydetails.data_53_name +
            " " +
            gatewaydetails.data_53_unit.data_unit
        ] = Number(data.data_53 * gatewaydetails.data_53_multiplier).toFixed(2);

        let obj_54 = {};
        obj_1["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_54[
          gatewaydetails.data_54_name +
            " " +
            gatewaydetails.data_54_unit.data_unit
        ] = Number(data.data_54 * gatewaydetails.data_54_multiplier).toFixed(2);

        let obj_55 = {};
        obj_55["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_55[
          gatewaydetails.data_55_name +
            " " +
            gatewaydetails.data_55_unit.data_unit
        ] = Number(data.data_55 * gatewaydetails.data_55_multiplier).toFixed(2);

        let obj_56 = {};
        obj_56["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_56[
          gatewaydetails.data_56_name +
            " " +
            gatewaydetails.data_56_unit.data_unit
        ] = Number(data.data_56 * gatewaydetails.data_56_multiplier).toFixed(2);

        let obj_57 = {};
        obj_57["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_57[
          gatewaydetails.data_57_name +
            " " +
            gatewaydetails.data_57_unit.data_unit
        ] = Number(data.data_57 * gatewaydetails.data_57_multiplier).toFixed(2);

        let obj_58 = {};
        obj_58["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_58[
          gatewaydetails.data_58_name +
            " " +
            gatewaydetails.data_58_unit.data_unit
        ] = Number(data.data_58 * gatewaydetails.data_58_multiplier).toFixed(2);

        let obj_59 = {};
        obj_59["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_59[
          gatewaydetails.data_59_name +
            " " +
            gatewaydetails.data_59_unit.data_unit
        ] = Number(data.data_59 * gatewaydetails.data_59_multiplier).toFixed(2);

        let obj_60 = {};
        obj_60["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_60[
          gatewaydetails.data_60_name +
            " " +
            gatewaydetails.data_60_unit.data_unit
        ] = Number(data.data_60 * gatewaydetails.data_60_multiplier).toFixed(2);

        let obj_61 = {};
        obj_61["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_61[
          gatewaydetails.data_61_name +
            " " +
            gatewaydetails.data_61_unit.data_unit
        ] = Number(data.data_61 * gatewaydetails.data_61_multiplier).toFixed(2);

        let obj_62 = {};
        obj_62["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_62[
          gatewaydetails.data_62_name +
            " " +
            gatewaydetails.data_62_unit.data_unit
        ] = Number(data.data_62 * gatewaydetails.data_62_multiplier).toFixed(2);

        let obj_63 = {};
        obj_63["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_63[
          gatewaydetails.data_63_name +
            " " +
            gatewaydetails.data_63_unit.data_unit
        ] = Number(data.data_63 * gatewaydetails.data_63_multiplier).toFixed(2);

        let obj_64 = {};
        obj_64["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_64[
          gatewaydetails.data_64_name +
            " " +
            gatewaydetails.data_64_unit.data_unit
        ] = Number(data.data_64 * gatewaydetails.data_64_multiplier).toFixed(2);

        let obj_65 = {};
        obj_65["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_65[
          gatewaydetails.data_65_name +
            " " +
            gatewaydetails.data_65_unit.data_unit
        ] = Number(data.data_65 * gatewaydetails.data_65_multiplier).toFixed(2);

        let obj_66 = {};
        obj_66["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_66[
          gatewaydetails.data_66_name +
            " " +
            gatewaydetails.data_66_unit.data_unit
        ] = Number(data.data_66 * gatewaydetails.data_66_multiplier).toFixed(2);

        let obj_67 = {};
        obj_67["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_67[
          gatewaydetails.data_67_name +
            " " +
            gatewaydetails.data_67_unit.data_unit
        ] = Number(data.data_67 * gatewaydetails.data_67_multiplier).toFixed(2);

        let obj_68 = {};
        obj_68["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_68[
          gatewaydetails.data_68_name +
            " " +
            gatewaydetails.data_68_unit.data_unit
        ] = Number(data.data_68 * gatewaydetails.data_68_multiplier).toFixed(2);

        let obj_69 = {};
        obj_69["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 169);
        obj_69[
          gatewaydetails.data_69_name +
            " " +
            gatewaydetails.data_69_unit.data_unit
        ] = Number(data.data_69 * gatewaydetails.data_69_multiplier).toFixed(2);

        let obj_70 = {};
        obj_70["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_70[
          gatewaydetails.data_70_name +
            " " +
            gatewaydetails.data_70_unit.data_unit
        ] = Number(data.data_70 * gatewaydetails.data_70_multiplier).toFixed(2);

        let obj_71 = {};
        obj_71["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_71[
          gatewaydetails.data_71_name +
            " " +
            gatewaydetails.data_71_unit.data_unit
        ] = Number(data.data_71 * gatewaydetails.data_71_multiplier).toFixed(2);

        let obj_72 = {};
        obj_72["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_72[
          gatewaydetails.data_72_name +
            " " +
            gatewaydetails.data_72_unit.data_unit
        ] = Number(data.data_72 * gatewaydetails.data_72_multiplier).toFixed(2);

        let obj_73 = {};
        obj_73["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_73[
          gatewaydetails.data_73_name +
            " " +
            gatewaydetails.data_73_unit.data_unit
        ] = Number(data.data_73 * gatewaydetails.data_73_multiplier).toFixed(2);

        let obj_74 = {};
        obj_1["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_74[
          gatewaydetails.data_74_name +
            " " +
            gatewaydetails.data_74_unit.data_unit
        ] = Number(data.data_74 * gatewaydetails.data_74_multiplier).toFixed(2);

        let obj_75 = {};
        obj_75["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_75[
          gatewaydetails.data_75_name +
            " " +
            gatewaydetails.data_75_unit.data_unit
        ] = Number(data.data_75 * gatewaydetails.data_75_multiplier).toFixed(2);

        let obj_76 = {};
        obj_76["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_76[
          gatewaydetails.data_76_name +
            " " +
            gatewaydetails.data_76_unit.data_unit
        ] = Number(data.data_76 * gatewaydetails.data_76_multiplier).toFixed(2);

        let obj_77 = {};
        obj_77["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_77[
          gatewaydetails.data_77_name +
            " " +
            gatewaydetails.data_77_unit.data_unit
        ] = Number(data.data_77 * gatewaydetails.data_77_multiplier).toFixed(2);

        let obj_78 = {};
        obj_78["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_78[
          gatewaydetails.data_78_name +
            " " +
            gatewaydetails.data_78_unit.data_unit
        ] = Number(data.data_78 * gatewaydetails.data_78_multiplier).toFixed(2);

        let obj_79 = {};
        obj_79["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_79[
          gatewaydetails.data_79_name +
            " " +
            gatewaydetails.data_79_unit.data_unit
        ] = Number(data.data_79 * gatewaydetails.data_79_multiplier).toFixed(2);

        let obj_80 = {};
        obj_80["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_80[
          gatewaydetails.data_80_name +
            " " +
            gatewaydetails.data_80_unit.data_unit
        ] = Number(data.data_80 * gatewaydetails.data_80_multiplier).toFixed(2);

        let obj_81 = {};
        obj_81["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_81[
          gatewaydetails.data_81_name +
            " " +
            gatewaydetails.data_81_unit.data_unit
        ] = Number(data.data_81 * gatewaydetails.data_81_multiplier).toFixed(2);

        let obj_82 = {};
        obj_82["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_82[
          gatewaydetails.data_82_name +
            " " +
            gatewaydetails.data_82_unit.data_unit
        ] = Number(data.data_82 * gatewaydetails.data_82_multiplier).toFixed(2);

        let obj_83 = {};
        obj_83["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_83[
          gatewaydetails.data_83_name +
            " " +
            gatewaydetails.data_83_unit.data_unit
        ] = Number(data.data_83 * gatewaydetails.data_83_multiplier).toFixed(2);

        let obj_84 = {};
        obj_84["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_84[
          gatewaydetails.data_84_name +
            " " +
            gatewaydetails.data_84_unit.data_unit
        ] = Number(data.data_84 * gatewaydetails.data_84_multiplier).toFixed(2);

        let obj_85 = {};
        obj_85["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_85[
          gatewaydetails.data_85_name +
            " " +
            gatewaydetails.data_85_unit.data_unit
        ] = Number(data.data_85 * gatewaydetails.data_85_multiplier).toFixed(2);

        let obj_86 = {};
        obj_86["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_86[
          gatewaydetails.data_86_name +
            " " +
            gatewaydetails.data_86_unit.data_unit
        ] = Number(data.data_86 * gatewaydetails.data_86_multiplier).toFixed(2);

        let obj_87 = {};
        obj_87["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_87[
          gatewaydetails.data_87_name +
            " " +
            gatewaydetails.data_87_unit.data_unit
        ] = Number(data.data_87 * gatewaydetails.data_87_multiplier).toFixed(2);

        let obj_88 = {};
        obj_88["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_88[
          gatewaydetails.data_88_name +
            " " +
            gatewaydetails.data_88_unit.data_unit
        ] = Number(data.data_88 * gatewaydetails.data_88_multiplier).toFixed(2);

        let obj_89 = {};
        obj_89["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_89[
          gatewaydetails.data_89_name +
            " " +
            gatewaydetails.data_89_unit.data_unit
        ] = Number(data.data_89 * gatewaydetails.data_89_multiplier).toFixed(2);

        let obj_90 = {};
        obj_90["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_90[
          gatewaydetails.data_90_name +
            " " +
            gatewaydetails.data_90_unit.data_unit
        ] = Number(data.data_90 * gatewaydetails.data_90_multiplier).toFixed(2);

        let obj_91 = {};
        obj_91["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_91[
          gatewaydetails.data_91_name +
            " " +
            gatewaydetails.data_91_unit.data_unit
        ] = Number(data.data_91 * gatewaydetails.data_91_multiplier).toFixed(2);

        let obj_92 = {};
        obj_92["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_92[
          gatewaydetails.data_92_name +
            " " +
            gatewaydetails.data_92_unit.data_unit
        ] = Number(data.data_92 * gatewaydetails.data_92_multiplier).toFixed(2);

        let obj_93 = {};
        obj_93["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_93[
          gatewaydetails.data_93_name +
            " " +
            gatewaydetails.data_93_unit.data_unit
        ] = Number(data.data_93 * gatewaydetails.data_93_multiplier).toFixed(2);

        let obj_94 = {};
        obj_1["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_94[
          gatewaydetails.data_94_name +
            " " +
            gatewaydetails.data_94_unit.data_unit
        ] = Number(data.data_94 * gatewaydetails.data_94_multiplier).toFixed(2);

        let obj_95 = {};
        obj_95["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_95[
          gatewaydetails.data_95_name +
            " " +
            gatewaydetails.data_95_unit.data_unit
        ] = Number(data.data_95 * gatewaydetails.data_95_multiplier).toFixed(2);

        let obj_96 = {};
        obj_96["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_96[
          gatewaydetails.data_96_name +
            " " +
            gatewaydetails.data_96_unit.data_unit
        ] = Number(data.data_96 * gatewaydetails.data_96_multiplier).toFixed(2);

        let obj_97 = {};
        obj_97["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_97[
          gatewaydetails.data_97_name +
            " " +
            gatewaydetails.data_97_unit.data_unit
        ] = Number(data.data_97 * gatewaydetails.data_97_multiplier).toFixed(2);

        let obj_98 = {};
        obj_98["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_98[
          gatewaydetails.data_98_name +
            " " +
            gatewaydetails.data_98_unit.data_unit
        ] = Number(data.data_98 * gatewaydetails.data_98_multiplier).toFixed(2);

        let obj_99 = {};
        obj_99["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_99[
          gatewaydetails.data_99_name +
            " " +
            gatewaydetails.data_99_unit.data_unit
        ] = Number(data.data_99 * gatewaydetails.data_99_multiplier).toFixed(2);

        data_0.push(obj_0);
        data_1.push(obj_1);
        data_2.push(obj_2);
        data_3.push(obj_3);
        data_4.push(obj_4);
        data_5.push(obj_5);
        data_6.push(obj_6);
        data_7.push(obj_7);
        data_8.push(obj_8);
        data_9.push(obj_9);
        data_10.push(obj_10);
        data_11.push(obj_11);
        data_12.push(obj_12);
        data_13.push(obj_13);
        data_14.push(obj_14);
        data_15.push(obj_15);
        data_16.push(obj_16);
        data_17.push(obj_17);
        data_18.push(obj_18);
        data_19.push(obj_19);
        data_20.push(obj_20);
        data_21.push(obj_21);
        data_22.push(obj_22);
        data_23.push(obj_23);
        data_24.push(obj_24);
        data_25.push(obj_25);
        data_26.push(obj_26);
        data_27.push(obj_27);
        data_28.push(obj_28);
        data_29.push(obj_29);
        data_30.push(obj_30);
        data_31.push(obj_31);
        data_32.push(obj_32);
        data_33.push(obj_33);
        data_34.push(obj_34);
        data_35.push(obj_35);
        data_36.push(obj_36);
        data_37.push(obj_37);
        data_38.push(obj_38);
        data_39.push(obj_39);
        data_40.push(obj_40);
        data_41.push(obj_41);
        data_42.push(obj_42);
        data_43.push(obj_43);
        data_44.push(obj_44);
        data_45.push(obj_45);
        data_46.push(obj_46);
        data_47.push(obj_47);
        data_48.push(obj_48);
        data_49.push(obj_49);
        data_50.push(obj_50);
        data_51.push(obj_51);
        data_52.push(obj_52);
        data_53.push(obj_53);
        data_54.push(obj_54);
        data_55.push(obj_55);
        data_56.push(obj_56);
        data_57.push(obj_57);
        data_58.push(obj_58);
        data_59.push(obj_59);
        data_60.push(obj_60);
        data_61.push(obj_61);
        data_62.push(obj_62);
        data_63.push(obj_63);
        data_64.push(obj_64);
        data_65.push(obj_65);
        data_66.push(obj_66);
        data_67.push(obj_67);
        data_68.push(obj_68);
        data_69.push(obj_69);
        data_70.push(obj_70);
        data_71.push(obj_71);
        data_72.push(obj_72);
        data_73.push(obj_73);
        data_74.push(obj_74);
        data_75.push(obj_75);
        data_76.push(obj_76);
        data_77.push(obj_77);
        data_78.push(obj_78);
        data_79.push(obj_79);
        data_80.push(obj_80);
        data_81.push(obj_81);
        data_82.push(obj_82);
        data_83.push(obj_83);
        data_84.push(obj_84);
        data_85.push(obj_85);
        data_86.push(obj_86);
        data_87.push(obj_87);
        data_88.push(obj_88);
        data_89.push(obj_89);
        data_90.push(obj_90);
        data_91.push(obj_91);
        data_92.push(obj_92);
        data_93.push(obj_93);
        data_94.push(obj_94);
        data_95.push(obj_95);
        data_96.push(obj_96);
        data_97.push(obj_97);
        data_98.push(obj_98);
        data_99.push(obj_99);
      });
      setData_0(data_0.reverse());
      setData_1(data_1.reverse());
      setData_2(data_2.reverse());
      setData_3(data_3.reverse());
      setData_4(data_4.reverse());
      setData_5(data_5.reverse());
      setData_6(data_6.reverse());
      setData_7(data_7.reverse());
      setData_8(data_8.reverse());
      setData_9(data_9.reverse());
      setData_10(data_10.reverse());
      setData_11(data_11.reverse());
      setData_12(data_12.reverse());
      setData_13(data_13.reverse());
      setData_14(data_14.reverse());
      setData_15(data_15.reverse());
      setData_16(data_16.reverse());
      setData_17(data_17.reverse());
      setData_18(data_18.reverse());
      setData_19(data_19.reverse());
      setData_20(data_20.reverse());
      setData_21(data_21.reverse());
      setData_22(data_22.reverse());
      setData_23(data_23.reverse());
      setData_24(data_24.reverse());
      setData_25(data_25.reverse());
      setData_26(data_26.reverse());
      setData_27(data_27.reverse());
      setData_28(data_28.reverse());
      setData_29(data_29.reverse());
      setData_30(data_30.reverse());
      setData_31(data_31.reverse());
      setData_32(data_32.reverse());
      setData_33(data_33.reverse());
      setData_34(data_34.reverse());
      setData_35(data_35.reverse());
      setData_36(data_36.reverse());
      setData_37(data_37.reverse());
      setData_38(data_38.reverse());
      setData_39(data_39.reverse());
      setData_40(data_40.reverse());
      setData_41(data_41.reverse());
      setData_42(data_42.reverse());
      setData_43(data_43.reverse());
      setData_44(data_44.reverse());
      setData_45(data_45.reverse());
      setData_46(data_46.reverse());
      setData_47(data_47.reverse());
      setData_48(data_48.reverse());
      setData_49(data_49.reverse());
      setData_50(data_50.reverse());
      setData_51(data_51.reverse());
      setData_52(data_52.reverse());
      setData_53(data_53.reverse());
      setData_54(data_54.reverse());
      setData_55(data_55.reverse());
      setData_56(data_56.reverse());
      setData_57(data_57.reverse());
      setData_58(data_58.reverse());
      setData_59(data_59.reverse());
      setData_60(data_60.reverse());
      setData_61(data_61.reverse());
      setData_62(data_62.reverse());
      setData_63(data_63.reverse());
      setData_64(data_64.reverse());
      setData_65(data_65.reverse());
      setData_66(data_66.reverse());
      setData_67(data_67.reverse());
      setData_68(data_68.reverse());
      setData_69(data_69.reverse());
      setData_70(data_70.reverse());
      setData_71(data_71.reverse());
      setData_72(data_72.reverse());
      setData_73(data_73.reverse());
      setData_74(data_74.reverse());
      setData_75(data_75.reverse());
      setData_76(data_76.reverse());
      setData_77(data_77.reverse());
      setData_78(data_78.reverse());
      setData_79(data_79.reverse());
      setData_80(data_80.reverse());
      setData_81(data_81.reverse());
      setData_82(data_82.reverse());
      setData_83(data_83.reverse());
      setData_84(data_84.reverse());
      setData_85(data_85.reverse());
      setData_86(data_86.reverse());
      setData_87(data_87.reverse());
      setData_88(data_88.reverse());
      setData_89(data_89.reverse());
      setData_90(data_90.reverse());
      setData_91(data_91.reverse());
      setData_92(data_92.reverse());
      setData_93(data_93.reverse());
      setData_94(data_94.reverse());
      setData_95(data_95.reverse());
      setData_96(data_96.reverse());
      setData_97(data_97.reverse());
      setData_98(data_98.reverse());
      setData_99(data_99.reverse());
      setmax_0(Math.max(...data_0.map((o) => o[Object.keys(data_0[0])[1]])));
      setmax_1(Math.max(...data_1.map((o) => o[Object.keys(data_1[0])[1]])));
      setmax_2(Math.max(...data_2.map((o) => o[Object.keys(data_2[0])[1]])));
      setmax_3(Math.max(...data_3.map((o) => o[Object.keys(data_3[0])[1]])));
      setmax_4(Math.max(...data_4.map((o) => o[Object.keys(data_4[0])[1]])));
      setmax_5(Math.max(...data_5.map((o) => o[Object.keys(data_5[0])[1]])));
      setmax_6(Math.max(...data_6.map((o) => o[Object.keys(data_6[0])[1]])));
      setmax_7(Math.max(...data_7.map((o) => o[Object.keys(data_7[0])[1]])));
      setmax_8(Math.max(...data_8.map((o) => o[Object.keys(data_8[0])[1]])));
      setmax_9(Math.max(...data_9.map((o) => o[Object.keys(data_9[0])[1]])));
      setmax_10(Math.max(...data_10.map((o) => o[Object.keys(data_10[0])[1]])));
      setmax_11(Math.max(...data_11.map((o) => o[Object.keys(data_11[0])[1]])));
      setmax_12(Math.max(...data_12.map((o) => o[Object.keys(data_12[0])[1]])));
      setmax_13(Math.max(...data_13.map((o) => o[Object.keys(data_13[0])[1]])));
      setmax_14(Math.max(...data_14.map((o) => o[Object.keys(data_14[0])[1]])));
      setmax_15(Math.max(...data_15.map((o) => o[Object.keys(data_15[0])[1]])));
      setmax_16(Math.max(...data_16.map((o) => o[Object.keys(data_16[0])[1]])));
      setmax_17(Math.max(...data_17.map((o) => o[Object.keys(data_17[0])[1]])));
      setmax_18(Math.max(...data_18.map((o) => o[Object.keys(data_18[0])[1]])));
      setmax_19(Math.max(...data_19.map((o) => o[Object.keys(data_19[0])[1]])));
      setmax_20(Math.max(...data_20.map((o) => o[Object.keys(data_20[0])[1]])));
      setmax_21(Math.max(...data_21.map((o) => o[Object.keys(data_21[0])[1]])));
      setmax_22(Math.max(...data_22.map((o) => o[Object.keys(data_22[0])[1]])));
      setmax_23(Math.max(...data_23.map((o) => o[Object.keys(data_23[0])[1]])));
      setmax_24(Math.max(...data_24.map((o) => o[Object.keys(data_24[0])[1]])));
      setmax_25(Math.max(...data_25.map((o) => o[Object.keys(data_25[0])[1]])));
      setmax_26(Math.max(...data_26.map((o) => o[Object.keys(data_26[0])[1]])));
      setmax_27(Math.max(...data_27.map((o) => o[Object.keys(data_27[0])[1]])));
      setmax_28(Math.max(...data_28.map((o) => o[Object.keys(data_28[0])[1]])));
      setmax_29(Math.max(...data_29.map((o) => o[Object.keys(data_29[0])[1]])));
      setmax_30(Math.max(...data_30.map((o) => o[Object.keys(data_30[0])[1]])));
      setmax_31(Math.max(...data_31.map((o) => o[Object.keys(data_31[0])[1]])));
      setmax_32(Math.max(...data_32.map((o) => o[Object.keys(data_32[0])[1]])));
      setmax_33(Math.max(...data_33.map((o) => o[Object.keys(data_33[0])[1]])));
      setmax_34(Math.max(...data_34.map((o) => o[Object.keys(data_34[0])[1]])));
      setmax_35(Math.max(...data_35.map((o) => o[Object.keys(data_35[0])[1]])));
      setmax_36(Math.max(...data_36.map((o) => o[Object.keys(data_36[0])[1]])));
      setmax_37(Math.max(...data_37.map((o) => o[Object.keys(data_37[0])[1]])));
      setmax_38(Math.max(...data_38.map((o) => o[Object.keys(data_38[0])[1]])));
      setmax_39(Math.max(...data_39.map((o) => o[Object.keys(data_39[0])[1]])));
      setmax_40(Math.max(...data_40.map((o) => o[Object.keys(data_40[0])[1]])));
      setmax_41(Math.max(...data_41.map((o) => o[Object.keys(data_41[0])[1]])));
      setmax_42(Math.max(...data_42.map((o) => o[Object.keys(data_42[0])[1]])));
      setmax_43(Math.max(...data_43.map((o) => o[Object.keys(data_43[0])[1]])));
      setmax_44(Math.max(...data_44.map((o) => o[Object.keys(data_44[0])[1]])));
      setmax_45(Math.max(...data_45.map((o) => o[Object.keys(data_45[0])[1]])));
      setmax_46(Math.max(...data_46.map((o) => o[Object.keys(data_46[0])[1]])));
      setmax_47(Math.max(...data_47.map((o) => o[Object.keys(data_47[0])[1]])));
      setmax_48(Math.max(...data_48.map((o) => o[Object.keys(data_48[0])[1]])));
      setmax_49(Math.max(...data_49.map((o) => o[Object.keys(data_49[0])[1]])));
      setmax_50(Math.max(...data_50.map((o) => o[Object.keys(data_50[0])[1]])));
      setmax_51(Math.max(...data_51.map((o) => o[Object.keys(data_51[0])[1]])));
      setmax_52(Math.max(...data_52.map((o) => o[Object.keys(data_52[0])[1]])));
      setmax_53(Math.max(...data_53.map((o) => o[Object.keys(data_53[0])[1]])));
      setmax_54(Math.max(...data_54.map((o) => o[Object.keys(data_54[0])[1]])));
      setmax_55(Math.max(...data_55.map((o) => o[Object.keys(data_55[0])[1]])));
      setmax_56(Math.max(...data_56.map((o) => o[Object.keys(data_56[0])[1]])));
      setmax_57(Math.max(...data_57.map((o) => o[Object.keys(data_57[0])[1]])));
      setmax_58(Math.max(...data_58.map((o) => o[Object.keys(data_58[0])[1]])));
      setmax_59(Math.max(...data_59.map((o) => o[Object.keys(data_59[0])[1]])));
      setmax_60(Math.max(...data_60.map((o) => o[Object.keys(data_60[0])[1]])));
      setmax_61(Math.max(...data_61.map((o) => o[Object.keys(data_61[0])[1]])));
      setmax_62(Math.max(...data_62.map((o) => o[Object.keys(data_62[0])[1]])));
      setmax_63(Math.max(...data_63.map((o) => o[Object.keys(data_63[0])[1]])));
      setmax_64(Math.max(...data_64.map((o) => o[Object.keys(data_64[0])[1]])));
      setmax_65(Math.max(...data_65.map((o) => o[Object.keys(data_65[0])[1]])));
      setmax_66(Math.max(...data_66.map((o) => o[Object.keys(data_66[0])[1]])));
      setmax_67(Math.max(...data_67.map((o) => o[Object.keys(data_67[0])[1]])));
      setmax_68(Math.max(...data_68.map((o) => o[Object.keys(data_68[0])[1]])));
      setmax_69(Math.max(...data_69.map((o) => o[Object.keys(data_69[0])[1]])));
      setmax_70(Math.max(...data_70.map((o) => o[Object.keys(data_70[0])[1]])));
      setmax_71(Math.max(...data_71.map((o) => o[Object.keys(data_71[0])[1]])));
      setmax_72(Math.max(...data_72.map((o) => o[Object.keys(data_72[0])[1]])));
      setmax_73(Math.max(...data_73.map((o) => o[Object.keys(data_73[0])[1]])));
      setmax_74(Math.max(...data_74.map((o) => o[Object.keys(data_74[0])[1]])));
      setmax_75(Math.max(...data_75.map((o) => o[Object.keys(data_75[0])[1]])));
      setmax_76(Math.max(...data_76.map((o) => o[Object.keys(data_76[0])[1]])));
      setmax_77(Math.max(...data_77.map((o) => o[Object.keys(data_77[0])[1]])));
      setmax_78(Math.max(...data_78.map((o) => o[Object.keys(data_78[0])[1]])));
      setmax_79(Math.max(...data_79.map((o) => o[Object.keys(data_79[0])[1]])));
      setmax_80(Math.max(...data_80.map((o) => o[Object.keys(data_80[0])[1]])));
      setmax_81(Math.max(...data_81.map((o) => o[Object.keys(data_81[0])[1]])));
      setmax_82(Math.max(...data_82.map((o) => o[Object.keys(data_82[0])[1]])));
      setmax_83(Math.max(...data_83.map((o) => o[Object.keys(data_83[0])[1]])));
      setmax_84(Math.max(...data_84.map((o) => o[Object.keys(data_84[0])[1]])));
      setmax_85(Math.max(...data_85.map((o) => o[Object.keys(data_85[0])[1]])));
      setmax_86(Math.max(...data_86.map((o) => o[Object.keys(data_86[0])[1]])));
      setmax_87(Math.max(...data_87.map((o) => o[Object.keys(data_87[0])[1]])));
      setmax_88(Math.max(...data_88.map((o) => o[Object.keys(data_88[0])[1]])));
      setmax_89(Math.max(...data_89.map((o) => o[Object.keys(data_89[0])[1]])));
      setmax_90(Math.max(...data_90.map((o) => o[Object.keys(data_90[0])[1]])));
      setmax_91(Math.max(...data_91.map((o) => o[Object.keys(data_91[0])[1]])));
      setmax_92(Math.max(...data_92.map((o) => o[Object.keys(data_92[0])[1]])));
      setmax_93(Math.max(...data_93.map((o) => o[Object.keys(data_93[0])[1]])));
      setmax_94(Math.max(...data_94.map((o) => o[Object.keys(data_94[0])[1]])));
      setmax_95(Math.max(...data_95.map((o) => o[Object.keys(data_95[0])[1]])));
      setmax_96(Math.max(...data_96.map((o) => o[Object.keys(data_96[0])[1]])));
      setmax_97(Math.max(...data_97.map((o) => o[Object.keys(data_97[0])[1]])));
      setmax_98(Math.max(...data_98.map((o) => o[Object.keys(data_98[0])[1]])));
      setmax_99(Math.max(...data_99.map((o) => o[Object.keys(data_99[0])[1]])));

      setmin_0(Math.min(...data_0.map((o) => o[Object.keys(data_0[0])[1]])));
      setmin_1(Math.min(...data_1.map((o) => o[Object.keys(data_1[0])[1]])));
      setmin_2(Math.min(...data_2.map((o) => o[Object.keys(data_2[0])[1]])));
      setmin_3(Math.min(...data_3.map((o) => o[Object.keys(data_3[0])[1]])));
      setmin_4(Math.min(...data_4.map((o) => o[Object.keys(data_4[0])[1]])));
      setmin_5(Math.min(...data_5.map((o) => o[Object.keys(data_5[0])[1]])));
      setmin_6(Math.min(...data_6.map((o) => o[Object.keys(data_6[0])[1]])));
      setmin_7(Math.min(...data_7.map((o) => o[Object.keys(data_7[0])[1]])));
      setmin_8(Math.min(...data_8.map((o) => o[Object.keys(data_8[0])[1]])));
      setmin_9(Math.min(...data_9.map((o) => o[Object.keys(data_9[0])[1]])));
      setmin_10(Math.min(...data_10.map((o) => o[Object.keys(data_10[0])[1]])));
      setmin_11(Math.min(...data_11.map((o) => o[Object.keys(data_11[0])[1]])));
      setmin_12(Math.min(...data_12.map((o) => o[Object.keys(data_12[0])[1]])));
      setmin_13(Math.min(...data_13.map((o) => o[Object.keys(data_13[0])[1]])));
      setmin_14(Math.min(...data_14.map((o) => o[Object.keys(data_14[0])[1]])));
      setmin_15(Math.min(...data_15.map((o) => o[Object.keys(data_15[0])[1]])));
      setmin_16(Math.min(...data_16.map((o) => o[Object.keys(data_16[0])[1]])));
      setmin_17(Math.min(...data_17.map((o) => o[Object.keys(data_17[0])[1]])));
      setmin_18(Math.min(...data_18.map((o) => o[Object.keys(data_18[0])[1]])));
      setmin_19(Math.min(...data_19.map((o) => o[Object.keys(data_19[0])[1]])));
      setmin_20(Math.min(...data_20.map((o) => o[Object.keys(data_20[0])[1]])));
      setmin_21(Math.min(...data_21.map((o) => o[Object.keys(data_21[0])[1]])));
      setmin_22(Math.min(...data_22.map((o) => o[Object.keys(data_22[0])[1]])));
      setmin_23(Math.min(...data_23.map((o) => o[Object.keys(data_23[0])[1]])));
      setmin_24(Math.min(...data_24.map((o) => o[Object.keys(data_24[0])[1]])));
      setmin_25(Math.min(...data_25.map((o) => o[Object.keys(data_25[0])[1]])));
      setmin_26(Math.min(...data_26.map((o) => o[Object.keys(data_26[0])[1]])));
      setmin_27(Math.min(...data_27.map((o) => o[Object.keys(data_27[0])[1]])));
      setmin_28(Math.min(...data_28.map((o) => o[Object.keys(data_28[0])[1]])));
      setmin_29(Math.min(...data_29.map((o) => o[Object.keys(data_29[0])[1]])));
      setmin_30(Math.min(...data_30.map((o) => o[Object.keys(data_30[0])[1]])));
      setmin_31(Math.min(...data_31.map((o) => o[Object.keys(data_31[0])[1]])));
      setmin_32(Math.min(...data_32.map((o) => o[Object.keys(data_32[0])[1]])));
      setmin_33(Math.min(...data_33.map((o) => o[Object.keys(data_33[0])[1]])));
      setmin_34(Math.min(...data_34.map((o) => o[Object.keys(data_34[0])[1]])));
      setmin_35(Math.min(...data_35.map((o) => o[Object.keys(data_35[0])[1]])));
      setmin_36(Math.min(...data_36.map((o) => o[Object.keys(data_36[0])[1]])));
      setmin_37(Math.min(...data_37.map((o) => o[Object.keys(data_37[0])[1]])));
      setmin_38(Math.min(...data_38.map((o) => o[Object.keys(data_38[0])[1]])));
      setmin_39(Math.min(...data_39.map((o) => o[Object.keys(data_39[0])[1]])));
      setmin_40(Math.min(...data_40.map((o) => o[Object.keys(data_40[0])[1]])));
      setmin_41(Math.min(...data_41.map((o) => o[Object.keys(data_41[0])[1]])));
      setmin_42(Math.min(...data_42.map((o) => o[Object.keys(data_42[0])[1]])));
      setmin_43(Math.min(...data_43.map((o) => o[Object.keys(data_43[0])[1]])));
      setmin_44(Math.min(...data_44.map((o) => o[Object.keys(data_44[0])[1]])));
      setmin_45(Math.min(...data_45.map((o) => o[Object.keys(data_45[0])[1]])));
      setmin_46(Math.min(...data_46.map((o) => o[Object.keys(data_46[0])[1]])));
      setmin_47(Math.min(...data_47.map((o) => o[Object.keys(data_47[0])[1]])));
      setmin_48(Math.min(...data_48.map((o) => o[Object.keys(data_48[0])[1]])));
      setmin_49(Math.min(...data_49.map((o) => o[Object.keys(data_49[0])[1]])));
      setmin_50(Math.min(...data_50.map((o) => o[Object.keys(data_50[0])[1]])));
      setmin_51(Math.min(...data_51.map((o) => o[Object.keys(data_51[0])[1]])));
      setmin_52(Math.min(...data_52.map((o) => o[Object.keys(data_52[0])[1]])));
      setmin_53(Math.min(...data_53.map((o) => o[Object.keys(data_53[0])[1]])));
      setmin_54(Math.min(...data_54.map((o) => o[Object.keys(data_54[0])[1]])));
      setmin_55(Math.min(...data_55.map((o) => o[Object.keys(data_55[0])[1]])));
      setmin_56(Math.min(...data_56.map((o) => o[Object.keys(data_56[0])[1]])));
      setmin_57(Math.min(...data_57.map((o) => o[Object.keys(data_57[0])[1]])));
      setmin_58(Math.min(...data_58.map((o) => o[Object.keys(data_58[0])[1]])));
      setmin_59(Math.min(...data_59.map((o) => o[Object.keys(data_59[0])[1]])));
      setmin_60(Math.min(...data_60.map((o) => o[Object.keys(data_60[0])[1]])));
      setmin_61(Math.min(...data_61.map((o) => o[Object.keys(data_61[0])[1]])));
      setmin_62(Math.min(...data_62.map((o) => o[Object.keys(data_62[0])[1]])));
      setmin_63(Math.min(...data_63.map((o) => o[Object.keys(data_63[0])[1]])));
      setmin_64(Math.min(...data_64.map((o) => o[Object.keys(data_64[0])[1]])));
      setmin_65(Math.min(...data_65.map((o) => o[Object.keys(data_65[0])[1]])));
      setmin_66(Math.min(...data_66.map((o) => o[Object.keys(data_66[0])[1]])));
      setmin_67(Math.min(...data_67.map((o) => o[Object.keys(data_67[0])[1]])));
      setmin_68(Math.min(...data_68.map((o) => o[Object.keys(data_68[0])[1]])));
      setmin_69(Math.min(...data_69.map((o) => o[Object.keys(data_69[0])[1]])));
      setmin_70(Math.min(...data_70.map((o) => o[Object.keys(data_70[0])[1]])));
      setmin_71(Math.min(...data_71.map((o) => o[Object.keys(data_71[0])[1]])));
      setmin_72(Math.min(...data_72.map((o) => o[Object.keys(data_72[0])[1]])));
      setmin_73(Math.min(...data_73.map((o) => o[Object.keys(data_73[0])[1]])));
      setmin_74(Math.min(...data_74.map((o) => o[Object.keys(data_74[0])[1]])));
      setmin_75(Math.min(...data_75.map((o) => o[Object.keys(data_75[0])[1]])));
      setmin_76(Math.min(...data_76.map((o) => o[Object.keys(data_76[0])[1]])));
      setmin_77(Math.min(...data_77.map((o) => o[Object.keys(data_77[0])[1]])));
      setmin_78(Math.min(...data_78.map((o) => o[Object.keys(data_78[0])[1]])));
      setmin_79(Math.min(...data_79.map((o) => o[Object.keys(data_79[0])[1]])));
      setmin_80(Math.min(...data_80.map((o) => o[Object.keys(data_80[0])[1]])));
      setmin_81(Math.min(...data_81.map((o) => o[Object.keys(data_81[0])[1]])));
      setmin_82(Math.min(...data_82.map((o) => o[Object.keys(data_82[0])[1]])));
      setmin_83(Math.min(...data_83.map((o) => o[Object.keys(data_83[0])[1]])));
      setmin_84(Math.min(...data_84.map((o) => o[Object.keys(data_84[0])[1]])));
      setmin_85(Math.min(...data_85.map((o) => o[Object.keys(data_85[0])[1]])));
      setmin_86(Math.min(...data_86.map((o) => o[Object.keys(data_86[0])[1]])));
      setmin_87(Math.min(...data_87.map((o) => o[Object.keys(data_87[0])[1]])));
      setmin_88(Math.min(...data_88.map((o) => o[Object.keys(data_88[0])[1]])));
      setmin_89(Math.min(...data_89.map((o) => o[Object.keys(data_89[0])[1]])));
      setmin_90(Math.min(...data_90.map((o) => o[Object.keys(data_90[0])[1]])));
      setmin_91(Math.min(...data_91.map((o) => o[Object.keys(data_91[0])[1]])));
      setmin_92(Math.min(...data_92.map((o) => o[Object.keys(data_92[0])[1]])));
      setmin_93(Math.min(...data_93.map((o) => o[Object.keys(data_93[0])[1]])));
      setmin_94(Math.min(...data_94.map((o) => o[Object.keys(data_94[0])[1]])));
      setmin_95(Math.min(...data_95.map((o) => o[Object.keys(data_95[0])[1]])));
      setmin_96(Math.min(...data_96.map((o) => o[Object.keys(data_96[0])[1]])));
      setmin_97(Math.min(...data_97.map((o) => o[Object.keys(data_97[0])[1]])));
      setmin_98(Math.min(...data_98.map((o) => o[Object.keys(data_98[0])[1]])));
      setmin_99(Math.min(...data_99.map((o) => o[Object.keys(data_99[0])[1]])));
    }
  }, [success, gatewaydetails, timezone]);

  useEffect(() => {
    if (updateSuccess) {
      let data_0 = [];
      let data_1 = [];
      let data_2 = [];
      let data_3 = [];
      let data_4 = [];
      let data_5 = [];
      let data_6 = [];
      let data_7 = [];
      let data_8 = [];
      let data_9 = [];
      let data_10 = [];
      let data_11 = [];
      let data_12 = [];
      let data_13 = [];
      let data_14 = [];
      let data_15 = [];
      let data_16 = [];
      let data_17 = [];
      let data_18 = [];
      let data_19 = [];
      let data_20 = [];
      let data_21 = [];
      let data_22 = [];
      let data_23 = [];
      let data_24 = [];
      let data_25 = [];
      let data_26 = [];
      let data_27 = [];
      let data_28 = [];
      let data_29 = [];
      let data_30 = [];
      let data_31 = [];
      let data_32 = [];
      let data_33 = [];
      let data_34 = [];
      let data_35 = [];
      let data_36 = [];
      let data_37 = [];
      let data_38 = [];
      let data_39 = [];
      let data_40 = [];
      let data_41 = [];
      let data_42 = [];
      let data_43 = [];
      let data_44 = [];
      let data_45 = [];
      let data_46 = [];
      let data_47 = [];
      let data_48 = [];
      let data_49 = [];
      let data_50 = [];
      let data_51 = [];
      let data_52 = [];
      let data_53 = [];
      let data_54 = [];
      let data_55 = [];
      let data_56 = [];
      let data_57 = [];
      let data_58 = [];
      let data_59 = [];
      let data_60 = [];
      let data_61 = [];
      let data_62 = [];
      let data_63 = [];
      let data_64 = [];
      let data_65 = [];
      let data_66 = [];
      let data_67 = [];
      let data_68 = [];
      let data_69 = [];
      let data_70 = [];
      let data_71 = [];
      let data_72 = [];
      let data_73 = [];
      let data_74 = [];
      let data_75 = [];
      let data_76 = [];
      let data_77 = [];
      let data_78 = [];
      let data_79 = [];
      let data_80 = [];
      let data_81 = [];
      let data_82 = [];
      let data_83 = [];
      let data_84 = [];
      let data_85 = [];
      let data_86 = [];
      let data_87 = [];
      let data_88 = [];
      let data_89 = [];
      let data_90 = [];
      let data_91 = [];
      let data_92 = [];
      let data_93 = [];
      let data_94 = [];
      let data_95 = [];
      let data_96 = [];
      let data_97 = [];
      let data_98 = [];
      let data_99 = [];
      gatewayupdates.data_chart.forEach((data) => {
        let obj_0 = {};
        obj_0["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_0[
          gatewaydetails.data_0_name +
            " " +
            gatewaydetails.data_0_unit.data_unit
        ] = Number(data.data_0 * gatewaydetails.data_0_multiplier).toFixed(2);

        let obj_1 = {};
        obj_1["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_1[
          gatewaydetails.data_1_name +
            " " +
            gatewaydetails.data_1_unit.data_unit
        ] = Number(data.data_1 * gatewaydetails.data_1_multiplier).toFixed(2);

        let obj_2 = {};
        obj_2["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_2[
          gatewaydetails.data_2_name +
            " " +
            gatewaydetails.data_2_unit.data_unit
        ] = Number(data.data_2 * gatewaydetails.data_2_multiplier).toFixed(2);

        let obj_3 = {};
        obj_3["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_3[
          gatewaydetails.data_3_name +
            " " +
            gatewaydetails.data_3_unit.data_unit
        ] = Number(data.data_3 * gatewaydetails.data_3_multiplier).toFixed(2);

        let obj_4 = {};
        obj_4["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_4[
          gatewaydetails.data_4_name +
            " " +
            gatewaydetails.data_4_unit.data_unit
        ] = Number(data.data_4 * gatewaydetails.data_4_multiplier).toFixed(2);

        let obj_5 = {};
        obj_5["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_5[
          gatewaydetails.data_5_name +
            " " +
            gatewaydetails.data_5_unit.data_unit
        ] = Number(data.data_5 * gatewaydetails.data_5_multiplier).toFixed(2);

        let obj_6 = {};
        obj_6["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_6[
          gatewaydetails.data_6_name +
            " " +
            gatewaydetails.data_6_unit.data_unit
        ] = Number(data.data_6 * gatewaydetails.data_6_multiplier).toFixed(2);

        let obj_7 = {};
        obj_7["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_7[
          gatewaydetails.data_7_name +
            " " +
            gatewaydetails.data_7_unit.data_unit
        ] = Number(data.data_7 * gatewaydetails.data_7_multiplier).toFixed(2);

        let obj_8 = {};
        obj_8["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_8[
          gatewaydetails.data_8_name +
            " " +
            gatewaydetails.data_8_unit.data_unit
        ] = Number(data.data_8 * gatewaydetails.data_8_multiplier).toFixed(2);

        let obj_9 = {};
        obj_9["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_9[
          gatewaydetails.data_9_name +
            " " +
            gatewaydetails.data_9_unit.data_unit
        ] = Number(data.data_9 * gatewaydetails.data_9_multiplier).toFixed(2);

        let obj_10 = {};
        obj_10["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_10[
          gatewaydetails.data_10_name +
            " " +
            gatewaydetails.data_10_unit.data_unit
        ] = Number(data.data_10 * gatewaydetails.data_10_multiplier).toFixed(2);

        let obj_11 = {};
        obj_11["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_11[
          gatewaydetails.data_11_name +
            " " +
            gatewaydetails.data_11_unit.data_unit
        ] = Number(data.data_11 * gatewaydetails.data_11_multiplier).toFixed(2);

        let obj_12 = {};
        obj_12["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_12[
          gatewaydetails.data_12_name +
            " " +
            gatewaydetails.data_12_unit.data_unit
        ] = Number(data.data_12 * gatewaydetails.data_12_multiplier).toFixed(2);

        let obj_13 = {};
        obj_13["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_13[
          gatewaydetails.data_13_name +
            " " +
            gatewaydetails.data_13_unit.data_unit
        ] = Number(data.data_13 * gatewaydetails.data_13_multiplier).toFixed(2);

        let obj_14 = {};
        obj_14["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_14[
          gatewaydetails.data_14_name +
            " " +
            gatewaydetails.data_14_unit.data_unit
        ] = Number(data.data_14 * gatewaydetails.data_14_multiplier).toFixed(2);

        let obj_15 = {};
        obj_15["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_15[
          gatewaydetails.data_15_name +
            " " +
            gatewaydetails.data_15_unit.data_unit
        ] = Number(data.data_15 * gatewaydetails.data_15_multiplier).toFixed(2);

        let obj_16 = {};
        obj_16["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_16[
          gatewaydetails.data_16_name +
            " " +
            gatewaydetails.data_16_unit.data_unit
        ] = Number(data.data_16 * gatewaydetails.data_16_multiplier).toFixed(2);

        let obj_17 = {};
        obj_17["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_17[
          gatewaydetails.data_17_name +
            " " +
            gatewaydetails.data_17_unit.data_unit
        ] = Number(data.data_17 * gatewaydetails.data_17_multiplier).toFixed(2);

        let obj_18 = {};
        obj_18["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_18[
          gatewaydetails.data_18_name +
            " " +
            gatewaydetails.data_18_unit.data_unit
        ] = Number(data.data_18 * gatewaydetails.data_18_multiplier).toFixed(2);

        let obj_19 = {};
        obj_19["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_19[
          gatewaydetails.data_19_name +
            " " +
            gatewaydetails.data_19_unit.data_unit
        ] = Number(data.data_19 * gatewaydetails.data_19_multiplier).toFixed(2);

        let obj_20 = {};
        obj_20["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_20[
          gatewaydetails.data_20_name +
            " " +
            gatewaydetails.data_20_unit.data_unit
        ] = Number(data.data_20 * gatewaydetails.data_20_multiplier).toFixed(2);

        let obj_21 = {};
        obj_21["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_21[
          gatewaydetails.data_21_name +
            " " +
            gatewaydetails.data_21_unit.data_unit
        ] = Number(data.data_21 * gatewaydetails.data_21_multiplier).toFixed(2);

        let obj_22 = {};
        obj_22["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_22[
          gatewaydetails.data_22_name +
            " " +
            gatewaydetails.data_22_unit.data_unit
        ] = Number(data.data_22 * gatewaydetails.data_22_multiplier).toFixed(2);

        let obj_23 = {};
        obj_23["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_23[
          gatewaydetails.data_23_name +
            " " +
            gatewaydetails.data_23_unit.data_unit
        ] = Number(data.data_23 * gatewaydetails.data_23_multiplier).toFixed(2);

        let obj_24 = {};
        obj_24["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_24[
          gatewaydetails.data_24_name +
            " " +
            gatewaydetails.data_24_unit.data_unit
        ] = Number(data.data_24 * gatewaydetails.data_24_multiplier).toFixed(2);

        let obj_25 = {};
        obj_25["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_25[
          gatewaydetails.data_25_name +
            " " +
            gatewaydetails.data_25_unit.data_unit
        ] = Number(data.data_25 * gatewaydetails.data_25_multiplier).toFixed(2);

        let obj_26 = {};
        obj_26["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_26[
          gatewaydetails.data_26_name +
            " " +
            gatewaydetails.data_26_unit.data_unit
        ] = Number(data.data_26 * gatewaydetails.data_26_multiplier).toFixed(2);

        let obj_27 = {};
        obj_27["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_27[
          gatewaydetails.data_27_name +
            " " +
            gatewaydetails.data_27_unit.data_unit
        ] = Number(data.data_27 * gatewaydetails.data_27_multiplier).toFixed(2);

        let obj_28 = {};
        obj_28["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_28[
          gatewaydetails.data_28_name +
            " " +
            gatewaydetails.data_28_unit.data_unit
        ] = Number(data.data_28 * gatewaydetails.data_28_multiplier).toFixed(2);

        let obj_29 = {};
        obj_29["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_29[
          gatewaydetails.data_29_name +
            " " +
            gatewaydetails.data_29_unit.data_unit
        ] = Number(data.data_29 * gatewaydetails.data_29_multiplier).toFixed(2);

        let obj_30 = {};
        obj_30["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_30[
          gatewaydetails.data_30_name +
            " " +
            gatewaydetails.data_30_unit.data_unit
        ] = Number(data.data_30 * gatewaydetails.data_30_multiplier).toFixed(2);

        let obj_31 = {};
        obj_31["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_31[
          gatewaydetails.data_31_name +
            " " +
            gatewaydetails.data_31_unit.data_unit
        ] = Number(data.data_31 * gatewaydetails.data_31_multiplier).toFixed(2);

        let obj_32 = {};
        obj_32["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_32[
          gatewaydetails.data_32_name +
            " " +
            gatewaydetails.data_32_unit.data_unit
        ] = Number(data.data_32 * gatewaydetails.data_32_multiplier).toFixed(2);

        let obj_33 = {};
        obj_33["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_33[
          gatewaydetails.data_33_name +
            " " +
            gatewaydetails.data_33_unit.data_unit
        ] = Number(data.data_33 * gatewaydetails.data_33_multiplier).toFixed(2);

        let obj_34 = {};
        obj_34["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_34[
          gatewaydetails.data_34_name +
            " " +
            gatewaydetails.data_34_unit.data_unit
        ] = Number(data.data_34 * gatewaydetails.data_34_multiplier).toFixed(2);

        let obj_35 = {};
        obj_35["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_35[
          gatewaydetails.data_35_name +
            " " +
            gatewaydetails.data_35_unit.data_unit
        ] = Number(data.data_35 * gatewaydetails.data_35_multiplier).toFixed(2);

        let obj_36 = {};
        obj_36["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_36[
          gatewaydetails.data_36_name +
            " " +
            gatewaydetails.data_36_unit.data_unit
        ] = Number(data.data_36 * gatewaydetails.data_36_multiplier).toFixed(2);

        let obj_37 = {};
        obj_37["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_37[
          gatewaydetails.data_37_name +
            " " +
            gatewaydetails.data_37_unit.data_unit
        ] = Number(data.data_37 * gatewaydetails.data_37_multiplier).toFixed(2);

        let obj_38 = {};
        obj_38["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_38[
          gatewaydetails.data_38_name +
            " " +
            gatewaydetails.data_38_unit.data_unit
        ] = Number(data.data_38 * gatewaydetails.data_38_multiplier).toFixed(2);

        let obj_39 = {};
        obj_39["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_39[
          gatewaydetails.data_39_name +
            " " +
            gatewaydetails.data_39_unit.data_unit
        ] = Number(data.data_39 * gatewaydetails.data_39_multiplier).toFixed(2);

        let obj_40 = {};
        obj_40["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_40[
          gatewaydetails.data_40_name +
            " " +
            gatewaydetails.data_40_unit.data_unit
        ] = Number(data.data_40 * gatewaydetails.data_40_multiplier).toFixed(2);

        let obj_41 = {};
        obj_41["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 419);
        obj_41[
          gatewaydetails.data_41_name +
            " " +
            gatewaydetails.data_41_unit.data_unit
        ] = Number(data.data_41 * gatewaydetails.data_41_multiplier).toFixed(2);

        let obj_42 = {};
        obj_42["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_42[
          gatewaydetails.data_42_name +
            " " +
            gatewaydetails.data_42_unit.data_unit
        ] = Number(data.data_42 * gatewaydetails.data_42_multiplier).toFixed(2);

        let obj_43 = {};
        obj_43["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_43[
          gatewaydetails.data_43_name +
            " " +
            gatewaydetails.data_43_unit.data_unit
        ] = Number(data.data_43 * gatewaydetails.data_43_multiplier).toFixed(2);

        let obj_44 = {};
        obj_44["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_44[
          gatewaydetails.data_44_name +
            " " +
            gatewaydetails.data_44_unit.data_unit
        ] = Number(data.data_44 * gatewaydetails.data_44_multiplier).toFixed(2);

        let obj_45 = {};
        obj_45["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_45[
          gatewaydetails.data_45_name +
            " " +
            gatewaydetails.data_45_unit.data_unit
        ] = Number(data.data_45 * gatewaydetails.data_45_multiplier).toFixed(2);

        let obj_46 = {};
        obj_46["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_46[
          gatewaydetails.data_46_name +
            " " +
            gatewaydetails.data_46_unit.data_unit
        ] = Number(data.data_46 * gatewaydetails.data_46_multiplier).toFixed(2);

        let obj_47 = {};
        obj_47["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_47[
          gatewaydetails.data_47_name +
            " " +
            gatewaydetails.data_47_unit.data_unit
        ] = Number(data.data_47 * gatewaydetails.data_47_multiplier).toFixed(2);

        let obj_48 = {};
        obj_48["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_48[
          gatewaydetails.data_48_name +
            " " +
            gatewaydetails.data_48_unit.data_unit
        ] = Number(data.data_48 * gatewaydetails.data_48_multiplier).toFixed(2);

        let obj_49 = {};
        obj_49["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_49[
          gatewaydetails.data_49_name +
            " " +
            gatewaydetails.data_49_unit.data_unit
        ] = Number(data.data_49 * gatewaydetails.data_49_multiplier).toFixed(2);

        let obj_50 = {};
        obj_50["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_50[
          gatewaydetails.data_50_name +
            " " +
            gatewaydetails.data_50_unit.data_unit
        ] = Number(data.data_50 * gatewaydetails.data_50_multiplier).toFixed(2);

        let obj_51 = {};
        obj_51["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_51[
          gatewaydetails.data_51_name +
            " " +
            gatewaydetails.data_51_unit.data_unit
        ] = Number(data.data_51 * gatewaydetails.data_51_multiplier).toFixed(2);

        let obj_52 = {};
        obj_52["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_52[
          gatewaydetails.data_52_name +
            " " +
            gatewaydetails.data_52_unit.data_unit
        ] = Number(data.data_52 * gatewaydetails.data_52_multiplier).toFixed(2);

        let obj_53 = {};
        obj_53["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_53[
          gatewaydetails.data_53_name +
            " " +
            gatewaydetails.data_53_unit.data_unit
        ] = Number(data.data_53 * gatewaydetails.data_53_multiplier).toFixed(2);

        let obj_54 = {};
        obj_1["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_54[
          gatewaydetails.data_54_name +
            " " +
            gatewaydetails.data_54_unit.data_unit
        ] = Number(data.data_54 * gatewaydetails.data_54_multiplier).toFixed(2);

        let obj_55 = {};
        obj_55["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_55[
          gatewaydetails.data_55_name +
            " " +
            gatewaydetails.data_55_unit.data_unit
        ] = Number(data.data_55 * gatewaydetails.data_55_multiplier).toFixed(2);

        let obj_56 = {};
        obj_56["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_56[
          gatewaydetails.data_56_name +
            " " +
            gatewaydetails.data_56_unit.data_unit
        ] = Number(data.data_56 * gatewaydetails.data_56_multiplier).toFixed(2);

        let obj_57 = {};
        obj_57["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_57[
          gatewaydetails.data_57_name +
            " " +
            gatewaydetails.data_57_unit.data_unit
        ] = Number(data.data_57 * gatewaydetails.data_57_multiplier).toFixed(2);

        let obj_58 = {};
        obj_58["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_58[
          gatewaydetails.data_58_name +
            " " +
            gatewaydetails.data_58_unit.data_unit
        ] = Number(data.data_58 * gatewaydetails.data_58_multiplier).toFixed(2);

        let obj_59 = {};
        obj_59["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_59[
          gatewaydetails.data_59_name +
            " " +
            gatewaydetails.data_59_unit.data_unit
        ] = Number(data.data_59 * gatewaydetails.data_59_multiplier).toFixed(2);

        let obj_60 = {};
        obj_60["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_60[
          gatewaydetails.data_60_name +
            " " +
            gatewaydetails.data_60_unit.data_unit
        ] = Number(data.data_60 * gatewaydetails.data_60_multiplier).toFixed(2);

        let obj_61 = {};
        obj_61["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_61[
          gatewaydetails.data_61_name +
            " " +
            gatewaydetails.data_61_unit.data_unit
        ] = Number(data.data_61 * gatewaydetails.data_61_multiplier).toFixed(2);

        let obj_62 = {};
        obj_62["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_62[
          gatewaydetails.data_62_name +
            " " +
            gatewaydetails.data_62_unit.data_unit
        ] = Number(data.data_62 * gatewaydetails.data_62_multiplier).toFixed(2);

        let obj_63 = {};
        obj_63["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_63[
          gatewaydetails.data_63_name +
            " " +
            gatewaydetails.data_63_unit.data_unit
        ] = Number(data.data_63 * gatewaydetails.data_63_multiplier).toFixed(2);

        let obj_64 = {};
        obj_64["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_64[
          gatewaydetails.data_64_name +
            " " +
            gatewaydetails.data_64_unit.data_unit
        ] = Number(data.data_64 * gatewaydetails.data_64_multiplier).toFixed(2);

        let obj_65 = {};
        obj_65["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_65[
          gatewaydetails.data_65_name +
            " " +
            gatewaydetails.data_65_unit.data_unit
        ] = Number(data.data_65 * gatewaydetails.data_65_multiplier).toFixed(2);

        let obj_66 = {};
        obj_66["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_66[
          gatewaydetails.data_66_name +
            " " +
            gatewaydetails.data_66_unit.data_unit
        ] = Number(data.data_66 * gatewaydetails.data_66_multiplier).toFixed(2);

        let obj_67 = {};
        obj_67["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_67[
          gatewaydetails.data_67_name +
            " " +
            gatewaydetails.data_67_unit.data_unit
        ] = Number(data.data_67 * gatewaydetails.data_67_multiplier).toFixed(2);

        let obj_68 = {};
        obj_68["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_68[
          gatewaydetails.data_68_name +
            " " +
            gatewaydetails.data_68_unit.data_unit
        ] = Number(data.data_68 * gatewaydetails.data_68_multiplier).toFixed(2);

        let obj_69 = {};
        obj_69["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 169);
        obj_69[
          gatewaydetails.data_69_name +
            " " +
            gatewaydetails.data_69_unit.data_unit
        ] = Number(data.data_69 * gatewaydetails.data_69_multiplier).toFixed(2);

        let obj_70 = {};
        obj_70["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_70[
          gatewaydetails.data_70_name +
            " " +
            gatewaydetails.data_70_unit.data_unit
        ] = Number(data.data_70 * gatewaydetails.data_70_multiplier).toFixed(2);

        let obj_71 = {};
        obj_71["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_71[
          gatewaydetails.data_71_name +
            " " +
            gatewaydetails.data_71_unit.data_unit
        ] = Number(data.data_71 * gatewaydetails.data_71_multiplier).toFixed(2);

        let obj_72 = {};
        obj_72["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_72[
          gatewaydetails.data_72_name +
            " " +
            gatewaydetails.data_72_unit.data_unit
        ] = Number(data.data_72 * gatewaydetails.data_72_multiplier).toFixed(2);

        let obj_73 = {};
        obj_73["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_73[
          gatewaydetails.data_73_name +
            " " +
            gatewaydetails.data_73_unit.data_unit
        ] = Number(data.data_73 * gatewaydetails.data_73_multiplier).toFixed(2);

        let obj_74 = {};
        obj_1["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_74[
          gatewaydetails.data_74_name +
            " " +
            gatewaydetails.data_74_unit.data_unit
        ] = Number(data.data_74 * gatewaydetails.data_74_multiplier).toFixed(2);

        let obj_75 = {};
        obj_75["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_75[
          gatewaydetails.data_75_name +
            " " +
            gatewaydetails.data_75_unit.data_unit
        ] = Number(data.data_75 * gatewaydetails.data_75_multiplier).toFixed(2);

        let obj_76 = {};
        obj_76["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_76[
          gatewaydetails.data_76_name +
            " " +
            gatewaydetails.data_76_unit.data_unit
        ] = Number(data.data_76 * gatewaydetails.data_76_multiplier).toFixed(2);

        let obj_77 = {};
        obj_77["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_77[
          gatewaydetails.data_77_name +
            " " +
            gatewaydetails.data_77_unit.data_unit
        ] = Number(data.data_77 * gatewaydetails.data_77_multiplier).toFixed(2);

        let obj_78 = {};
        obj_78["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_78[
          gatewaydetails.data_78_name +
            " " +
            gatewaydetails.data_78_unit.data_unit
        ] = Number(data.data_78 * gatewaydetails.data_78_multiplier).toFixed(2);

        let obj_79 = {};
        obj_79["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_79[
          gatewaydetails.data_79_name +
            " " +
            gatewaydetails.data_79_unit.data_unit
        ] = Number(data.data_79 * gatewaydetails.data_79_multiplier).toFixed(2);

        let obj_80 = {};
        obj_80["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_80[
          gatewaydetails.data_80_name +
            " " +
            gatewaydetails.data_80_unit.data_unit
        ] = Number(data.data_80 * gatewaydetails.data_80_multiplier).toFixed(2);

        let obj_81 = {};
        obj_81["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_81[
          gatewaydetails.data_81_name +
            " " +
            gatewaydetails.data_81_unit.data_unit
        ] = Number(data.data_81 * gatewaydetails.data_81_multiplier).toFixed(2);

        let obj_82 = {};
        obj_82["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_82[
          gatewaydetails.data_82_name +
            " " +
            gatewaydetails.data_82_unit.data_unit
        ] = Number(data.data_82 * gatewaydetails.data_82_multiplier).toFixed(2);

        let obj_83 = {};
        obj_83["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_83[
          gatewaydetails.data_83_name +
            " " +
            gatewaydetails.data_83_unit.data_unit
        ] = Number(data.data_83 * gatewaydetails.data_83_multiplier).toFixed(2);

        let obj_84 = {};
        obj_84["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_84[
          gatewaydetails.data_84_name +
            " " +
            gatewaydetails.data_84_unit.data_unit
        ] = Number(data.data_84 * gatewaydetails.data_84_multiplier).toFixed(2);

        let obj_85 = {};
        obj_85["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_85[
          gatewaydetails.data_85_name +
            " " +
            gatewaydetails.data_85_unit.data_unit
        ] = Number(data.data_85 * gatewaydetails.data_85_multiplier).toFixed(2);

        let obj_86 = {};
        obj_86["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_86[
          gatewaydetails.data_86_name +
            " " +
            gatewaydetails.data_86_unit.data_unit
        ] = Number(data.data_86 * gatewaydetails.data_86_multiplier).toFixed(2);

        let obj_87 = {};
        obj_87["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_87[
          gatewaydetails.data_87_name +
            " " +
            gatewaydetails.data_87_unit.data_unit
        ] = Number(data.data_87 * gatewaydetails.data_87_multiplier).toFixed(2);

        let obj_88 = {};
        obj_88["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_88[
          gatewaydetails.data_88_name +
            " " +
            gatewaydetails.data_88_unit.data_unit
        ] = Number(data.data_88 * gatewaydetails.data_88_multiplier).toFixed(2);

        let obj_89 = {};
        obj_89["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_89[
          gatewaydetails.data_89_name +
            " " +
            gatewaydetails.data_89_unit.data_unit
        ] = Number(data.data_89 * gatewaydetails.data_89_multiplier).toFixed(2);

        let obj_90 = {};
        obj_90["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_90[
          gatewaydetails.data_90_name +
            " " +
            gatewaydetails.data_90_unit.data_unit
        ] = Number(data.data_90 * gatewaydetails.data_90_multiplier).toFixed(2);

        let obj_91 = {};
        obj_91["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_91[
          gatewaydetails.data_91_name +
            " " +
            gatewaydetails.data_91_unit.data_unit
        ] = Number(data.data_91 * gatewaydetails.data_91_multiplier).toFixed(2);

        let obj_92 = {};
        obj_92["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_92[
          gatewaydetails.data_92_name +
            " " +
            gatewaydetails.data_92_unit.data_unit
        ] = Number(data.data_92 * gatewaydetails.data_92_multiplier).toFixed(2);

        let obj_93 = {};
        obj_93["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_93[
          gatewaydetails.data_93_name +
            " " +
            gatewaydetails.data_93_unit.data_unit
        ] = Number(data.data_93 * gatewaydetails.data_93_multiplier).toFixed(2);

        let obj_94 = {};
        obj_1["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_94[
          gatewaydetails.data_94_name +
            " " +
            gatewaydetails.data_94_unit.data_unit
        ] = Number(data.data_94 * gatewaydetails.data_94_multiplier).toFixed(2);

        let obj_95 = {};
        obj_95["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_95[
          gatewaydetails.data_95_name +
            " " +
            gatewaydetails.data_95_unit.data_unit
        ] = Number(data.data_95 * gatewaydetails.data_95_multiplier).toFixed(2);

        let obj_96 = {};
        obj_96["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_96[
          gatewaydetails.data_96_name +
            " " +
            gatewaydetails.data_96_unit.data_unit
        ] = Number(data.data_96 * gatewaydetails.data_96_multiplier).toFixed(2);

        let obj_97 = {};
        obj_97["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_97[
          gatewaydetails.data_97_name +
            " " +
            gatewaydetails.data_97_unit.data_unit
        ] = Number(data.data_97 * gatewaydetails.data_97_multiplier).toFixed(2);

        let obj_98 = {};
        obj_98["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_98[
          gatewaydetails.data_98_name +
            " " +
            gatewaydetails.data_98_unit.data_unit
        ] = Number(data.data_98 * gatewaydetails.data_98_multiplier).toFixed(2);

        let obj_99 = {};
        obj_99["name"] = changeDatetimeByTimezone(
          data.posted,
          timezone.value
        ).substring(11, 19);
        obj_99[
          gatewaydetails.data_99_name +
            " " +
            gatewaydetails.data_99_unit.data_unit
        ] = Number(data.data_99 * gatewaydetails.data_99_multiplier).toFixed(2);

        data_0.push(obj_0);
        data_1.push(obj_1);
        data_2.push(obj_2);
        data_3.push(obj_3);
        data_4.push(obj_4);
        data_5.push(obj_5);
        data_6.push(obj_6);
        data_7.push(obj_7);
        data_8.push(obj_8);
        data_9.push(obj_9);
        data_10.push(obj_10);
        data_11.push(obj_11);
        data_12.push(obj_12);
        data_13.push(obj_13);
        data_14.push(obj_14);
        data_15.push(obj_15);
        data_16.push(obj_16);
        data_17.push(obj_17);
        data_18.push(obj_18);
        data_19.push(obj_19);
        data_20.push(obj_20);
        data_21.push(obj_21);
        data_22.push(obj_22);
        data_23.push(obj_23);
        data_24.push(obj_24);
        data_25.push(obj_25);
        data_26.push(obj_26);
        data_27.push(obj_27);
        data_28.push(obj_28);
        data_29.push(obj_29);
        data_30.push(obj_30);
        data_31.push(obj_31);
        data_32.push(obj_32);
        data_33.push(obj_33);
        data_34.push(obj_34);
        data_35.push(obj_35);
        data_36.push(obj_36);
        data_37.push(obj_37);
        data_38.push(obj_38);
        data_39.push(obj_39);
        data_40.push(obj_40);
        data_41.push(obj_41);
        data_42.push(obj_42);
        data_43.push(obj_43);
        data_44.push(obj_44);
        data_45.push(obj_45);
        data_46.push(obj_46);
        data_47.push(obj_47);
        data_48.push(obj_48);
        data_49.push(obj_49);
        data_50.push(obj_50);
        data_51.push(obj_51);
        data_52.push(obj_52);
        data_53.push(obj_53);
        data_54.push(obj_54);
        data_55.push(obj_55);
        data_56.push(obj_56);
        data_57.push(obj_57);
        data_58.push(obj_58);
        data_59.push(obj_59);
        data_60.push(obj_60);
        data_61.push(obj_61);
        data_62.push(obj_62);
        data_63.push(obj_63);
        data_64.push(obj_64);
        data_65.push(obj_65);
        data_66.push(obj_66);
        data_67.push(obj_67);
        data_68.push(obj_68);
        data_69.push(obj_69);
        data_70.push(obj_70);
        data_71.push(obj_71);
        data_72.push(obj_72);
        data_73.push(obj_73);
        data_74.push(obj_74);
        data_75.push(obj_75);
        data_76.push(obj_76);
        data_77.push(obj_77);
        data_78.push(obj_78);
        data_79.push(obj_79);
        data_80.push(obj_80);
        data_81.push(obj_81);
        data_82.push(obj_82);
        data_83.push(obj_83);
        data_84.push(obj_84);
        data_85.push(obj_85);
        data_86.push(obj_86);
        data_87.push(obj_87);
        data_88.push(obj_88);
        data_89.push(obj_89);
        data_90.push(obj_90);
        data_91.push(obj_91);
        data_92.push(obj_92);
        data_93.push(obj_93);
        data_94.push(obj_94);
        data_95.push(obj_95);
        data_96.push(obj_96);
        data_97.push(obj_97);
        data_98.push(obj_98);
        data_99.push(obj_99);
      });
      setData_0(data_0.reverse());
      setData_1(data_1.reverse());
      setData_2(data_2.reverse());
      setData_3(data_3.reverse());
      setData_4(data_4.reverse());
      setData_5(data_5.reverse());
      setData_6(data_6.reverse());
      setData_7(data_7.reverse());
      setData_8(data_8.reverse());
      setData_9(data_9.reverse());
      setData_10(data_10.reverse());
      setData_11(data_11.reverse());
      setData_12(data_12.reverse());
      setData_13(data_13.reverse());
      setData_14(data_14.reverse());
      setData_15(data_15.reverse());
      setData_16(data_16.reverse());
      setData_17(data_17.reverse());
      setData_18(data_18.reverse());
      setData_19(data_19.reverse());
      setData_20(data_20.reverse());
      setData_21(data_21.reverse());
      setData_22(data_22.reverse());
      setData_23(data_23.reverse());
      setData_24(data_24.reverse());
      setData_25(data_25.reverse());
      setData_26(data_26.reverse());
      setData_27(data_27.reverse());
      setData_28(data_28.reverse());
      setData_29(data_29.reverse());
      setData_30(data_30.reverse());
      setData_31(data_31.reverse());
      setData_32(data_32.reverse());
      setData_33(data_33.reverse());
      setData_34(data_34.reverse());
      setData_35(data_35.reverse());
      setData_36(data_36.reverse());
      setData_37(data_37.reverse());
      setData_38(data_38.reverse());
      setData_39(data_39.reverse());
      setData_40(data_40.reverse());
      setData_41(data_41.reverse());
      setData_42(data_42.reverse());
      setData_43(data_43.reverse());
      setData_44(data_44.reverse());
      setData_45(data_45.reverse());
      setData_46(data_46.reverse());
      setData_47(data_47.reverse());
      setData_48(data_48.reverse());
      setData_49(data_49.reverse());
      setData_50(data_50.reverse());
      setData_51(data_51.reverse());
      setData_52(data_52.reverse());
      setData_53(data_53.reverse());
      setData_54(data_54.reverse());
      setData_55(data_55.reverse());
      setData_56(data_56.reverse());
      setData_57(data_57.reverse());
      setData_58(data_58.reverse());
      setData_59(data_59.reverse());
      setData_60(data_60.reverse());
      setData_61(data_61.reverse());
      setData_62(data_62.reverse());
      setData_63(data_63.reverse());
      setData_64(data_64.reverse());
      setData_65(data_65.reverse());
      setData_66(data_66.reverse());
      setData_67(data_67.reverse());
      setData_68(data_68.reverse());
      setData_69(data_69.reverse());
      setData_70(data_70.reverse());
      setData_71(data_71.reverse());
      setData_72(data_72.reverse());
      setData_73(data_73.reverse());
      setData_74(data_74.reverse());
      setData_75(data_75.reverse());
      setData_76(data_76.reverse());
      setData_77(data_77.reverse());
      setData_78(data_78.reverse());
      setData_79(data_79.reverse());
      setData_80(data_80.reverse());
      setData_81(data_81.reverse());
      setData_82(data_82.reverse());
      setData_83(data_83.reverse());
      setData_84(data_84.reverse());
      setData_85(data_85.reverse());
      setData_86(data_86.reverse());
      setData_87(data_87.reverse());
      setData_88(data_88.reverse());
      setData_89(data_89.reverse());
      setData_90(data_90.reverse());
      setData_91(data_91.reverse());
      setData_92(data_92.reverse());
      setData_93(data_93.reverse());
      setData_94(data_94.reverse());
      setData_95(data_95.reverse());
      setData_96(data_96.reverse());
      setData_97(data_97.reverse());
      setData_98(data_98.reverse());
      setData_99(data_99.reverse());
      setmax_0(Math.max(...data_0.map((o) => o[Object.keys(data_0[0])[1]])));
      setmax_1(Math.max(...data_1.map((o) => o[Object.keys(data_1[0])[1]])));
      setmax_2(Math.max(...data_2.map((o) => o[Object.keys(data_2[0])[1]])));
      setmax_3(Math.max(...data_3.map((o) => o[Object.keys(data_3[0])[1]])));
      setmax_4(Math.max(...data_4.map((o) => o[Object.keys(data_4[0])[1]])));
      setmax_5(Math.max(...data_5.map((o) => o[Object.keys(data_5[0])[1]])));
      setmax_6(Math.max(...data_6.map((o) => o[Object.keys(data_6[0])[1]])));
      setmax_7(Math.max(...data_7.map((o) => o[Object.keys(data_7[0])[1]])));
      setmax_8(Math.max(...data_8.map((o) => o[Object.keys(data_8[0])[1]])));
      setmax_9(Math.max(...data_9.map((o) => o[Object.keys(data_9[0])[1]])));
      setmax_10(Math.max(...data_10.map((o) => o[Object.keys(data_10[0])[1]])));
      setmax_11(Math.max(...data_11.map((o) => o[Object.keys(data_11[0])[1]])));
      setmax_12(Math.max(...data_12.map((o) => o[Object.keys(data_12[0])[1]])));
      setmax_13(Math.max(...data_13.map((o) => o[Object.keys(data_13[0])[1]])));
      setmax_14(Math.max(...data_14.map((o) => o[Object.keys(data_14[0])[1]])));
      setmax_15(Math.max(...data_15.map((o) => o[Object.keys(data_15[0])[1]])));
      setmax_16(Math.max(...data_16.map((o) => o[Object.keys(data_16[0])[1]])));
      setmax_17(Math.max(...data_17.map((o) => o[Object.keys(data_17[0])[1]])));
      setmax_18(Math.max(...data_18.map((o) => o[Object.keys(data_18[0])[1]])));
      setmax_19(Math.max(...data_19.map((o) => o[Object.keys(data_19[0])[1]])));
      setmax_20(Math.max(...data_20.map((o) => o[Object.keys(data_20[0])[1]])));
      setmax_21(Math.max(...data_21.map((o) => o[Object.keys(data_21[0])[1]])));
      setmax_22(Math.max(...data_22.map((o) => o[Object.keys(data_22[0])[1]])));
      setmax_23(Math.max(...data_23.map((o) => o[Object.keys(data_23[0])[1]])));
      setmax_24(Math.max(...data_24.map((o) => o[Object.keys(data_24[0])[1]])));
      setmax_25(Math.max(...data_25.map((o) => o[Object.keys(data_25[0])[1]])));
      setmax_26(Math.max(...data_26.map((o) => o[Object.keys(data_26[0])[1]])));
      setmax_27(Math.max(...data_27.map((o) => o[Object.keys(data_27[0])[1]])));
      setmax_28(Math.max(...data_28.map((o) => o[Object.keys(data_28[0])[1]])));
      setmax_29(Math.max(...data_29.map((o) => o[Object.keys(data_29[0])[1]])));
      setmax_30(Math.max(...data_30.map((o) => o[Object.keys(data_30[0])[1]])));
      setmax_31(Math.max(...data_31.map((o) => o[Object.keys(data_31[0])[1]])));
      setmax_32(Math.max(...data_32.map((o) => o[Object.keys(data_32[0])[1]])));
      setmax_33(Math.max(...data_33.map((o) => o[Object.keys(data_33[0])[1]])));
      setmax_34(Math.max(...data_34.map((o) => o[Object.keys(data_34[0])[1]])));
      setmax_35(Math.max(...data_35.map((o) => o[Object.keys(data_35[0])[1]])));
      setmax_36(Math.max(...data_36.map((o) => o[Object.keys(data_36[0])[1]])));
      setmax_37(Math.max(...data_37.map((o) => o[Object.keys(data_37[0])[1]])));
      setmax_38(Math.max(...data_38.map((o) => o[Object.keys(data_38[0])[1]])));
      setmax_39(Math.max(...data_39.map((o) => o[Object.keys(data_39[0])[1]])));
      setmax_40(Math.max(...data_40.map((o) => o[Object.keys(data_40[0])[1]])));
      setmax_41(Math.max(...data_41.map((o) => o[Object.keys(data_41[0])[1]])));
      setmax_42(Math.max(...data_42.map((o) => o[Object.keys(data_42[0])[1]])));
      setmax_43(Math.max(...data_43.map((o) => o[Object.keys(data_43[0])[1]])));
      setmax_44(Math.max(...data_44.map((o) => o[Object.keys(data_44[0])[1]])));
      setmax_45(Math.max(...data_45.map((o) => o[Object.keys(data_45[0])[1]])));
      setmax_46(Math.max(...data_46.map((o) => o[Object.keys(data_46[0])[1]])));
      setmax_47(Math.max(...data_47.map((o) => o[Object.keys(data_47[0])[1]])));
      setmax_48(Math.max(...data_48.map((o) => o[Object.keys(data_48[0])[1]])));
      setmax_49(Math.max(...data_49.map((o) => o[Object.keys(data_49[0])[1]])));
      setmax_50(Math.max(...data_50.map((o) => o[Object.keys(data_50[0])[1]])));
      setmax_51(Math.max(...data_51.map((o) => o[Object.keys(data_51[0])[1]])));
      setmax_52(Math.max(...data_52.map((o) => o[Object.keys(data_52[0])[1]])));
      setmax_53(Math.max(...data_53.map((o) => o[Object.keys(data_53[0])[1]])));
      setmax_54(Math.max(...data_54.map((o) => o[Object.keys(data_54[0])[1]])));
      setmax_55(Math.max(...data_55.map((o) => o[Object.keys(data_55[0])[1]])));
      setmax_56(Math.max(...data_56.map((o) => o[Object.keys(data_56[0])[1]])));
      setmax_57(Math.max(...data_57.map((o) => o[Object.keys(data_57[0])[1]])));
      setmax_58(Math.max(...data_58.map((o) => o[Object.keys(data_58[0])[1]])));
      setmax_59(Math.max(...data_59.map((o) => o[Object.keys(data_59[0])[1]])));
      setmax_60(Math.max(...data_60.map((o) => o[Object.keys(data_60[0])[1]])));
      setmax_61(Math.max(...data_61.map((o) => o[Object.keys(data_61[0])[1]])));
      setmax_62(Math.max(...data_62.map((o) => o[Object.keys(data_62[0])[1]])));
      setmax_63(Math.max(...data_63.map((o) => o[Object.keys(data_63[0])[1]])));
      setmax_64(Math.max(...data_64.map((o) => o[Object.keys(data_64[0])[1]])));
      setmax_65(Math.max(...data_65.map((o) => o[Object.keys(data_65[0])[1]])));
      setmax_66(Math.max(...data_66.map((o) => o[Object.keys(data_66[0])[1]])));
      setmax_67(Math.max(...data_67.map((o) => o[Object.keys(data_67[0])[1]])));
      setmax_68(Math.max(...data_68.map((o) => o[Object.keys(data_68[0])[1]])));
      setmax_69(Math.max(...data_69.map((o) => o[Object.keys(data_69[0])[1]])));
      setmax_70(Math.max(...data_70.map((o) => o[Object.keys(data_70[0])[1]])));
      setmax_71(Math.max(...data_71.map((o) => o[Object.keys(data_71[0])[1]])));
      setmax_72(Math.max(...data_72.map((o) => o[Object.keys(data_72[0])[1]])));
      setmax_73(Math.max(...data_73.map((o) => o[Object.keys(data_73[0])[1]])));
      setmax_74(Math.max(...data_74.map((o) => o[Object.keys(data_74[0])[1]])));
      setmax_75(Math.max(...data_75.map((o) => o[Object.keys(data_75[0])[1]])));
      setmax_76(Math.max(...data_76.map((o) => o[Object.keys(data_76[0])[1]])));
      setmax_77(Math.max(...data_77.map((o) => o[Object.keys(data_77[0])[1]])));
      setmax_78(Math.max(...data_78.map((o) => o[Object.keys(data_78[0])[1]])));
      setmax_79(Math.max(...data_79.map((o) => o[Object.keys(data_79[0])[1]])));
      setmax_80(Math.max(...data_80.map((o) => o[Object.keys(data_80[0])[1]])));
      setmax_81(Math.max(...data_81.map((o) => o[Object.keys(data_81[0])[1]])));
      setmax_82(Math.max(...data_82.map((o) => o[Object.keys(data_82[0])[1]])));
      setmax_83(Math.max(...data_83.map((o) => o[Object.keys(data_83[0])[1]])));
      setmax_84(Math.max(...data_84.map((o) => o[Object.keys(data_84[0])[1]])));
      setmax_85(Math.max(...data_85.map((o) => o[Object.keys(data_85[0])[1]])));
      setmax_86(Math.max(...data_86.map((o) => o[Object.keys(data_86[0])[1]])));
      setmax_87(Math.max(...data_87.map((o) => o[Object.keys(data_87[0])[1]])));
      setmax_88(Math.max(...data_88.map((o) => o[Object.keys(data_88[0])[1]])));
      setmax_89(Math.max(...data_89.map((o) => o[Object.keys(data_89[0])[1]])));
      setmax_90(Math.max(...data_90.map((o) => o[Object.keys(data_90[0])[1]])));
      setmax_91(Math.max(...data_91.map((o) => o[Object.keys(data_91[0])[1]])));
      setmax_92(Math.max(...data_92.map((o) => o[Object.keys(data_92[0])[1]])));
      setmax_93(Math.max(...data_93.map((o) => o[Object.keys(data_93[0])[1]])));
      setmax_94(Math.max(...data_94.map((o) => o[Object.keys(data_94[0])[1]])));
      setmax_95(Math.max(...data_95.map((o) => o[Object.keys(data_95[0])[1]])));
      setmax_96(Math.max(...data_96.map((o) => o[Object.keys(data_96[0])[1]])));
      setmax_97(Math.max(...data_97.map((o) => o[Object.keys(data_97[0])[1]])));
      setmax_98(Math.max(...data_98.map((o) => o[Object.keys(data_98[0])[1]])));
      setmax_99(Math.max(...data_99.map((o) => o[Object.keys(data_99[0])[1]])));

      setmin_0(Math.min(...data_0.map((o) => o[Object.keys(data_0[0])[1]])));
      setmin_1(Math.min(...data_1.map((o) => o[Object.keys(data_1[0])[1]])));
      setmin_2(Math.min(...data_2.map((o) => o[Object.keys(data_2[0])[1]])));
      setmin_3(Math.min(...data_3.map((o) => o[Object.keys(data_3[0])[1]])));
      setmin_4(Math.min(...data_4.map((o) => o[Object.keys(data_4[0])[1]])));
      setmin_5(Math.min(...data_5.map((o) => o[Object.keys(data_5[0])[1]])));
      setmin_6(Math.min(...data_6.map((o) => o[Object.keys(data_6[0])[1]])));
      setmin_7(Math.min(...data_7.map((o) => o[Object.keys(data_7[0])[1]])));
      setmin_8(Math.min(...data_8.map((o) => o[Object.keys(data_8[0])[1]])));
      setmin_9(Math.min(...data_9.map((o) => o[Object.keys(data_9[0])[1]])));
      setmin_10(Math.min(...data_10.map((o) => o[Object.keys(data_10[0])[1]])));
      setmin_11(Math.min(...data_11.map((o) => o[Object.keys(data_11[0])[1]])));
      setmin_12(Math.min(...data_12.map((o) => o[Object.keys(data_12[0])[1]])));
      setmin_13(Math.min(...data_13.map((o) => o[Object.keys(data_13[0])[1]])));
      setmin_14(Math.min(...data_14.map((o) => o[Object.keys(data_14[0])[1]])));
      setmin_15(Math.min(...data_15.map((o) => o[Object.keys(data_15[0])[1]])));
      setmin_16(Math.min(...data_16.map((o) => o[Object.keys(data_16[0])[1]])));
      setmin_17(Math.min(...data_17.map((o) => o[Object.keys(data_17[0])[1]])));
      setmin_18(Math.min(...data_18.map((o) => o[Object.keys(data_18[0])[1]])));
      setmin_19(Math.min(...data_19.map((o) => o[Object.keys(data_19[0])[1]])));
      setmin_20(Math.min(...data_20.map((o) => o[Object.keys(data_20[0])[1]])));
      setmin_21(Math.min(...data_21.map((o) => o[Object.keys(data_21[0])[1]])));
      setmin_22(Math.min(...data_22.map((o) => o[Object.keys(data_22[0])[1]])));
      setmin_23(Math.min(...data_23.map((o) => o[Object.keys(data_23[0])[1]])));
      setmin_24(Math.min(...data_24.map((o) => o[Object.keys(data_24[0])[1]])));
      setmin_25(Math.min(...data_25.map((o) => o[Object.keys(data_25[0])[1]])));
      setmin_26(Math.min(...data_26.map((o) => o[Object.keys(data_26[0])[1]])));
      setmin_27(Math.min(...data_27.map((o) => o[Object.keys(data_27[0])[1]])));
      setmin_28(Math.min(...data_28.map((o) => o[Object.keys(data_28[0])[1]])));
      setmin_29(Math.min(...data_29.map((o) => o[Object.keys(data_29[0])[1]])));
      setmin_30(Math.min(...data_30.map((o) => o[Object.keys(data_30[0])[1]])));
      setmin_31(Math.min(...data_31.map((o) => o[Object.keys(data_31[0])[1]])));
      setmin_32(Math.min(...data_32.map((o) => o[Object.keys(data_32[0])[1]])));
      setmin_33(Math.min(...data_33.map((o) => o[Object.keys(data_33[0])[1]])));
      setmin_34(Math.min(...data_34.map((o) => o[Object.keys(data_34[0])[1]])));
      setmin_35(Math.min(...data_35.map((o) => o[Object.keys(data_35[0])[1]])));
      setmin_36(Math.min(...data_36.map((o) => o[Object.keys(data_36[0])[1]])));
      setmin_37(Math.min(...data_37.map((o) => o[Object.keys(data_37[0])[1]])));
      setmin_38(Math.min(...data_38.map((o) => o[Object.keys(data_38[0])[1]])));
      setmin_39(Math.min(...data_39.map((o) => o[Object.keys(data_39[0])[1]])));
      setmin_40(Math.min(...data_40.map((o) => o[Object.keys(data_40[0])[1]])));
      setmin_41(Math.min(...data_41.map((o) => o[Object.keys(data_41[0])[1]])));
      setmin_42(Math.min(...data_42.map((o) => o[Object.keys(data_42[0])[1]])));
      setmin_43(Math.min(...data_43.map((o) => o[Object.keys(data_43[0])[1]])));
      setmin_44(Math.min(...data_44.map((o) => o[Object.keys(data_44[0])[1]])));
      setmin_45(Math.min(...data_45.map((o) => o[Object.keys(data_45[0])[1]])));
      setmin_46(Math.min(...data_46.map((o) => o[Object.keys(data_46[0])[1]])));
      setmin_47(Math.min(...data_47.map((o) => o[Object.keys(data_47[0])[1]])));
      setmin_48(Math.min(...data_48.map((o) => o[Object.keys(data_48[0])[1]])));
      setmin_49(Math.min(...data_49.map((o) => o[Object.keys(data_49[0])[1]])));
      setmin_50(Math.min(...data_50.map((o) => o[Object.keys(data_50[0])[1]])));
      setmin_51(Math.min(...data_51.map((o) => o[Object.keys(data_51[0])[1]])));
      setmin_52(Math.min(...data_52.map((o) => o[Object.keys(data_52[0])[1]])));
      setmin_53(Math.min(...data_53.map((o) => o[Object.keys(data_53[0])[1]])));
      setmin_54(Math.min(...data_54.map((o) => o[Object.keys(data_54[0])[1]])));
      setmin_55(Math.min(...data_55.map((o) => o[Object.keys(data_55[0])[1]])));
      setmin_56(Math.min(...data_56.map((o) => o[Object.keys(data_56[0])[1]])));
      setmin_57(Math.min(...data_57.map((o) => o[Object.keys(data_57[0])[1]])));
      setmin_58(Math.min(...data_58.map((o) => o[Object.keys(data_58[0])[1]])));
      setmin_59(Math.min(...data_59.map((o) => o[Object.keys(data_59[0])[1]])));
      setmin_60(Math.min(...data_60.map((o) => o[Object.keys(data_60[0])[1]])));
      setmin_61(Math.min(...data_61.map((o) => o[Object.keys(data_61[0])[1]])));
      setmin_62(Math.min(...data_62.map((o) => o[Object.keys(data_62[0])[1]])));
      setmin_63(Math.min(...data_63.map((o) => o[Object.keys(data_63[0])[1]])));
      setmin_64(Math.min(...data_64.map((o) => o[Object.keys(data_64[0])[1]])));
      setmin_65(Math.min(...data_65.map((o) => o[Object.keys(data_65[0])[1]])));
      setmin_66(Math.min(...data_66.map((o) => o[Object.keys(data_66[0])[1]])));
      setmin_67(Math.min(...data_67.map((o) => o[Object.keys(data_67[0])[1]])));
      setmin_68(Math.min(...data_68.map((o) => o[Object.keys(data_68[0])[1]])));
      setmin_69(Math.min(...data_69.map((o) => o[Object.keys(data_69[0])[1]])));
      setmin_70(Math.min(...data_70.map((o) => o[Object.keys(data_70[0])[1]])));
      setmin_71(Math.min(...data_71.map((o) => o[Object.keys(data_71[0])[1]])));
      setmin_72(Math.min(...data_72.map((o) => o[Object.keys(data_72[0])[1]])));
      setmin_73(Math.min(...data_73.map((o) => o[Object.keys(data_73[0])[1]])));
      setmin_74(Math.min(...data_74.map((o) => o[Object.keys(data_74[0])[1]])));
      setmin_75(Math.min(...data_75.map((o) => o[Object.keys(data_75[0])[1]])));
      setmin_76(Math.min(...data_76.map((o) => o[Object.keys(data_76[0])[1]])));
      setmin_77(Math.min(...data_77.map((o) => o[Object.keys(data_77[0])[1]])));
      setmin_78(Math.min(...data_78.map((o) => o[Object.keys(data_78[0])[1]])));
      setmin_79(Math.min(...data_79.map((o) => o[Object.keys(data_79[0])[1]])));
      setmin_80(Math.min(...data_80.map((o) => o[Object.keys(data_80[0])[1]])));
      setmin_81(Math.min(...data_81.map((o) => o[Object.keys(data_81[0])[1]])));
      setmin_82(Math.min(...data_82.map((o) => o[Object.keys(data_82[0])[1]])));
      setmin_83(Math.min(...data_83.map((o) => o[Object.keys(data_83[0])[1]])));
      setmin_84(Math.min(...data_84.map((o) => o[Object.keys(data_84[0])[1]])));
      setmin_85(Math.min(...data_85.map((o) => o[Object.keys(data_85[0])[1]])));
      setmin_86(Math.min(...data_86.map((o) => o[Object.keys(data_86[0])[1]])));
      setmin_87(Math.min(...data_87.map((o) => o[Object.keys(data_87[0])[1]])));
      setmin_88(Math.min(...data_88.map((o) => o[Object.keys(data_88[0])[1]])));
      setmin_89(Math.min(...data_89.map((o) => o[Object.keys(data_89[0])[1]])));
      setmin_90(Math.min(...data_90.map((o) => o[Object.keys(data_90[0])[1]])));
      setmin_91(Math.min(...data_91.map((o) => o[Object.keys(data_91[0])[1]])));
      setmin_92(Math.min(...data_92.map((o) => o[Object.keys(data_92[0])[1]])));
      setmin_93(Math.min(...data_93.map((o) => o[Object.keys(data_93[0])[1]])));
      setmin_94(Math.min(...data_94.map((o) => o[Object.keys(data_94[0])[1]])));
      setmin_95(Math.min(...data_95.map((o) => o[Object.keys(data_95[0])[1]])));
      setmin_96(Math.min(...data_96.map((o) => o[Object.keys(data_96[0])[1]])));
      setmin_97(Math.min(...data_97.map((o) => o[Object.keys(data_97[0])[1]])));
      setmin_98(Math.min(...data_98.map((o) => o[Object.keys(data_98[0])[1]])));
      setmin_99(Math.min(...data_99.map((o) => o[Object.keys(data_99[0])[1]])));
    }
  }, [updateSuccess, gatewayupdates, gatewaydetails, timezone]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        success && (
          <>
            <h1>
              {gatewaydetails.name}

              <LinkContainer to={`/user/gateway/${gatewaydetails.id}/edit`}>
                <Button variant="transparent" style={{ color: "red" }}>
                  <i className="fas fa-pen-to-square"></i>
                </Button>
              </LinkContainer>
            </h1>
            <Row className="justify-content-md-center">
              <Carousel
                variant="dark"
                pause="hover"
                className="w-100 chartcarousel"
              >
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_0}
                      type={9}
                      data_name={
                        gatewaydetails.data_0_name +
                        " " +
                        gatewaydetails.data_0_unit.data_unit
                      }
                      data_min={min_0}
                      data_max={max_0}
                    ></Chart>
                    <Chart
                      data={data_1}
                      type={9}
                      data_name={
                        gatewaydetails.data_1_name +
                        " " +
                        gatewaydetails.data_1_unit.data_unit
                      }
                      data_min={min_1}
                      data_max={max_1}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_2}
                      type={9}
                      data_name={
                        gatewaydetails.data_2_name +
                        " " +
                        gatewaydetails.data_2_unit.data_unit
                      }
                      data_min={min_2}
                      data_max={max_2}
                    ></Chart>
                    <Chart
                      data={data_3}
                      type={9}
                      data_name={
                        gatewaydetails.data_3_name +
                        " " +
                        gatewaydetails.data_3_unit.data_unit
                      }
                      data_min={min_3}
                      data_max={max_3}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_4}
                      type={9}
                      data_name={
                        gatewaydetails.data_4_name +
                        " " +
                        gatewaydetails.data_4_unit.data_unit
                      }
                      data_min={min_4}
                      data_max={max_4}
                    ></Chart>
                    <Chart
                      data={data_5}
                      type={9}
                      data_name={
                        gatewaydetails.data_5_name +
                        " " +
                        gatewaydetails.data_5_unit.data_unit
                      }
                      data_min={min_5}
                      data_max={max_5}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_6}
                      type={9}
                      data_name={
                        gatewaydetails.data_6_name +
                        " " +
                        gatewaydetails.data_6_unit.data_unit
                      }
                      data_min={min_6}
                      data_max={max_6}
                    ></Chart>
                    <Chart
                      data={data_7}
                      type={9}
                      data_name={
                        gatewaydetails.data_7_name +
                        " " +
                        gatewaydetails.data_7_unit.data_unit
                      }
                      data_min={min_7}
                      data_max={max_7}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_8}
                      type={9}
                      data_name={
                        gatewaydetails.data_8_name +
                        " " +
                        gatewaydetails.data_8_unit.data_unit
                      }
                      data_min={min_8}
                      data_max={max_8}
                    ></Chart>
                    <Chart
                      data={data_9}
                      type={9}
                      data_name={
                        gatewaydetails.data_9_name +
                        " " +
                        gatewaydetails.data_9_unit.data_unit
                      }
                      data_min={min_9}
                      data_max={max_9}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_10}
                      type={9}
                      data_name={
                        gatewaydetails.data_10_name +
                        " " +
                        gatewaydetails.data_10_unit.data_unit
                      }
                      data_min={min_10}
                      data_max={max_10}
                    ></Chart>
                    <Chart
                      data={data_11}
                      type={9}
                      data_name={
                        gatewaydetails.data_11_name +
                        " " +
                        gatewaydetails.data_11_unit.data_unit
                      }
                      data_min={min_11}
                      data_max={max_11}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_12}
                      type={9}
                      data_name={
                        gatewaydetails.data_12_name +
                        " " +
                        gatewaydetails.data_12_unit.data_unit
                      }
                      data_min={min_12}
                      data_max={max_12}
                    ></Chart>
                    <Chart
                      data={data_13}
                      type={9}
                      data_name={
                        gatewaydetails.data_13_name +
                        " " +
                        gatewaydetails.data_13_unit.data_unit
                      }
                      data_min={min_13}
                      data_max={max_13}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_14}
                      type={9}
                      data_name={
                        gatewaydetails.data_14_name +
                        " " +
                        gatewaydetails.data_14_unit.data_unit
                      }
                      data_min={min_14}
                      data_max={max_14}
                    ></Chart>
                    <Chart
                      data={data_15}
                      type={9}
                      data_name={
                        gatewaydetails.data_15_name +
                        " " +
                        gatewaydetails.data_15_unit.data_unit
                      }
                      data_min={min_15}
                      data_max={max_15}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_16}
                      type={9}
                      data_name={
                        gatewaydetails.data_16_name +
                        " " +
                        gatewaydetails.data_16_unit.data_unit
                      }
                      data_min={min_16}
                      data_max={max_16}
                    ></Chart>
                    <Chart
                      data={data_17}
                      type={9}
                      data_name={
                        gatewaydetails.data_17_name +
                        " " +
                        gatewaydetails.data_17_unit.data_unit
                      }
                      data_min={min_17}
                      data_max={max_17}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_18}
                      type={9}
                      data_name={
                        gatewaydetails.data_18_name +
                        " " +
                        gatewaydetails.data_18_unit.data_unit
                      }
                      data_min={min_18}
                      data_max={max_18}
                    ></Chart>
                    <Chart
                      data={data_19}
                      type={9}
                      data_name={
                        gatewaydetails.data_19_name +
                        " " +
                        gatewaydetails.data_19_unit.data_unit
                      }
                      data_min={min_19}
                      data_max={max_19}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_20}
                      type={9}
                      data_name={
                        gatewaydetails.data_20_name +
                        " " +
                        gatewaydetails.data_20_unit.data_unit
                      }
                      data_min={min_20}
                      data_max={max_20}
                    ></Chart>
                    <Chart
                      data={data_21}
                      type={9}
                      data_name={
                        gatewaydetails.data_21_name +
                        " " +
                        gatewaydetails.data_21_unit.data_unit
                      }
                      data_min={min_21}
                      data_max={max_21}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_22}
                      type={9}
                      data_name={
                        gatewaydetails.data_22_name +
                        " " +
                        gatewaydetails.data_22_unit.data_unit
                      }
                      data_min={min_22}
                      data_max={max_22}
                    ></Chart>
                    <Chart
                      data={data_23}
                      type={9}
                      data_name={
                        gatewaydetails.data_23_name +
                        " " +
                        gatewaydetails.data_23_unit.data_unit
                      }
                      data_min={min_23}
                      data_max={max_23}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_24}
                      type={9}
                      data_name={
                        gatewaydetails.data_24_name +
                        " " +
                        gatewaydetails.data_24_unit.data_unit
                      }
                      data_min={min_24}
                      data_max={max_24}
                    ></Chart>
                    <Chart
                      data={data_25}
                      type={9}
                      data_name={
                        gatewaydetails.data_25_name +
                        " " +
                        gatewaydetails.data_25_unit.data_unit
                      }
                      data_min={min_25}
                      data_max={max_25}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_26}
                      type={9}
                      data_name={
                        gatewaydetails.data_26_name +
                        " " +
                        gatewaydetails.data_26_unit.data_unit
                      }
                      data_min={min_26}
                      data_max={max_26}
                    ></Chart>
                    <Chart
                      data={data_27}
                      type={9}
                      data_name={
                        gatewaydetails.data_27_name +
                        " " +
                        gatewaydetails.data_27_unit.data_unit
                      }
                      data_min={min_27}
                      data_max={max_27}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_28}
                      type={9}
                      data_name={
                        gatewaydetails.data_28_name +
                        " " +
                        gatewaydetails.data_28_unit.data_unit
                      }
                      data_min={min_28}
                      data_max={max_28}
                    ></Chart>
                    <Chart
                      data={data_29}
                      type={9}
                      data_name={
                        gatewaydetails.data_29_name +
                        " " +
                        gatewaydetails.data_29_unit.data_unit
                      }
                      data_min={min_29}
                      data_max={max_29}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_30}
                      type={9}
                      data_name={
                        gatewaydetails.data_30_name +
                        " " +
                        gatewaydetails.data_30_unit.data_unit
                      }
                      data_min={min_30}
                      data_max={max_30}
                    ></Chart>
                    <Chart
                      data={data_31}
                      type={9}
                      data_name={
                        gatewaydetails.data_31_name +
                        " " +
                        gatewaydetails.data_31_unit.data_unit
                      }
                      data_min={min_31}
                      data_max={max_31}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_32}
                      type={9}
                      data_name={
                        gatewaydetails.data_32_name +
                        " " +
                        gatewaydetails.data_32_unit.data_unit
                      }
                      data_min={min_32}
                      data_max={max_32}
                    ></Chart>
                    <Chart
                      data={data_33}
                      type={9}
                      data_name={
                        gatewaydetails.data_33_name +
                        " " +
                        gatewaydetails.data_33_unit.data_unit
                      }
                      data_min={min_33}
                      data_max={max_33}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_34}
                      type={9}
                      data_name={
                        gatewaydetails.data_34_name +
                        " " +
                        gatewaydetails.data_34_unit.data_unit
                      }
                      data_min={min_34}
                      data_max={max_34}
                    ></Chart>
                    <Chart
                      data={data_35}
                      type={9}
                      data_name={
                        gatewaydetails.data_35_name +
                        " " +
                        gatewaydetails.data_35_unit.data_unit
                      }
                      data_min={min_35}
                      data_max={max_35}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_36}
                      type={9}
                      data_name={
                        gatewaydetails.data_36_name +
                        " " +
                        gatewaydetails.data_36_unit.data_unit
                      }
                      data_min={min_36}
                      data_max={max_36}
                    ></Chart>
                    <Chart
                      data={data_37}
                      type={9}
                      data_name={
                        gatewaydetails.data_37_name +
                        " " +
                        gatewaydetails.data_37_unit.data_unit
                      }
                      data_min={min_37}
                      data_max={max_37}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_38}
                      type={9}
                      data_name={
                        gatewaydetails.data_38_name +
                        " " +
                        gatewaydetails.data_38_unit.data_unit
                      }
                      data_min={min_38}
                      data_max={max_38}
                    ></Chart>
                    <Chart
                      data={data_39}
                      type={9}
                      data_name={
                        gatewaydetails.data_39_name +
                        " " +
                        gatewaydetails.data_39_unit.data_unit
                      }
                      data_min={min_39}
                      data_max={max_39}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_40}
                      type={9}
                      data_name={
                        gatewaydetails.data_40_name +
                        " " +
                        gatewaydetails.data_40_unit.data_unit
                      }
                      data_min={min_40}
                      data_max={max_40}
                    ></Chart>
                    <Chart
                      data={data_41}
                      type={9}
                      data_name={
                        gatewaydetails.data_41_name +
                        " " +
                        gatewaydetails.data_41_unit.data_unit
                      }
                      data_min={min_41}
                      data_max={max_41}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_42}
                      type={9}
                      data_name={
                        gatewaydetails.data_42_name +
                        " " +
                        gatewaydetails.data_42_unit.data_unit
                      }
                      data_min={min_42}
                      data_max={max_42}
                    ></Chart>
                    <Chart
                      data={data_43}
                      type={9}
                      data_name={
                        gatewaydetails.data_43_name +
                        " " +
                        gatewaydetails.data_43_unit.data_unit
                      }
                      data_min={min_43}
                      data_max={max_43}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_44}
                      type={9}
                      data_name={
                        gatewaydetails.data_44_name +
                        " " +
                        gatewaydetails.data_44_unit.data_unit
                      }
                      data_min={min_44}
                      data_max={max_44}
                    ></Chart>
                    <Chart
                      data={data_45}
                      type={9}
                      data_name={
                        gatewaydetails.data_45_name +
                        " " +
                        gatewaydetails.data_45_unit.data_unit
                      }
                      data_min={min_45}
                      data_max={max_45}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_46}
                      type={9}
                      data_name={
                        gatewaydetails.data_46_name +
                        " " +
                        gatewaydetails.data_46_unit.data_unit
                      }
                      data_min={min_46}
                      data_max={max_46}
                    ></Chart>
                    <Chart
                      data={data_47}
                      type={9}
                      data_name={
                        gatewaydetails.data_47_name +
                        " " +
                        gatewaydetails.data_47_unit.data_unit
                      }
                      data_min={min_47}
                      data_max={max_47}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_48}
                      type={9}
                      data_name={
                        gatewaydetails.data_48_name +
                        " " +
                        gatewaydetails.data_48_unit.data_unit
                      }
                      data_min={min_48}
                      data_max={max_48}
                    ></Chart>
                    <Chart
                      data={data_49}
                      type={9}
                      data_name={
                        gatewaydetails.data_49_name +
                        " " +
                        gatewaydetails.data_49_unit.data_unit
                      }
                      data_min={min_49}
                      data_max={max_49}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_50}
                      type={9}
                      data_name={
                        gatewaydetails.data_50_name +
                        " " +
                        gatewaydetails.data_50_unit.data_unit
                      }
                      data_min={min_50}
                      data_max={max_50}
                    ></Chart>
                    <Chart
                      data={data_51}
                      type={9}
                      data_name={
                        gatewaydetails.data_51_name +
                        " " +
                        gatewaydetails.data_51_unit.data_unit
                      }
                      data_min={min_51}
                      data_max={max_51}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_52}
                      type={9}
                      data_name={
                        gatewaydetails.data_52_name +
                        " " +
                        gatewaydetails.data_52_unit.data_unit
                      }
                      data_min={min_52}
                      data_max={max_52}
                    ></Chart>
                    <Chart
                      data={data_53}
                      type={9}
                      data_name={
                        gatewaydetails.data_53_name +
                        " " +
                        gatewaydetails.data_53_unit.data_unit
                      }
                      data_min={min_53}
                      data_max={max_53}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_54}
                      type={9}
                      data_name={
                        gatewaydetails.data_54_name +
                        " " +
                        gatewaydetails.data_54_unit.data_unit
                      }
                      data_min={min_54}
                      data_max={max_54}
                    ></Chart>
                    <Chart
                      data={data_55}
                      type={9}
                      data_name={
                        gatewaydetails.data_55_name +
                        " " +
                        gatewaydetails.data_55_unit.data_unit
                      }
                      data_min={min_55}
                      data_max={max_55}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_56}
                      type={9}
                      data_name={
                        gatewaydetails.data_56_name +
                        " " +
                        gatewaydetails.data_56_unit.data_unit
                      }
                      data_min={min_56}
                      data_max={max_56}
                    ></Chart>
                    <Chart
                      data={data_57}
                      type={9}
                      data_name={
                        gatewaydetails.data_57_name +
                        " " +
                        gatewaydetails.data_57_unit.data_unit
                      }
                      data_min={min_57}
                      data_max={max_57}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_58}
                      type={9}
                      data_name={
                        gatewaydetails.data_58_name +
                        " " +
                        gatewaydetails.data_58_unit.data_unit
                      }
                      data_min={min_58}
                      data_max={max_58}
                    ></Chart>
                    <Chart
                      data={data_59}
                      type={9}
                      data_name={
                        gatewaydetails.data_59_name +
                        " " +
                        gatewaydetails.data_59_unit.data_unit
                      }
                      data_min={min_59}
                      data_max={max_59}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_60}
                      type={9}
                      data_name={
                        gatewaydetails.data_60_name +
                        " " +
                        gatewaydetails.data_60_unit.data_unit
                      }
                      data_min={min_60}
                      data_max={max_60}
                    ></Chart>
                    <Chart
                      data={data_61}
                      type={9}
                      data_name={
                        gatewaydetails.data_61_name +
                        " " +
                        gatewaydetails.data_61_unit.data_unit
                      }
                      data_min={min_61}
                      data_max={max_61}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_62}
                      type={9}
                      data_name={
                        gatewaydetails.data_62_name +
                        " " +
                        gatewaydetails.data_62_unit.data_unit
                      }
                      data_min={min_62}
                      data_max={max_62}
                    ></Chart>
                    <Chart
                      data={data_63}
                      type={9}
                      data_name={
                        gatewaydetails.data_63_name +
                        " " +
                        gatewaydetails.data_63_unit.data_unit
                      }
                      data_min={min_63}
                      data_max={max_63}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_64}
                      type={9}
                      data_name={
                        gatewaydetails.data_64_name +
                        " " +
                        gatewaydetails.data_64_unit.data_unit
                      }
                      data_min={min_64}
                      data_max={max_64}
                    ></Chart>
                    <Chart
                      data={data_65}
                      type={9}
                      data_name={
                        gatewaydetails.data_65_name +
                        " " +
                        gatewaydetails.data_65_unit.data_unit
                      }
                      data_min={min_65}
                      data_max={max_65}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_66}
                      type={9}
                      data_name={
                        gatewaydetails.data_66_name +
                        " " +
                        gatewaydetails.data_66_unit.data_unit
                      }
                      data_min={min_66}
                      data_max={max_66}
                    ></Chart>
                    <Chart
                      data={data_67}
                      type={9}
                      data_name={
                        gatewaydetails.data_67_name +
                        " " +
                        gatewaydetails.data_67_unit.data_unit
                      }
                      data_min={min_67}
                      data_max={max_67}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_68}
                      type={9}
                      data_name={
                        gatewaydetails.data_68_name +
                        " " +
                        gatewaydetails.data_68_unit.data_unit
                      }
                      data_min={min_68}
                      data_max={max_68}
                    ></Chart>
                    <Chart
                      data={data_69}
                      type={9}
                      data_name={
                        gatewaydetails.data_69_name +
                        " " +
                        gatewaydetails.data_69_unit.data_unit
                      }
                      data_min={min_69}
                      data_max={max_69}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_70}
                      type={9}
                      data_name={
                        gatewaydetails.data_70_name +
                        " " +
                        gatewaydetails.data_70_unit.data_unit
                      }
                      data_min={min_70}
                      data_max={max_70}
                    ></Chart>
                    <Chart
                      data={data_71}
                      type={9}
                      data_name={
                        gatewaydetails.data_71_name +
                        " " +
                        gatewaydetails.data_71_unit.data_unit
                      }
                      data_min={min_71}
                      data_max={max_71}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_72}
                      type={9}
                      data_name={
                        gatewaydetails.data_72_name +
                        " " +
                        gatewaydetails.data_72_unit.data_unit
                      }
                      data_min={min_72}
                      data_max={max_72}
                    ></Chart>
                    <Chart
                      data={data_73}
                      type={9}
                      data_name={
                        gatewaydetails.data_73_name +
                        " " +
                        gatewaydetails.data_73_unit.data_unit
                      }
                      data_min={min_73}
                      data_max={max_73}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_74}
                      type={9}
                      data_name={
                        gatewaydetails.data_74_name +
                        " " +
                        gatewaydetails.data_74_unit.data_unit
                      }
                      data_min={min_74}
                      data_max={max_74}
                    ></Chart>
                    <Chart
                      data={data_75}
                      type={9}
                      data_name={
                        gatewaydetails.data_75_name +
                        " " +
                        gatewaydetails.data_75_unit.data_unit
                      }
                      data_min={min_75}
                      data_max={max_75}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_76}
                      type={9}
                      data_name={
                        gatewaydetails.data_76_name +
                        " " +
                        gatewaydetails.data_76_unit.data_unit
                      }
                      data_min={min_76}
                      data_max={max_76}
                    ></Chart>
                    <Chart
                      data={data_77}
                      type={9}
                      data_name={
                        gatewaydetails.data_77_name +
                        " " +
                        gatewaydetails.data_77_unit.data_unit
                      }
                      data_min={min_77}
                      data_max={max_77}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_78}
                      type={9}
                      data_name={
                        gatewaydetails.data_78_name +
                        " " +
                        gatewaydetails.data_78_unit.data_unit
                      }
                      data_min={min_78}
                      data_max={max_78}
                    ></Chart>
                    <Chart
                      data={data_79}
                      type={9}
                      data_name={
                        gatewaydetails.data_79_name +
                        " " +
                        gatewaydetails.data_79_unit.data_unit
                      }
                      data_min={min_79}
                      data_max={max_79}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_80}
                      type={9}
                      data_name={
                        gatewaydetails.data_80_name +
                        " " +
                        gatewaydetails.data_80_unit.data_unit
                      }
                      data_min={min_80}
                      data_max={max_80}
                    ></Chart>
                    <Chart
                      data={data_81}
                      type={9}
                      data_name={
                        gatewaydetails.data_81_name +
                        " " +
                        gatewaydetails.data_81_unit.data_unit
                      }
                      data_min={min_81}
                      data_max={max_81}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_82}
                      type={9}
                      data_name={
                        gatewaydetails.data_82_name +
                        " " +
                        gatewaydetails.data_82_unit.data_unit
                      }
                      data_min={min_82}
                      data_max={max_82}
                    ></Chart>
                    <Chart
                      data={data_83}
                      type={9}
                      data_name={
                        gatewaydetails.data_83_name +
                        " " +
                        gatewaydetails.data_83_unit.data_unit
                      }
                      data_min={min_83}
                      data_max={max_83}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_84}
                      type={9}
                      data_name={
                        gatewaydetails.data_84_name +
                        " " +
                        gatewaydetails.data_84_unit.data_unit
                      }
                      data_min={min_84}
                      data_max={max_84}
                    ></Chart>
                    <Chart
                      data={data_85}
                      type={9}
                      data_name={
                        gatewaydetails.data_85_name +
                        " " +
                        gatewaydetails.data_85_unit.data_unit
                      }
                      data_min={min_85}
                      data_max={max_85}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_86}
                      type={9}
                      data_name={
                        gatewaydetails.data_86_name +
                        " " +
                        gatewaydetails.data_86_unit.data_unit
                      }
                      data_min={min_86}
                      data_max={max_86}
                    ></Chart>
                    <Chart
                      data={data_87}
                      type={9}
                      data_name={
                        gatewaydetails.data_87_name +
                        " " +
                        gatewaydetails.data_87_unit.data_unit
                      }
                      data_min={min_87}
                      data_max={max_87}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_88}
                      type={9}
                      data_name={
                        gatewaydetails.data_88_name +
                        " " +
                        gatewaydetails.data_88_unit.data_unit
                      }
                      data_min={min_88}
                      data_max={max_88}
                    ></Chart>
                    <Chart
                      data={data_89}
                      type={9}
                      data_name={
                        gatewaydetails.data_89_name +
                        " " +
                        gatewaydetails.data_89_unit.data_unit
                      }
                      data_min={min_89}
                      data_max={max_89}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_90}
                      type={9}
                      data_name={
                        gatewaydetails.data_90_name +
                        " " +
                        gatewaydetails.data_90_unit.data_unit
                      }
                      data_min={min_90}
                      data_max={max_90}
                    ></Chart>
                    <Chart
                      data={data_91}
                      type={9}
                      data_name={
                        gatewaydetails.data_91_name +
                        " " +
                        gatewaydetails.data_91_unit.data_unit
                      }
                      data_min={min_91}
                      data_max={max_91}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_92}
                      type={9}
                      data_name={
                        gatewaydetails.data_92_name +
                        " " +
                        gatewaydetails.data_92_unit.data_unit
                      }
                      data_min={min_92}
                      data_max={max_92}
                    ></Chart>
                    <Chart
                      data={data_93}
                      type={9}
                      data_name={
                        gatewaydetails.data_93_name +
                        " " +
                        gatewaydetails.data_93_unit.data_unit
                      }
                      data_min={min_93}
                      data_max={max_93}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_94}
                      type={9}
                      data_name={
                        gatewaydetails.data_94_name +
                        " " +
                        gatewaydetails.data_94_unit.data_unit
                      }
                      data_min={min_94}
                      data_max={max_94}
                    ></Chart>
                    <Chart
                      data={data_95}
                      type={9}
                      data_name={
                        gatewaydetails.data_95_name +
                        " " +
                        gatewaydetails.data_95_unit.data_unit
                      }
                      data_min={min_95}
                      data_max={max_95}
                    ></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_96}
                      type={9}
                      data_name={
                        gatewaydetails.data_96_name +
                        " " +
                        gatewaydetails.data_96_unit.data_unit
                      }
                      data_min={min_96}
                      data_max={max_96}
                    ></Chart>
                    <Chart
                      data={data_97}
                      type={9}
                      data_name={
                        gatewaydetails.data_97_name +
                        " " +
                        gatewaydetails.data_97_unit.data_unit
                      }
                      data_min={min_97}
                      data_max={max_97}
                    ></Chart>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Chart
                      data={data_98}
                      type={9}
                      data_name={
                        gatewaydetails.data_98_name +
                        " " +
                        gatewaydetails.data_98_unit.data_unit
                      }
                      data_min={min_98}
                      data_max={max_98}
                    ></Chart>
                    <Chart
                      data={data_99}
                      type={9}
                      data_name={
                        gatewaydetails.data_99_name +
                        " " +
                        gatewaydetails.data_99_unit.data_unit
                      }
                      data_min={min_99}
                      data_max={max_99}
                    ></Chart>
                  </Col>
                </Carousel.Item>
              </Carousel>
            </Row>

            <Row className="justify-content-md-center">
              <Carousel
                variant="dark"
                pause="hover"
                className="w-100 tablecarousel"
              >
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={8}>
                    <Table striped responsive hover className="table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {updateData.data_0_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_1_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_2_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_3_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_4_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_5_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_6_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_7_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_8_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_9_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {updating &&
                          updateData.data_table.map((data) => (
                            <tr key={data.id}>
                              <td className="align-middle text-center">
                                {(
                                  data.data_0 * updateData.data_0_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_0_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_1 * updateData.data_1_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_1_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_2 * updateData.data_2_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_2_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_3 * updateData.data_3_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_3_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_4 * updateData.data_4_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_4_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_5 * updateData.data_5_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_5_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_6 * updateData.data_6_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_6_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_7 * updateData.data_7_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_7_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_8 * updateData.data_8_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_8_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_9 * updateData.data_9_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_9_unit.data_unit}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={8}>
                    <Table striped responsive hover className="table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {updateData.data_10_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_11_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_12_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_13_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_14_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_15_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_16_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_17_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_18_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_19_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {updating &&
                          updateData.data_table.map((data) => (
                            <tr key={data.id}>
                              <td className="align-middle text-center">
                                {(
                                  data.data_10 * updateData.data_10_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_10_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_11 * updateData.data_11_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_11_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_12 * updateData.data_12_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_12_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_13 * updateData.data_13_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_13_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_14 * updateData.data_14_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_14_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_15 * updateData.data_15_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_15_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_16 * updateData.data_16_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_16_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_17 * updateData.data_17_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_17_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_18 * updateData.data_18_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_18_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_19 * updateData.data_19_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_19_unit.data_unit}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={8}>
                    <Table striped responsive hover className="table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {updateData.data_20_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_21_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_22_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_23_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_24_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_25_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_26_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_27_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_28_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_29_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {updating &&
                          updateData.data_table.map((data) => (
                            <tr key={data.id}>
                              <td className="align-middle text-center">
                                {(
                                  data.data_20 * updateData.data_20_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_20_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_21 * updateData.data_21_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_21_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_22 * updateData.data_22_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_22_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_23 * updateData.data_23_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_23_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_24 * updateData.data_24_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_24_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_25 * updateData.data_25_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_25_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_26 * updateData.data_26_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_26_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_27 * updateData.data_27_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_27_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_28 * updateData.data_28_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_28_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_29 * updateData.data_29_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_29_unit.data_unit}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={8}>
                    <Table striped responsive hover className="table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {updateData.data_30_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_31_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_32_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_33_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_34_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_35_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_36_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_37_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_38_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_39_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {updating &&
                          updateData.data_table.map((data) => (
                            <tr key={data.id}>
                              <td className="align-middle text-center">
                                {(
                                  data.data_30 * updateData.data_30_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_30_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_31 * updateData.data_31_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_31_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_32 * updateData.data_32_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_32_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_33 * updateData.data_33_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_33_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_34 * updateData.data_34_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_34_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_35 * updateData.data_35_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_35_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_36 * updateData.data_36_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_36_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_37 * updateData.data_37_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_37_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_38 * updateData.data_38_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_38_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_39 * updateData.data_39_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_39_unit.data_unit}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={8}>
                    <Table striped responsive hover className="table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {updateData.data_40_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_41_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_42_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_43_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_44_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_45_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_46_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_47_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_48_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_49_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {updating &&
                          updateData.data_table.map((data) => (
                            <tr key={data.id}>
                              <td className="align-middle text-center">
                                {(
                                  data.data_40 * updateData.data_40_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_40_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_41 * updateData.data_41_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_41_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_42 * updateData.data_42_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_42_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_43 * updateData.data_43_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_43_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_44 * updateData.data_44_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_44_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_45 * updateData.data_45_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_45_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_46 * updateData.data_46_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_46_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_47 * updateData.data_47_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_47_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_48 * updateData.data_48_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_48_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_49 * updateData.data_49_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_49_unit.data_unit}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={8}>
                    <Table striped responsive hover className="table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {updateData.data_50_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_51_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_52_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_53_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_54_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_55_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_56_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_57_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_58_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_59_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {updating &&
                          updateData.data_table.map((data) => (
                            <tr key={data.id}>
                              <td className="align-middle text-center">
                                {(
                                  data.data_50 * updateData.data_50_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_50_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_51 * updateData.data_51_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_51_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_52 * updateData.data_52_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_52_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_53 * updateData.data_53_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_53_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_54 * updateData.data_54_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_54_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_55 * updateData.data_55_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_55_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_56 * updateData.data_56_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_56_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_57 * updateData.data_57_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_57_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_58 * updateData.data_58_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_58_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_59 * updateData.data_59_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_59_unit.data_unit}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={8}>
                    <Table striped responsive hover className="table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {updateData.data_60_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_61_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_62_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_63_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_64_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_65_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_66_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_67_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_68_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_69_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {updating &&
                          updateData.data_table.map((data) => (
                            <tr key={data.id}>
                              <td className="align-middle text-center">
                                {(
                                  data.data_60 * updateData.data_60_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_60_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_61 * updateData.data_61_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_61_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_62 * updateData.data_62_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_62_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_63 * updateData.data_63_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_63_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_64 * updateData.data_64_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_64_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_65 * updateData.data_65_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_65_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_66 * updateData.data_66_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_66_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_67 * updateData.data_67_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_67_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_68 * updateData.data_68_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_68_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_69 * updateData.data_69_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_69_unit.data_unit}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={8}>
                    <Table striped responsive hover className="table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {updateData.data_70_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_71_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_72_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_73_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_74_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_75_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_76_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_77_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_78_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_79_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {updating &&
                          updateData.data_table.map((data) => (
                            <tr key={data.id}>
                              <td className="align-middle text-center">
                                {(
                                  data.data_70 * updateData.data_70_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_70_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_71 * updateData.data_71_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_71_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_72 * updateData.data_72_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_72_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_73 * updateData.data_73_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_73_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_74 * updateData.data_74_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_74_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_75 * updateData.data_75_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_75_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_76 * updateData.data_76_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_76_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_77 * updateData.data_77_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_77_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_78 * updateData.data_78_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_78_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_79 * updateData.data_79_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_79_unit.data_unit}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={8}>
                    <Table striped responsive hover className="table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {updateData.data_80_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_81_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_82_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_83_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_84_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_85_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_86_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_87_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_88_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_89_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {updating &&
                          updateData.data_table.map((data) => (
                            <tr key={data.id}>
                              <td className="align-middle text-center">
                                {(
                                  data.data_80 * updateData.data_80_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_80_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_81 * updateData.data_81_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_81_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_82 * updateData.data_82_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_82_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_88 * updateData.data_83_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_33_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_84 * updateData.data_84_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_84_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_85 * updateData.data_85_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_85_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_86 * updateData.data_86_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_86_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_87 * updateData.data_87_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_87_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_88 * updateData.data_88_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_88_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_89 * updateData.data_89_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_89_unit.data_unit}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={8}>
                    <Table striped responsive hover className="table-sm">
                      <thead>
                        <tr>
                          <th className="text-center">
                            {updateData.data_90_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_91_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_92_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_93_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_94_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_95_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_96_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_97_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_98_name}
                          </th>
                          <th className="text-center">
                            {updateData.data_99_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {updating &&
                          updateData.data_table.map((data) => (
                            <tr key={data.id}>
                              <td className="align-middle text-center">
                                {(
                                  data.data_90 * updateData.data_90_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_90_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_91 * updateData.data_91_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_41_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_92 * updateData.data_92_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_92_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_93 * updateData.data_93_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_93_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_94 * updateData.data_94_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_94_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_95 * updateData.data_95_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_95_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_96 * updateData.data_96_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_96_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_97 * updateData.data_97_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_97_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_98 * updateData.data_98_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_98_unit.data_unit}
                              </td>
                              <td className="align-middle text-center">
                                {(
                                  data.data_99 * updateData.data_99_multiplier
                                ).toFixed(2)}{" "}
                                {updateData.data_99_unit.data_unit}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Carousel.Item>
              </Carousel>
            </Row>
          </>
        )
      )}
    </div>
  );
}

export default AdminGatewayDetailsScreen;
