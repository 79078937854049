import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import FormContainer from "../../components/FormContainer";
import {
  getUserDetails,
  updateUser,
  getUserCustomers,
} from "../../actions/userActions";
import { USER_UPDATE_RESET } from "../../constants/userConstants";
import { useTranslation } from "react-i18next";

function UserEditScreen() {
  const params = useParams();
  const userId = params.id;
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [isStaff, setIsStaff] = useState("");
  const [customer, setCustomer] = useState(-1);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const customerState = useSelector((state) => state.userCustomer);
  const {
    error: errorCustomers,
    loading: loadingCustomers,
    success,
    customers,
  } = customerState;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = userUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({
        type: USER_UPDATE_RESET,
      });
      navigate("/staff/users");
    } else {
      if (!user.first_name || user.id !== Number(userId)) {
        dispatch(getUserDetails(userId));
        dispatch(getUserCustomers());
      } else {
        dispatch(getUserCustomers());
        setName(user.first_name);
        setUsername(user.username);
        setEmail(user.email);
        setIsStaff(user.is_staff);
        if (user.customer) {
          setCustomer(user.customer.id);
        }
      }
    }
  }, [userId, dispatch, user, navigate, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    const nr_customer = Number(customer);
    dispatch(
      updateUser({
        id: user.id,
        name,
        username,
        email,
        isStaff,
        customer: nr_customer,
      })
    );
  };
  return (
    <div>
      <FormContainer>
        <h1>{t("edit_user")}</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading || !success ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : errorCustomers ? (
          <Message variant="danger">{errorCustomers}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>{t("name")}:</Form.Label>
              <Form.Control
                type="name"
                placeholder={t("enter_first_name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="username">
              <Form.Label>{t("username")}:</Form.Label>
              <Form.Control
                type="name"
                placeholder={t("enter_user_name")}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>{t("email")}:</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("enter_email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="customer">
              <Form.Label>{t("company")}:</Form.Label>
              <Form.Select
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
              >
                <option value="-1" key="-1">
                  ---
                </option>
                {customers.map((customer) => (
                  <option value={customer.id} key={customer.id}>
                    {customer.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="isStaff">
              <Form.Check
                type="checkbox"
                label={t("is_admin")}
                checked={isStaff}
                onChange={(e) => setIsStaff(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            <Button className="mt-2" type="submit" variant="primary">
              {t("edit")}
            </Button>
          </Form>
        )}
      </FormContainer>
    </div>
  );
}

export default UserEditScreen;
