export const USER_SENSOR_REQUEST = "USER_SENSOR_REQUEST";
export const USER_SENSOR_SUCCESS = "USER_SENSOR_SUCCESS";
export const USER_SENSOR_FAIL = "USER_SENSOR_FAIL";
export const USER_SENSOR_RESET = "USER_SENSOR_RESET";

export const ADMIN_SENSOR_REQUEST = "ADMIN_SENSOR_REQUEST";
export const ADMIN_SENSOR_SUCCESS = "ADMIN_SENSOR_SUCCESS";
export const ADMIN_SENSOR_FAIL = "ADMIN_SENSOR_FAIL";
export const ADMIN_SENSOR_RESET = "ADMIN_SENSOR_RESET";

export const SENSOR_DATA_REQUEST = "SENSOR_DATA_REQUEST";
export const SENSOR_DATA_SUCCESS = "SENSOR_DATA_SUCCESS";
export const SENSOR_DATA_FAIL = "SENSOR_DATA_FAIL";

export const SENSOR_UPDATE_REQUEST = "SENSOR_UPDATE_REQUEST";
export const SENSOR_UPDATE_SUCCESS = "SENSOR_UPDATE_SUCCESS";
export const SENSOR_UPDATE_FAIL = "SENSOR_UPDATE_FAIL";

export const SENSOR_DATA_BASIC_REQUEST = "SENSOR_DATA_BASIC_REQUEST";
export const SENSOR_DATA_BASIC_SUCCESS = "SENSOR_DATA_BASIC_SUCCESS";
export const SENSOR_DATA_BASIC_FAIL = "SENSOR_DATA_BASIC_FAIL";

export const SENSOR_DATA_BASIC_UPDATE_REQUEST =
  "SENSOR_DATA_BASIC_UPDATE_REQUEST";
export const SENSOR_DATA_BASIC_UPDATE_SUCCESS =
  "SENSOR_DATA_BASIC_UPDATE_SUCCESS";
export const SENSOR_DATA_BASIC_UPDATE_FAIL = "SENSOR_DATA_BASIC_UPDATE_FAIL";

export const USER_EIR_REQUEST = "USER_EIR_REQUEST";
export const USER_EIR_SUCCESS = "USER_EIR_SUCCESS";
export const USER_EIR_FAIL = "USER_EIR_FAIL";
export const USER_EIR_RESET = "USER_EIR_RESET";

export const ADMIN_EIR_REQUEST = "ADMIN_EIR_REQUEST";
export const ADMIN_EIR_SUCCESS = "ADMIN_EIR_SUCCESS";
export const ADMIN_EIR_FAIL = "ADMIN_EIR_FAIL";
export const ADMIN_EIR_RESET = "ADMIN_EIR_RESET";

export const EIR_DATA_REQUEST = "EIR_DATA_REQUEST";
export const EIR_DATA_SUCCESS = "EIR_DATA_SUCCESS";
export const EIR_DATA_FAIL = "EIR_DATA_FAIL";

export const EIR_UPDATE_REQUEST = "EIR_UPDATE_REQUEST";
export const EIR_UPDATE_SUCCESS = "EIR_UPDATE_SUCCESS";
export const EIR_UPDATE_FAIL = "EIR_UPDATE_FAIL";
