import {
  USER_PROJECT_REQUEST,
  USER_PROJECT_SUCCESS,
  USER_PROJECT_FAIL,
  ADMIN_PROJECT_REQUEST,
  ADMIN_PROJECT_SUCCESS,
  ADMIN_PROJECT_FAIL,
  UNIT_REQUEST,
  UNIT_SUCCESS,
  UNIT_FAIL,
  UNIT_PICTURE_REQUEST,
  UNIT_PICTURE_SUCCESS,
  UNIT_PICTURE_FAIL,
} from "../constants/serviceConstants";
import axios from "axios";

export const getAdminProjects = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_PROJECT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/service/projects-admin/`, config);

    dispatch({
      type: ADMIN_PROJECT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PROJECT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserProjects = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PROJECT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/service/projects-user/`, config);

    dispatch({
      type: USER_PROJECT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROJECT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUnits =
  (id, keyword = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: UNIT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/service/${id}/units/${keyword}`,
        config
      );

      dispatch({
        type: UNIT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UNIT_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const getPictures =
  (projectid, unitid) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UNIT_PICTURE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/service/${projectid}/${unitid}/pictures/`,
        config
      );

      dispatch({
        type: UNIT_PICTURE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UNIT_PICTURE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
