import Reactfrom from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import HomePageCarousel from "../../components/HomePageCarousel";

function HomeScreen() {
  return (
    <div>
      <div>
        <HomePageCarousel />
      </div>
    </div>
  );
}

export default HomeScreen;
