import React from "react";
import ReactFlow from "react-flow-renderer";
import { useNavigate, useParams } from "react-router-dom";

function Chart({
  nodes,
  edges,
  nodes2,
  edges2,
  nodes3,
  edges3,
  ahu_count,
  admin_screen,
  boiler_screen = false,
}) {
  const params = useParams();
  const productId = params.id;
  const navigate = useNavigate();

  const nodeClick = (node_id) => {
    if (admin_screen) {
      if (boiler_screen) {
        navigate(`/staff/boiler/${productId}/${node_id}/1`);
      } else {
        navigate(`/staff/gateway/${productId}/${node_id}/1`);
      }
    } else {
      if (boiler_screen) {
        navigate(`/user/boiler/${productId}/${node_id}/1`);
      } else {
        navigate(`/user/gateway/${productId}/${node_id}/1`);
      }
    }
  };
  if (ahu_count == 1) {
    return (
      <div className="w-100" style={{ height: 1000 }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodesDraggable={false}
          nodesConnectable={false}
          zoomOnScroll={false}
          onNodeClick={(event, node) => nodeClick(node.id)}
          fitView
          attributionPosition="bottom-left"
        ></ReactFlow>
      </div>
    );
  }
  if (ahu_count == 2) {
    return (
      <>
        <div className="w-100" style={{ height: 1000 }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodesDraggable={false}
            nodesConnectable={false}
            zoomOnScroll={false}
            onNodeClick={(event, node) => nodeClick(node.id)}
            fitView
            attributionPosition="bottom-left"
          ></ReactFlow>
        </div>
        <div className="w-100" style={{ height: 1000 }}>
          <ReactFlow
            nodes={nodes2}
            edges={edges2}
            nodesDraggable={false}
            nodesConnectable={false}
            zoomOnScroll={false}
            onNodeClick={(event, node) => nodeClick(node.id)}
            fitView
            attributionPosition="bottom-left"
          ></ReactFlow>
        </div>
      </>
    );
  }
  if (ahu_count == 3) {
    return (
      <>
        <div className="w-100" style={{ height: 1000 }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodesDraggable={false}
            nodesConnectable={false}
            zoomOnScroll={false}
            onNodeClick={(event, node) => nodeClick(node.id)}
            fitView
            attributionPosition="bottom-left"
          ></ReactFlow>
        </div>
        <div className="w-100" style={{ height: 1000 }}>
          <ReactFlow
            nodes={nodes2}
            edges={edges2}
            nodesDraggable={false}
            nodesConnectable={false}
            zoomOnScroll={false}
            onNodeClick={(event, node) => nodeClick(node.id)}
            fitView
            attributionPosition="bottom-left"
          ></ReactFlow>
        </div>
        <div className="w-100" style={{ height: 1000 }}>
          <ReactFlow
            nodes={nodes3}
            edges={edges3}
            nodesDraggable={false}
            nodesConnectable={false}
            zoomOnScroll={false}
            onNodeClick={(event, node) => nodeClick(node.id)}
            fitView
            attributionPosition="bottom-left"
          ></ReactFlow>
        </div>
      </>
    );
  }
}

export default Chart;
