import React from "react";
import { Carousel, Image } from "react-bootstrap";

function HomePageCarousel() {
  return (
    <div>
      <Carousel pause="hover">
        <Carousel.Item>
          <Image
            className="d-block mx-auto img-fluid"
            src="/images/Co2.png"
            alt="slider_1"
            fluid
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="d-block mx-auto img-fluid"
            src="/images/Cough.png"
            alt="slider_2"
            fluid
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="d-block mx-auto img-fluid"
            src="/images/IOT_Banner_1.png"
            alt="slider_3"
            fluid
          />
        </Carousel.Item>        
        <Carousel.Item>
          <Image
            className="d-block mx-auto img-fluid"
            src="/images/IOT_Banner_2.png"
            alt="slider_4"
            fluid
          />
        </Carousel.Item>        
        <Carousel.Item>
          <Image
            className="d-block mx-auto img-fluid"
            src="/images/IOT_Banner_3.png"
            alt="slider_5"
            fluid
          />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="d-block mx-auto img-fluid"
            src="/images/School.png"
            alt="slider_6"
            fluid
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default HomePageCarousel;
