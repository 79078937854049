import { Table, Row, Col, Button } from "react-bootstrap";
import {
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Scatter,
  ResponsiveContainer,
  Area,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";



function PsychoTest() {

  const data = [
    { Temperature: 19, Humidity: 8.4},
    { Temperature: 26, Humidity: 14.2},
    { Temperature: 26, Humidity: 15.8},
    { Temperature: 19, Humidity: 9.4 },
  ];


  const point1 = [
    {Temperature: 25.3, Humidity:10.3},
  ]

  const point2 = [
    {Temperature: 25.6, Humidity:10.3},
  ]

  const point3 = [
    {Temperature: 23.9, Humidity:12.7},
  ]

  return (
      <div style={{width: '100%', height: 400}}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 80,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey="Temperature"  domain={[10, 30]}/>
          <YAxis type="number" dataKey="Humidity" mirror={true} domain={[5, 19]}/>

          
          <Tooltip />
          <Legend />

          <Area type="linear" dot={false} activeDot={false} isAnimationActive={false} dataKey="Humidity" stroke="#8884d8" fill="#8884d8" />
          <Scatter data={point1} stroke = "#13FF00" fill="#13FF00"/>
          <Scatter data={point2} stroke = "#F3FF00" fill="#F3FF00"/>
          <Scatter data={point3} stroke = "#000FFF" fill="#000FFF"/>


        </ComposedChart>
      </ResponsiveContainer>    
      </div> 
  );
}

export default PsychoTest;
