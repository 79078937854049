export const TRANSLATIONS_EN = {
  //HEADER STUFF
  admin_menu: "Admin Menu",
  user_menu: "User Menu",
  demo_menu: "Demo Devices",

  complex_demo: "Complex Sensor",
  co2_demo: "CO2 Sensor",
  users: "Users",
  sensors: "Sensors",
  eir: "EIR",
  neffes: "Neffes",
  ahu_monitoring: "AHU Monitoring",
  products: "Products",

  loginmenu: "User",
  logout: "Log Out",
  login: "Log In",
  service: "Service",

  language_selector: "DİL SEÇİMİ",

  demo_complex_sensor: "Demo Complex Sensor",
  demo_co2_sensor: "Demo CO2 Sensor",

  boiler: "Boilers",

  //FOOTER STUFF
  footer: "Copyright ©Apex Otomasyon",

  //USER SCREENS
  //LOGIN
  username: "Username",
  password: "Password",
  enter_username: "Enter Username",
  enter_password: "Enter Password",
  login_button: "Log In",
  new_user: "Are you a new user?",
  signup: "Click Here",

  //REGISTER
  register: "Register",
  first_name: "First Name",
  enter_first_name: "Please enter first name",
  last_name: "Last Name",
  enter_last_name: "Please enter last name",
  enter_user_name: "Please enter username",
  email: "E-Mail",
  enter_email: "Please enter e-mail address",
  confirm_password: "Re-Enter Password",
  enter_confirm_password: "Please enter your password again",
  already_user: "Are you already a user?",
  password_dont_match: "Passwords don't match",

  //USERS SCREEN
  user_delete_check: "Do you want to delete this user?",
  name: "Name",
  comp_name: "Company",
  is_admin: "Is Admin?",
  edit: "Edit",
  delete: "Delete",
  create_new_comp: "Create New Company",
  enter_comp_name: "Please Enter Company Name",
  create: "Create",

  //EDIT USER SCREEN
  company: "Company",

  //SENSORS SCREENS
  //ADMIN SENSORS SCREEN
  create_sensor: "Create New Sensor",
  mac_id: "MAC ID",
  details: "Details",
  status: "Status",

  //ADMIN EDIT SENSOR SCREEN
  sensor_details: "Sensor Details",
  device_delete: "Are you sure you want to delete this sensor?",
  enter_name: "Please enter name",
  enter_device_id: "Please enter device id",
  save: "Save",
  device_id: "Device ID",

  //ADMIN SENSOR DETAILS SCREEN
  temperature: "Temperature",
  humidity: "Humidity",
  time_date: "Time-Date",

  //EIR SCREENS
  //ADMIN EIR SCREEN
  create_eir_device: "Create New EIR Device",

  //ADMIN EDIT EIR SCREEN
  eir_details: "EIR Details",

  //ADMIN EIR DETAILS SCREEN
  eir_state: "Device Status",
  hepa_drop: "Hepa Pressure Drop",
  g3_drop: "G3 Pressure Drop",
  hepa_state: "Hepa State",
  g3_state: "G3 State",
  work_hours: "Work Hours",

  //NEFFES SCREENS
  //ADMIN NEFFES SCREEN
  neffes_devices: "Neffes Devices",
  create_neffes_device: "Create New Neffes Device",

  //ADMIN NEFFES EDIT SCREEN
  neffes_details: "Neffes Details",
  neffes_delete: "Are you sure you want to delete this Neffes?",

  //ADMIN NEFFES DETAILS SCREEN
  web_override: "Web Override",
  web_power: "Web Power",
  web_uvc: "Web UVC",
  power: "Power",
  uvc: "UVC",
  fan_speed: "Fan Speed",
  uvc_hours: "UVC Hours",

  //ADMIN CREATE NEFFES SCREEN
  create_neffes: "Create Neffes",

  //GATEWAY SCREENS
  //ADMIN GATEWAYS SCREEN
  gateway_devices: "Gateway Devices",
  create_gateway_device: "Create New Gateway",

  //ADMIN EDIT GATEWAY SCREEN
  gateway_details: "Gateway Details",

  //ADMIN GATEWAY DIAGRAM SCREEN
  empty: "Empty",
  default: "Default",
  detailed_view: "Detailed View",
  latest_date: "Latest Date on Graph",

  //PRODUCT SCREENS
  general: "General Information",
  documents: "Documents",
  tr_download: "Click Here to Download Turkish Datasheet",
  en_download: "Click Here to Download English Datasheet",

  //SERVICE SCREENS
  projects: "Projects",
  create_project: "Create Project",
  units: "Units",
  create_unit: "Create Unit",
  comments: "Comments",
  create_comment: "Create Comment",
  new_picture: "New Comment",
  picture: "Picture",
  comment: "Comment",
};
