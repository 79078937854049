import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { getUserBoilers } from "../../actions/gatewayActions";
import { useTranslation } from "react-i18next";

function UserBoilersScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userBoiler = useSelector((state) => state.userBoiler);
  const { loading, error, success, boilers } = userBoiler;

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserBoilers());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  return (
    <div>
      <Row className="align-items-center">
        <Col>
          <h1>{t("boiler")}</h1>
        </Col>
        {false && (
          <Col className="text-end">
            <LinkContainer to={"/user/creategateway"}>
              <Button className="my-3  w-100">
                <i className="fas fa-plus" style={{ color: "green" }}></i>
                {t("create_gateway_device")}
              </Button>
            </LinkContainer>
          </Col>
        )}
      </Row>
      {!success ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped responsive hover className="table-sm">
          <thead>
            <tr>
              <th className="text-center">{t("name")}</th>
              <th className="text-center">{t("mac_id")}</th>
              <th className="text-center">{t("status")}</th>
              <th className="text-center">{t("details")}</th>
              <th className="text-center">{t("GUI")}</th>
              <th className="text-center">{t("edit")}</th>
            </tr>
          </thead>
          <tbody>
            {boilers.map((sensor) => (
              <tr key={sensor.id}>
                <td className="align-middle text-center">{sensor.name}</td>
                <td className="align-middle text-center">{sensor.uid}</td>
                <td className="align-middle text-center">
                  {sensor.device_online ? (
                    <div className="my-1 led-green"></div>
                  ) : (
                    <div className="my-1 led-red"></div>
                  )}
                </td>
                <td className="align-middle text-center">
                  <LinkContainer to={`/user/boiler/${sensor.id}/overview`}>
                    <Button
                      variant="transparent"
                      className="btn-sm"
                      style={{ color: "red" }}
                    >
                      <i className="fas fa-magnifying-glass"></i>
                    </Button>
                  </LinkContainer>
                </td>
                <td className="align-middle text-center">
                  <LinkContainer to={`/user/boiler/${sensor.id}/gui`}>
                    <Button
                      variant="transparent"
                      className="btn-sm"
                      style={{ color: "red" }}
                    >
                      <i className="fas fa-image"></i>
                    </Button>
                  </LinkContainer>
                </td>
                <td className="align-middle text-center">
                  <LinkContainer to={`/user/boiler/${sensor.id}/edit`}>
                    <Button
                      variant="transparent"
                      className="btn-sm"
                      style={{ color: "red" }}
                    >
                      <i className="fas fa-edit"></i>
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default UserBoilersScreen;
