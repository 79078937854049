import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import FormContainer from "../../components/FormContainer";
import { login } from "../../actions/userActions";
import { useTranslation } from "react-i18next";

function LoginScreen() {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const redirect = window.location.search
    ? window.location.search.split("=")[1]
    : "/";

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };
  return (
    <FormContainer>
      <h1>{t("login")}</h1>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>{t("username")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("enter_username")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>{t("password")}:</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("enter_password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button className="mt-2" type="submit" variant="primary">
          {t("login_button")}
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          {t("new_user")}{" "}
          <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>
            {t("signup")}
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
}

export default LoginScreen;
