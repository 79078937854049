import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import FormContainer from "../../components/FormContainer";
import { useTranslation } from "react-i18next";

function UserCreateBoilerScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [id, setId] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  const editSensor = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const postData = {
        uid: id,
        name,
      };

      await axios.post(`/api/gateway/gateway-create/`, postData, config);
    } catch (error) {}

    navigate(`/user/remote-monitoring`);
  };

  return (
    <div>
      <h1>{t("create_gateway_device")}</h1>

      <FormContainer>
        <Form onSubmit={editSensor}>
          <Form.Group controlId="text">
            <Form.Label>{t("name")}:</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("enter_name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="text">
            <Form.Label>{t("device_id")}:</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("enter_device_id")}
              value={id}
              onChange={(e) => setId(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Button className="mt-2" type="submit" variant="primary">
            {t("save")}
          </Button>
        </Form>
      </FormContainer>
    </div>
  );
}

export default UserCreateBoilerScreen;
