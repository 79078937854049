import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import FormContainer from "../../components/FormContainer";
import { register } from "../../actions/userActions";
import { useTranslation } from "react-i18next";

function RegisterScreen() {
  let navigate = useNavigate();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const redirect = window.location.search
    ? window.location.search.split("=")[1]
    : "/";

  const userRegister = useSelector((state) => state.userRegister);
  const { error, loading, userInfo } = userRegister;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage(t("password_dont_match"));
    } else {
      dispatch(register(name, surname, username, email, password));
    }
  };
  return (
    <FormContainer>
      <h1>{t("register")}</h1>
      {error && <Message variant="danger">{error}</Message>}
      {message && <Message variant="danger">{message}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label>{t("first_name")}:</Form.Label>
          <Form.Control
            required
            type="name"
            placeholder={t("enter_first_name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="surname">
          <Form.Label>{t("last_name")}:</Form.Label>
          <Form.Control
            required
            type="name"
            placeholder={t("enter_last_name")}
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="username">
          <Form.Label>{t("username")}:</Form.Label>
          <Form.Control
            required
            type="username"
            placeholder={t("enter_user_name")}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>{t("email")}:</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder={t("enter_email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>{t("password")}:</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder={t("enter_password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="passwordConfirm">
          <Form.Label>{t("confirm_password")}:</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder={t("enter_confirm_password")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button className="mt-2" type="submit" variant="primary">
          {t("register")}
        </Button>
      </Form>
      <Row className="py-3">
        <Col>
          {t("already_user")}{" "}
          <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
            {t("login")}
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
}

export default RegisterScreen;
