import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Chart from "../../components/Chart";
import {
  getSensorDetailsBasic,
  getSensorDetailsBasicUpdates,
} from "../../actions/deviceActions";
import { LinkContainer } from "react-router-bootstrap";
import moment from "moment";
import "moment-timezone";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";
import GaugeChart from "react-gauge-chart";

function BasicSensorScreen() {
  const params = useParams();
  const macId = params.macid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [data_1, setData_1] = useState(0);
  const [data_2, setData_2] = useState(0);
  const [data_3, setData_3] = useState(0);
  const [data_4, setData_4] = useState(0);

  const [sensorPm, setSensorPm] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [timezone, setTimezone] = useState({});

  const [anim, setAnim] = useState(true);
  const [firstUp, setFirstUp] = useState(false);
  const [globalTimezone, setGlobalTimezone] = useGlobal("globalTimezone");

  useEffect(() => {
    const timeZoneFromStorage = localStorage.getItem("timeZone")
      ? JSON.parse(localStorage.getItem("timeZone"))
      : null;

    if (
      timeZoneFromStorage != null &&
      timezone.value != timeZoneFromStorage.value
    ) {
      setTimezone(timeZoneFromStorage);
    }
  }, [globalTimezone]);

  const changeDatetimeByTimezone = (datetime, timezone) => {
    if (timezone == null) {
      timezone = "Europe/Moscow";
    }
    return moment.utc(datetime).tz(timezone).format();
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const sensorBasicDetails = useSelector((state) => state.sensorBasicDetails);
  const { loading, error, success, sensordetailsbasic } = sensorBasicDetails;

  const sensorBasicUpdates = useSelector((state) => state.sensorBasicUpdates);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    sensordetailsbasicupdate,
  } = sensorBasicUpdates;

  useEffect(() => {
    if (updateSuccess) {
      setUpdateData(sensordetailsbasicupdate);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (success) {
      setUpdateData(sensordetailsbasic);
      setUpdating(true);
    }
  }, [success]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getSensorDetailsBasicUpdates(macId));
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(getSensorDetailsBasic(macId));
  }, [dispatch, navigate, userInfo, macId]);

  useEffect(() => {
    if (updating) {
      const { related_data } = updateData;
      const pm_top = 500;
      const co_top = 5000;
      if (related_data.data_1 == 9999) {
        setSensorPm(false);
      } else {
        setSensorPm(true);
      }

      if (related_data.data_1 != 9999) {
        let pm_1 = (related_data.data_1 / pm_top).toFixed(2);
        let pm_2 = (related_data.data_2 / pm_top).toFixed(2);
        let pm_10 = (related_data.data_3 / pm_top).toFixed(2);

        setData_1(pm_1);
        setData_2(pm_2);
        setData_3(pm_10);
      }

      let co = (related_data.data_4 / co_top).toFixed(2);
      setData_4(co);
      setFirstUp(true);
    }
  }, [updateData]);

  useEffect(() => {
    if (firstUp) {
      setAnim(false);
    }
  }, [firstUp]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        updating && (
          <>
            <h1>
              {sensordetailsbasic.name}

              {userInfo &&
                (userInfo.customer.id == sensordetailsbasic.customer.id ||
                  userInfo.customer.id ==
                    sensordetailsbasic.customer.main_customer) && (
                  <LinkContainer
                    to={`/user/sensor/${sensordetailsbasic.id}/details`}
                  >
                    <Button variant="transparent" style={{ color: "red" }}>
                      <i className="fas fa-arrow-left"></i>
                    </Button>
                  </LinkContainer>
                )}
            </h1>
            <Row>
              <Col xs={12} className="text-center">
                <h3>
                  {changeDatetimeByTimezone(
                    updateData.related_data.posted,
                    timezone.value
                  ).substring(11, 19)}{" "}
                  {changeDatetimeByTimezone(
                    updateData.related_data.posted,
                    timezone.value
                  ).substring(0, 10)}
                </h3>
              </Col>
            </Row>
            <Row className="my-2 text-center">
              <Col xs={6}>
                <h4>
                  {t("temperature")}: {updateData.related_data.data_5}{" "}
                  {updateData.data_5_unit.data_unit}
                </h4>
              </Col>
              <Col xs={6}>
                <h4>
                  {t("humidity")}: {updateData.related_data.data_6}{" "}
                  {updateData.data_6_unit.data_unit}
                </h4>
              </Col>
            </Row>
            {sensorPm ? (
              <Row className="justify-content-md-center my-5">
                <Col xs={12} lg={4} className="text-center">
                  <GaugeChart
                    id="gauge-chart1"
                    arcsLength={[0.2, 0.4, 0.4]}
                    colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                    percent={Number(data_1)}
                    textColor="#333"
                    animate={anim}
                    formatTextValue={(value) =>
                      "PM1.0: " +
                      updateData.related_data.data_1 +
                      " " +
                      updateData.data_1_unit.data_unit
                    }
                  />
                </Col>
                <Col xs={12} lg={4} className="text-center">
                  <GaugeChart
                    id="gauge-chart2"
                    arcsLength={[0.2, 0.4, 0.4]}
                    colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                    percent={Number(data_2)}
                    textColor="#333"
                    animate={anim}
                    formatTextValue={(value) =>
                      "PM2.5: " +
                      updateData.related_data.data_2 +
                      " " +
                      updateData.data_2_unit.data_unit
                    }
                  />
                </Col>
                <Col xs={12} lg={4} className="text-center">
                  <GaugeChart
                    id="gauge-chart3"
                    arcsLength={[0.2, 0.4, 0.4]}
                    colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                    percent={Number(data_3)}
                    textColor="#333"
                    animate={anim}
                    formatTextValue={(value) =>
                      "PM10: " +
                      updateData.related_data.data_3 +
                      " " +
                      updateData.data_3_unit.data_unit
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  lg={4}
                  className="text-center"
                  style={{ fontSize: 3 }}
                >
                  <GaugeChart
                    id="gauge-chart4"
                    arcsLength={[0.2, 0.4, 0.4]}
                    colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                    percent={Number(data_4)}
                    textColor="#333"
                    animate={anim}
                    formatTextValue={(value) =>
                      "CO2: " +
                      updateData.related_data.data_4 +
                      " " +
                      updateData.data_4_unit.data_unit
                    }
                  />
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-md-center my-5">
                <Col
                  xs={12}
                  lg={4}
                  className="text-center"
                  style={{ fontSize: 3 }}
                >
                  <GaugeChart
                    id="gauge-chart4"
                    arcsLength={[0.2, 0.4, 0.4]}
                    colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                    percent={Number(data_4)}
                    textColor="#333"
                    animate={anim}
                    formatTextValue={(value) =>
                      "CO2: " +
                      updateData.related_data.data_4 +
                      " " +
                      updateData.data_4_unit.data_unit
                    }
                  />
                </Col>
              </Row>
            )}
          </>
        )
      )}
    </div>
  );
}

export default BasicSensorScreen;
