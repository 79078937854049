import {
  USER_NEFFES_REQUEST,
  USER_NEFFES_SUCCESS,
  USER_NEFFES_FAIL,
  USER_NEFFES_RESET,
  ADMIN_NEFFES_REQUEST,
  ADMIN_NEFFES_SUCCESS,
  ADMIN_NEFFES_FAIL,
  ADMIN_NEFFES_RESET,
  NEFFES_DATA_REQUEST,
  NEFFES_DATA_SUCCESS,
  NEFFES_DATA_FAIL,
  NEFFES_UPDATE_REQUEST,
  NEFFES_UPDATE_SUCCESS,
  NEFFES_UPDATE_FAIL,
  USER_NEFFES_GROUP_REQUEST,
  USER_NEFFES_GROUP_SUCCESS,
  USER_NEFFES_GROUP_FAIL,
  USER_NEFFES_GROUP_RESET,
  ADMIN_NEFFES_GROUP_REQUEST,
  ADMIN_NEFFES_GROUP_SUCCESS,
  ADMIN_NEFFES_GROUP_FAIL,
  ADMIN_NEFFES_GROUP_RESET,
  NEFFES_GROUP_DATA_REQUEST,
  NEFFES_GROUP_DATA_SUCCESS,
  NEFFES_GROUP_DATA_FAIL,
  NEFFES_GROUP_UPDATE_REQUEST,
  NEFFES_GROUP_UPDATE_SUCCESS,
  NEFFES_GROUP_UPDATE_FAIL,
} from "../constants/neffesConstants";

export const userNeffesReducer = (state = { neffesdevices: {} }, action) => {
  switch (action.type) {
    case USER_NEFFES_REQUEST:
      return { loading: true, success: false };

    case USER_NEFFES_SUCCESS:
      return { loading: false, success: true, neffesdevices: action.payload };

    case USER_NEFFES_FAIL:
      return { loading: false, success: true, error: action.payload };

    case USER_NEFFES_RESET:
      return { neffesdevices: {} };

    default:
      return state;
  }
};

export const adminNeffesReducer = (state = { neffesdevices: {} }, action) => {
  switch (action.type) {
    case ADMIN_NEFFES_REQUEST:
      return { loading: true, success: false };

    case ADMIN_NEFFES_SUCCESS:
      return { loading: false, success: true, neffesdevices: action.payload };

    case ADMIN_NEFFES_FAIL:
      return { loading: false, success: true, error: action.payload };

    case ADMIN_NEFFES_RESET:
      return { neffesdevices: {} };

    default:
      return state;
  }
};

export const neffesDetailsReducer = (state = { neffesDetails: {} }, action) => {
  switch (action.type) {
    case NEFFES_DATA_REQUEST:
      return { loading: true, success: false };

    case NEFFES_DATA_SUCCESS:
      return { loading: false, success: true, neffesDetails: action.payload };

    case NEFFES_DATA_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const neffesUpdateReducer = (state = { neffesupdates: {} }, action) => {
  switch (action.type) {
    case NEFFES_UPDATE_REQUEST:
      return { loading: true, success: false };

    case NEFFES_UPDATE_SUCCESS:
      return { loading: false, success: true, neffesupdates: action.payload };

    case NEFFES_UPDATE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const userNeffesGroupReducer = (
  state = { neffesgroups: {} },
  action
) => {
  switch (action.type) {
    case USER_NEFFES_GROUP_REQUEST:
      return { loading: true, success: false };

    case USER_NEFFES_GROUP_SUCCESS:
      return { loading: false, success: true, neffesgroups: action.payload };

    case USER_NEFFES_GROUP_FAIL:
      return { loading: false, success: true, error: action.payload };

    case USER_NEFFES_GROUP_RESET:
      return { neffesgroups: {} };

    default:
      return state;
  }
};

export const adminNeffesGroupReducer = (
  state = { neffesgroups: {} },
  action
) => {
  switch (action.type) {
    case ADMIN_NEFFES_GROUP_REQUEST:
      return { loading: true, success: false };

    case ADMIN_NEFFES_GROUP_SUCCESS:
      return { loading: false, success: true, neffesgroups: action.payload };

    case ADMIN_NEFFES_GROUP_FAIL:
      return { loading: false, success: true, error: action.payload };

    case ADMIN_NEFFES_RESET:
      return { neffesgroups: {} };

    default:
      return state;
  }
};

export const neffesGroupDetailsReducer = (
  state = { neffesGroupDetails: {} },
  action
) => {
  switch (action.type) {
    case NEFFES_GROUP_DATA_REQUEST:
      return { loading: true, success: false };

    case NEFFES_GROUP_DATA_SUCCESS:
      return {
        loading: false,
        success: true,
        neffesGroupDetails: action.payload,
      };

    case NEFFES_GROUP_DATA_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const neffesGroupUpdateReducer = (
  state = { neffesGroupupdates: {} },
  action
) => {
  switch (action.type) {
    case NEFFES_GROUP_UPDATE_REQUEST:
      return { loading: true, success: false };

    case NEFFES_GROUP_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        neffesGroupupdates: action.payload,
      };

    case NEFFES_GROUP_UPDATE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};
