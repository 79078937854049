import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useGlobal } from "reactn";
import moment from "moment";
import "moment-timezone";

function UnitComment({ product }) {
  const [globalTimezone, setGlobalTimezone] = useGlobal("globalTimezone");
  const [timezone, setTimezone] = useState({});

  useEffect(() => {
    const timeZoneFromStorage = localStorage.getItem("timeZone")
      ? JSON.parse(localStorage.getItem("timeZone"))
      : null;

    if (
      timeZoneFromStorage != null &&
      timezone.value != timeZoneFromStorage.value
    ) {
      setTimezone(timeZoneFromStorage);
    }
  }, [globalTimezone]);

  const changeDatetimeByTimezone = (datetime, timezone) => {
    if (timezone == null) {
      timezone = "Europe/Moscow";
    }
    return moment.utc(datetime).tz(timezone).format();
  };

  return (
    <Card className="my-3 p-3 rounded h-100">
      <Card.Body className="centered-card">
        <Card.Img className="align-items-center" src={product.image} />
      </Card.Body>
      <Card.Footer>
        <Card.Text as="div">
          <div>
            <Card.Title as="div">
              <strong>
                {changeDatetimeByTimezone(
                  product.posted,
                  timezone.value
                ).substring(11, 19)}{" "}
                -{" "}
                {changeDatetimeByTimezone(
                  product.posted,
                  timezone.value
                ).substring(0, 10)}
              </strong>
            </Card.Title>
            <div className="my-3">{product.comment}</div>
            <div className="my-3">{product.uploaded_by}</div>
          </div>
        </Card.Text>
      </Card.Footer>
    </Card>
  );
}

export default UnitComment;
