import {
  USER_SENSOR_REQUEST,
  USER_SENSOR_SUCCESS,
  USER_SENSOR_FAIL,
  USER_SENSOR_RESET,
  ADMIN_SENSOR_REQUEST,
  ADMIN_SENSOR_SUCCESS,
  ADMIN_SENSOR_FAIL,
  ADMIN_SENSOR_RESET,
  SENSOR_DATA_REQUEST,
  SENSOR_DATA_SUCCESS,
  SENSOR_DATA_FAIL,
  SENSOR_DATA_BASIC_REQUEST,
  SENSOR_DATA_BASIC_SUCCESS,
  SENSOR_DATA_BASIC_FAIL,
  SENSOR_DATA_BASIC_UPDATE_REQUEST,
  SENSOR_DATA_BASIC_UPDATE_SUCCESS,
  SENSOR_DATA_BASIC_UPDATE_FAIL,
  SENSOR_UPDATE_REQUEST,
  SENSOR_UPDATE_SUCCESS,
  SENSOR_UPDATE_FAIL,
  USER_EIR_REQUEST,
  USER_EIR_SUCCESS,
  USER_EIR_FAIL,
  USER_EIR_RESET,
  ADMIN_EIR_REQUEST,
  ADMIN_EIR_SUCCESS,
  ADMIN_EIR_FAIL,
  ADMIN_EIR_RESET,
  EIR_DATA_REQUEST,
  EIR_DATA_SUCCESS,
  EIR_DATA_FAIL,
  EIR_UPDATE_REQUEST,
  EIR_UPDATE_SUCCESS,
  EIR_UPDATE_FAIL,
} from "../constants/deviceConstants";

export const userSensorReducer = (state = { sensors: {} }, action) => {
  switch (action.type) {
    case USER_SENSOR_REQUEST:
      return { loading: true, success: false };

    case USER_SENSOR_SUCCESS:
      return { loading: false, success: true, sensors: action.payload };

    case USER_SENSOR_FAIL:
      return { loading: false, success: true, error: action.payload };

    case USER_SENSOR_RESET:
      return { sensors: {} };

    default:
      return state;
  }
};

export const adminSensorReducer = (state = { sensors: {} }, action) => {
  switch (action.type) {
    case ADMIN_SENSOR_REQUEST:
      return { loading: true, success: false };

    case ADMIN_SENSOR_SUCCESS:
      return { loading: false, success: true, sensors: action.payload };

    case ADMIN_SENSOR_FAIL:
      return { loading: false, success: true, error: action.payload };

    case ADMIN_SENSOR_RESET:
      return { sensors: {} };

    default:
      return state;
  }
};

export const sensorDetailsReducer = (
  state = { sensorsdetails: {} },
  action
) => {
  switch (action.type) {
    case SENSOR_DATA_REQUEST:
      return { loading: true, success: false };

    case SENSOR_DATA_SUCCESS:
      return { loading: false, success: true, sensordetails: action.payload };

    case SENSOR_DATA_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const sensorUpdateReducer = (state = { sensorupdates: {} }, action) => {
  switch (action.type) {
    case SENSOR_UPDATE_REQUEST:
      return { loading: true, success: false };

    case SENSOR_UPDATE_SUCCESS:
      return { loading: false, success: true, sensorupdates: action.payload };

    case SENSOR_UPDATE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const sensorDetailsBasicReducer = (
  state = { sensordetailsbasic: {} },
  action
) => {
  switch (action.type) {
    case SENSOR_DATA_BASIC_REQUEST:
      return { loading: true, success: false };

    case SENSOR_DATA_BASIC_SUCCESS:
      return {
        loading: false,
        success: true,
        sensordetailsbasic: action.payload,
      };

    case SENSOR_DATA_BASIC_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const sensorDetailsBasicUpdateReducer = (
  state = { sensordetailsbasicupdate: {} },
  action
) => {
  switch (action.type) {
    case SENSOR_DATA_BASIC_UPDATE_REQUEST:
      return { loading: true, success: false };

    case SENSOR_DATA_BASIC_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        sensordetailsbasicupdate: action.payload,
      };

    case SENSOR_DATA_BASIC_UPDATE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const userEIRReducer = (state = { eirs: {} }, action) => {
  switch (action.type) {
    case USER_EIR_REQUEST:
      return { loading: true, success: false };

    case USER_EIR_SUCCESS:
      return { loading: false, success: true, eirs: action.payload };

    case USER_EIR_FAIL:
      return { loading: false, success: true, error: action.payload };

    case USER_EIR_RESET:
      return { eirs: {} };

    default:
      return state;
  }
};

export const adminEIRReducer = (state = { eirs: {} }, action) => {
  switch (action.type) {
    case ADMIN_EIR_REQUEST:
      return { loading: true, success: false };

    case ADMIN_EIR_SUCCESS:
      return { loading: false, success: true, eirs: action.payload };

    case ADMIN_EIR_FAIL:
      return { loading: false, success: true, error: action.payload };

    case ADMIN_EIR_RESET:
      return { eirs: {} };

    default:
      return state;
  }
};

export const eirDetailsReducer = (state = { eirdetails: {} }, action) => {
  switch (action.type) {
    case EIR_DATA_REQUEST:
      return { loading: true, success: false };

    case EIR_DATA_SUCCESS:
      return { loading: false, success: true, eirdetails: action.payload };

    case EIR_DATA_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const eirUpdateReducer = (state = { eirupdates: {} }, action) => {
  switch (action.type) {
    case EIR_UPDATE_REQUEST:
      return { loading: true, success: false };

    case EIR_UPDATE_SUCCESS:
      return { loading: false, success: true, eirupdates: action.payload };

    case EIR_UPDATE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};
