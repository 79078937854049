import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  userLoginReducer,
  userRegisterReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userDetailsReducer,
  userCustomerReducer,
  userRelatedDeviceReducer,
} from "./reducers/userReducers";

import {
  adminSensorReducer,
  userSensorReducer,
  sensorDetailsReducer,
  sensorUpdateReducer,
  sensorDetailsBasicReducer,
  sensorDetailsBasicUpdateReducer,
  adminEIRReducer,
  userEIRReducer,
  eirDetailsReducer,
  eirUpdateReducer,
} from "./reducers/deviceReducers";

import {
  adminGatewayReducer,
  userGatewayReducer,
  gatewayDetailsReducer,
  gatewayUpdateReducer,
  gatewayDiagramReducer,
  gatewayDiagramUpdateReducer,
  gatewayGroupReducer,
  gatewayGroupUpdateReducer,
  adminBoilerReducer,
  userBoilerReducer,
  boilerErrorsReducer,
} from "./reducers/gatewayReducers";

import {
  complexDetailsReducer,
  complexUpdateReducer,
  co2DetailsReducer,
  co2UpdateReducer,
} from "./reducers/demoReducers";

import {
  userNeffesReducer,
  adminNeffesReducer,
  neffesDetailsReducer,
  neffesUpdateReducer,
  userNeffesGroupReducer,
  adminNeffesGroupReducer,
  neffesGroupDetailsReducer,
  neffesGroupUpdateReducer,
} from "./reducers/neffesReducers";

import {
  productsReducer,
  productDetailsReducer,
} from "./reducers/productReducers";

import {
  adminProjectReducer,
  unitPictureReducer,
  unitReducer,
  userProjectReducer,
} from "./reducers/serviceReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userDetails: userDetailsReducer,
  userCustomer: userCustomerReducer,
  userRelatedDevice: userRelatedDeviceReducer,

  adminSensor: adminSensorReducer,
  userSensor: userSensorReducer,
  sensorDetails: sensorDetailsReducer,
  sensorUpdate: sensorUpdateReducer,
  adminEIR: adminEIRReducer,
  userEIR: userEIRReducer,
  detailsEIR: eirDetailsReducer,
  updatesEIR: eirUpdateReducer,
  sensorBasicDetails: sensorDetailsBasicReducer,
  sensorBasicUpdates: sensorDetailsBasicUpdateReducer,

  adminGateway: adminGatewayReducer,
  userGateway: userGatewayReducer,
  gatewayDetails: gatewayDetailsReducer,
  gatewayUpdate: gatewayUpdateReducer,
  gatewayDiagram: gatewayDiagramReducer,
  gatewayDiagramUpdate: gatewayDiagramUpdateReducer,
  gatewayGroup: gatewayGroupReducer,
  gatewayGroupUpdate: gatewayGroupUpdateReducer,
  adminBoiler: adminBoilerReducer,
  userBoiler: userBoilerReducer,
  boilerErrors: boilerErrorsReducer,

  userNeffes: userNeffesReducer,
  adminNeffes: adminNeffesReducer,
  neffesDetails: neffesDetailsReducer,
  neffesUpdate: neffesUpdateReducer,
  userNeffesGroup: userNeffesGroupReducer,
  adminNeffesGroup: adminNeffesGroupReducer,
  neffesGroupDetails: neffesGroupDetailsReducer,
  neffesGroupUpdate: neffesGroupUpdateReducer,

  complexDetails: complexDetailsReducer,
  complexUpdates: complexUpdateReducer,
  co2Details: co2DetailsReducer,
  co2Updates: co2UpdateReducer,

  adminProjects: adminProjectReducer,
  userProjects: userProjectReducer,
  units: unitReducer,
  unitPictures: unitPictureReducer,

  productsState: productsReducer,
  productDetailsState: productDetailsReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
