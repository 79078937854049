import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { getUserProjects } from "../../actions/serviceActions";
import { useTranslation } from "react-i18next";

function UserProjectsScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userProjects = useSelector((state) => state.userProjects);
  const { loading, error, success, projects } = userProjects;

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserProjects());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  return (
    <div>
      <Row className="align-items-center">
        <Col>
          <h1>{t("projects")}</h1>
        </Col>
      </Row>
      {!success ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped responsive hover className="table-sm">
          <thead>
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">{t("name")}</th>
              <th className="text-center">{t("comp_name")}</th>
              <th className="text-center">{t("details")}</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((sensor) => (
              <tr key={sensor.id}>
                <td className="align-middle text-center">{sensor.id}</td>
                <td className="align-middle text-center">{sensor.name}</td>
                <td className="align-middle text-center">
                  {sensor.customer.name}
                </td>
                <td className="align-middle text-center">
                  <LinkContainer to={`/user/projects/${sensor.id}`}>
                    <Button
                      variant="transparent"
                      className="btn-sm"
                      style={{ color: "red" }}
                    >
                      <i className="fas fa-magnifying-glass"></i>
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default UserProjectsScreen;
