import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Row, Col, Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Chart from "../../components/Chart";
import {
  getComplexDetails,
  getComplexUpdates,
} from "../../actions/demoActions";
import moment from "moment";
import "moment-timezone";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";

function ComplexSensorDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [data_1, setData_1] = useState([]);
  const [data_2, setData_2] = useState([]);
  const [data_3, setData_3] = useState([]);
  const [data_4, setData_4] = useState([]);
  const [data_5, setData_5] = useState([]);
  const [data_6, setData_6] = useState([]);
  const [data_7, setData_7] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [timezone, setTimezone] = useState({});
  const [globalTimezone, setGlobalTimezone] = useGlobal("globalTimezone");

  useEffect(() => {
    const timeZoneFromStorage = localStorage.getItem("timeZone")
      ? JSON.parse(localStorage.getItem("timeZone"))
      : null;

    if (
      timeZoneFromStorage != null &&
      timezone.value != timeZoneFromStorage.value
    ) {
      setTimezone(timeZoneFromStorage);
    }
  }, [globalTimezone]);

  const changeDatetimeByTimezone = (datetime, timezone) => {
    if (timezone == null) {
      timezone = "Europe/Moscow";
    }
    return moment.utc(datetime).tz(timezone).format();
  };

  const complexDetails = useSelector((state) => state.complexDetails);
  const { loading, error, success, complexdetails } = complexDetails;

  const complexUpdates = useSelector((state) => state.complexUpdates);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    complexupdates,
  } = complexUpdates;

  useEffect(() => {
    if (updateSuccess) {
      setUpdateData(complexupdates);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (success) {
      setUpdateData(complexdetails);
      setUpdating(true);
    }
  }, [success]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getComplexUpdates());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(getComplexDetails());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      let data_1 = [];
      let data_2 = [];
      let data_3 = [];
      let data_4 = [];
      let data_5 = [];
      let data_6 = [];
      let data_7 = [];
      complexdetails.chart_data.forEach((data) => {
        let obj_1 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          pm_1: data.data_1,
          pm_25: data.data_2,
          pm_10: data.data_3,
        };
        let obj_2 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          co2: data.data_4,
        };
        let obj_3 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          voc: data.data_5,
        };
        let obj_4 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          sıcaklık: data.data_6 / 10,
        };
        let obj_5 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          nem: data.data_7,
        };
        let obj_6 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          ch2o: data.data_8 / 1000,
        };
        let obj_7 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          co: data.data_9 / 10,
          o3: data.data_10 / 100,
          no: data.data_11 / 100,
        };
        data_1.push(obj_1);
        data_2.push(obj_2);
        data_3.push(obj_3);
        data_4.push(obj_4);
        data_5.push(obj_5);
        data_6.push(obj_6);
        data_7.push(obj_7);
      });
      setData_1(data_1.reverse());
      setData_2(data_2.reverse());
      setData_3(data_3.reverse());
      setData_4(data_4.reverse());
      setData_5(data_5.reverse());
      setData_6(data_6.reverse());
      setData_7(data_7.reverse());
    }
  }, [success, complexdetails, timezone]);

  useEffect(() => {
    if (updateSuccess) {
      let data_1 = [];
      let data_2 = [];
      let data_3 = [];
      let data_4 = [];
      let data_5 = [];
      let data_6 = [];
      let data_7 = [];
      complexupdates.chart_data.forEach((data) => {
        let obj_1 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          pm_1: data.data_1,
          pm_25: data.data_2,
          pm_10: data.data_3,
        };
        let obj_2 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          co2: data.data_4,
        };
        let obj_3 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          voc: data.data_5,
        };
        let obj_4 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          sıcaklık: data.data_6 / 10,
        };
        let obj_5 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          nem: data.data_7,
        };
        let obj_6 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          ch2o: data.data_8 / 1000,
        };
        let obj_7 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          co: data.data_9 / 10,
          o3: data.data_10 / 100,
          no: data.data_11 / 100,
        };
        data_1.push(obj_1);
        data_2.push(obj_2);
        data_3.push(obj_3);
        data_4.push(obj_4);
        data_5.push(obj_5);
        data_6.push(obj_6);
        data_7.push(obj_7);
      });
      setData_1(data_1.reverse());
      setData_2(data_2.reverse());
      setData_3(data_3.reverse());
      setData_4(data_4.reverse());
      setData_5(data_5.reverse());
      setData_6(data_6.reverse());
      setData_7(data_7.reverse());
    }
  }, [updateSuccess, complexupdates, timezone]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        success && (
          <>
            <h1>{t("demo_complex_sensor")}</h1>
            <Row className="justify-content-md-center">
              <Carousel
                variant="dark"
                pause="hover"
                className="w-100 chartcarousel"
              >
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={12} lg={6}>
                    <Chart data={data_1} type={1}></Chart>
                    <Chart data={data_2} type={2}></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={12} lg={6}>
                    <Chart data={data_3} type={6}></Chart>
                    <Chart data={data_4} type={3}></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center">
                  <Col xs={12} lg={6}>
                    <Chart data={data_5} type={4}></Chart>
                    <Chart data={data_6} type={7}></Chart>
                  </Col>
                </Carousel.Item>
                <Carousel.Item className="justify-content-md-center align-items-md-center">
                  <Col xs={12} lg={6}>
                    <Chart data={data_7} type={8}></Chart>
                  </Col>
                </Carousel.Item>
              </Carousel>
            </Row>

            <Table striped responsive hover className="table-sm">
              <thead>
                <tr>
                  <th className="text-center">PM 1</th>
                  <th className="text-center">PM 2.5</th>
                  <th className="text-center">PM 10</th>
                  <th className="text-center">CO2</th>
                  <th className="text-center">VOC</th>
                  <th className="text-center">Sıcaklık</th>
                  <th className="text-center">Nem</th>
                  <th className="text-center">CH2O</th>
                  <th className="text-center">CO</th>
                  <th className="text-center">O3</th>
                  <th className="text-center">NO</th>
                  <th className="text-center">{t("time_date")}</th>
                </tr>
              </thead>
              <tbody>
                {updating &&
                  updateData.table_data.map((data) => (
                    <tr key={data.id}>
                      <td className="align-middle text-center">
                        {data.data_1} µg/m³
                      </td>
                      <td className="align-middle text-center">
                        {data.data_2} µg/m³
                      </td>
                      <td className="align-middle text-center">
                        {data.data_3} µg/m³
                      </td>
                      <td className="align-middle text-center">
                        {data.data_4} ppm
                      </td>
                      <td className="align-middle text-center">
                        {data.data_5}
                      </td>
                      <td className="align-middle text-center">
                        {data.data_6 / 10} °C
                      </td>
                      <td className="align-middle text-center">
                        {data.data_7} %rH
                      </td>
                      <td className="align-middle text-center">
                        {data.data_8 / 1000} mg/m³
                      </td>
                      <td className="align-middle text-center">
                        {data.data_9 / 10} ppm
                      </td>
                      <td className="align-middle text-center">
                        {data.data_10 / 100} ppm
                      </td>
                      <td className="align-middle text-center">
                        {data.data_11 / 100} ppm
                      </td>
                      <td className="align-middle text-center">
                        {changeDatetimeByTimezone(
                          data.posted,
                          timezone.value
                        ).substring(11, 19)}{" "}
                        -{" "}
                        {changeDatetimeByTimezone(
                          data.posted,
                          timezone.value
                        ).substring(0, 10)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        )
      )}
    </div>
  );
}

export default ComplexSensorDetails;
