import {
  USER_SENSOR_REQUEST,
  USER_SENSOR_SUCCESS,
  USER_SENSOR_FAIL,
  USER_SENSOR_RESET,
  ADMIN_SENSOR_REQUEST,
  ADMIN_SENSOR_SUCCESS,
  ADMIN_SENSOR_FAIL,
  ADMIN_SENSOR_RESET,
  SENSOR_DATA_REQUEST,
  SENSOR_DATA_SUCCESS,
  SENSOR_DATA_FAIL,
  SENSOR_DATA_BASIC_REQUEST,
  SENSOR_DATA_BASIC_SUCCESS,
  SENSOR_DATA_BASIC_FAIL,
  SENSOR_DATA_BASIC_UPDATE_REQUEST,
  SENSOR_DATA_BASIC_UPDATE_SUCCESS,
  SENSOR_DATA_BASIC_UPDATE_FAIL,
  SENSOR_UPDATE_REQUEST,
  SENSOR_UPDATE_SUCCESS,
  SENSOR_UPDATE_FAIL,
  USER_EIR_REQUEST,
  USER_EIR_SUCCESS,
  USER_EIR_FAIL,
  USER_EIR_RESET,
  ADMIN_EIR_REQUEST,
  ADMIN_EIR_SUCCESS,
  ADMIN_EIR_FAIL,
  ADMIN_EIR_RESET,
  EIR_DATA_REQUEST,
  EIR_DATA_SUCCESS,
  EIR_DATA_FAIL,
  EIR_UPDATE_REQUEST,
  EIR_UPDATE_SUCCESS,
  EIR_UPDATE_FAIL,
} from "../constants/deviceConstants";
import axios from "axios";

export const getAdminSensors = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_SENSOR_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/devices/sensors-admin/`, config);

    dispatch({
      type: ADMIN_SENSOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_SENSOR_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserSensors = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_SENSOR_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/devices/sensors-user/`, config);

    dispatch({
      type: USER_SENSOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_SENSOR_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSensorDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SENSOR_DATA_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/devices/${id}/details/`, config);

    dispatch({
      type: SENSOR_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SENSOR_DATA_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSensorUpdates = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SENSOR_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/devices/${id}/details/`, config);

    dispatch({
      type: SENSOR_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SENSOR_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSensorDetailsBasic = (mac) => async (dispatch) => {
  try {
    dispatch({
      type: SENSOR_DATA_BASIC_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/devices/${mac}/basic/`, config);

    dispatch({
      type: SENSOR_DATA_BASIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SENSOR_DATA_BASIC_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSensorDetailsBasicUpdates = (mac) => async (dispatch) => {
  try {
    dispatch({
      type: SENSOR_DATA_BASIC_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/devices/${mac}/basic/`, config);

    dispatch({
      type: SENSOR_DATA_BASIC_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SENSOR_DATA_BASIC_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getAdminEIRs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_EIR_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/devices/eir-admin/`, config);

    dispatch({
      type: ADMIN_EIR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_EIR_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserEIRs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_EIR_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/devices/eir-user/`, config);

    dispatch({
      type: USER_EIR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_EIR_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getEIRDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EIR_DATA_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/devices/eir/${id}/details/`, config);

    dispatch({
      type: EIR_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EIR_DATA_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getEIRUpdates = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EIR_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/devices/eir/${id}/details/`, config);

    dispatch({
      type: EIR_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EIR_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
