import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../actions/userActions";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { setGlobal, useGlobal } from "reactn";
import { getUserRelatedDevices } from "../actions/userActions";
import Loader from "../components/Loader";

//import SearchBox from "./SearchBox";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { t } = useTranslation();

  const [language, setLanguage] = useGlobal("language");

  const handleOnclick = (e) => {
    e.preventDefault();
    let newLang = e.target.getAttribute("value");
    setLanguage(newLang);
    localStorage.setItem("language", JSON.stringify(newLang));
    i18n.changeLanguage(newLang);
  };

  useEffect(() => {
    const languageFromStorage = localStorage.getItem("language")
      ? JSON.parse(localStorage.getItem("language"))
      : null;

    if (languageFromStorage != null) {
      setLanguage(languageFromStorage);
      i18n.changeLanguage(languageFromStorage);
    }
  }, []);

  const userRelatedDevices = useSelector((state) => state.userRelatedDevice);
  const { loading, success, related_devices, error } = userRelatedDevices;

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserRelatedDevices());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if(error){
      dispatch(logout());
      navigate("/login");
    }
  }, [dispatch, error])

  const logoutHandler = () => {
    dispatch(logout());
  };
  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                alt=""
                src="/images/logo.png"
                width="175"
                height="90"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {/*            <SearchBox products_main_page={true} /> */}
            <Nav className="mr-auto ms-auto">
              {userInfo && userInfo.is_staff && success && !error && (
                <NavDropdown title={t("admin_menu")} id="staffmenu">
                  <LinkContainer to="/staff/users">
                    <NavDropdown.Item>{t("users")}</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to="/staff/sensors">
                    <NavDropdown.Item>{t("sensors")}</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to="/staff/eir-devices">
                    <NavDropdown.Item>{t("eir")}</NavDropdown.Item>
                  </LinkContainer>
                  {/*<Dropdown>
                    <Dropdown.Toggle className="dropdown-item nested-dropdown">
                      Neffes
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <LinkContainer to="/user/neffes-devices">
                        <NavDropdown.Item>Neffes Cihazlarım</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/user/neffes-groups">
                        <NavDropdown.Item>Neffes Gruplarım</NavDropdown.Item>
                      </LinkContainer>
                    </Dropdown.Menu>
                  </Dropdown>*/}
                  <NavDropdown.Divider />
                  {/*<Dropdown>
                    <Dropdown.Toggle className="dropdown-item nested-dropdown">
                      EIR
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <LinkContainer to="/user/eir">
                        <NavDropdown.Item>EIR Cihazlarım</NavDropdown.Item>
                      </LinkContainer>
                    </Dropdown.Menu>
                  </Dropdown>*/}
                  <LinkContainer to="/staff/neffes-devices">
                    <NavDropdown.Item>{t("neffes")}</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to="/staff/gateways">
                    <NavDropdown.Item>{t("ahu_monitoring")}</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to="/staff/boilers">
                    <NavDropdown.Item>{t("boiler")}</NavDropdown.Item>
                  </LinkContainer>
                  {false && (
                    <>
                      <NavDropdown.Divider />
                      <LinkContainer to="/staff/projects">
                        <NavDropdown.Item>{t("service")}</NavDropdown.Item>
                      </LinkContainer>
                    </>
                  )}
                </NavDropdown>
              )}
              {userInfo && success && !error && (
                <NavDropdown title={t("user_menu")} id="usermenu">
                  {related_devices.sensor && (
                    <>
                      <LinkContainer to="/user/sensors">
                        <NavDropdown.Item>{t("sensors")}</NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider />
                    </>
                  )}
                  {related_devices.eir && (
                    <>
                      <LinkContainer to="/user/eir-devices">
                        <NavDropdown.Item>{t("eir")}</NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider />
                    </>
                  )}
                  {/*<Dropdown>
                                    <Dropdown.Toggle className="dropdown-item nested-dropdown">
                                      Neffes
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <LinkContainer to="/user/neffes-devices">
                                        <NavDropdown.Item>Neffes Cihazlarım</NavDropdown.Item>
                                      </LinkContainer>
                                      <LinkContainer to="/user/neffes-groups">
                                        <NavDropdown.Item>Neffes Gruplarım</NavDropdown.Item>
                                      </LinkContainer>
                                    </Dropdown.Menu>
                                  </Dropdown>*/}
                  {/*<Dropdown>
                                    <Dropdown.Toggle className="dropdown-item nested-dropdown">
                                      EIR
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <LinkContainer to="/user/eir">
                                        <NavDropdown.Item>EIR Cihazlarım</NavDropdown.Item>
                                      </LinkContainer>
                                    </Dropdown.Menu>
                                  </Dropdown>*/}
                  {related_devices.neffes && (
                    <>
                      <LinkContainer to="/user/neffes-devices">
                        <NavDropdown.Item>{t("neffes")}</NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider />
                    </>
                  )}
                  {related_devices.ahu && (
                    <>
                      <LinkContainer to="/user/gateways">
                        <NavDropdown.Item>
                          {t("ahu_monitoring")}
                        </NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider />
                    </>
                  )}
                  {related_devices.boiler && (
                    <>
                      <LinkContainer to="/user/boilers">
                        <NavDropdown.Item>{t("boiler")}</NavDropdown.Item>
                      </LinkContainer>
                    </>
                  )}
                  {false && (
                    <>
                      <NavDropdown.Divider />
                      <LinkContainer to="/user/projects">
                        <NavDropdown.Item>{t("service")}</NavDropdown.Item>
                      </LinkContainer>
                    </>
                  )}
                </NavDropdown>
              )}

              {/*
              <div className="nav-item dropdown">
                <LinkContainer to="/products" style={{ color: "#a3a6a9" }}>
                  <div
                    aria-expanded="false"
                    role="button"
                    className="nav-link"
                    tabIndex="0"
                  >
                    {t("products")}
                  </div>
                </LinkContainer>
              </div>

              <NavDropdown title={t("demo_menu")} id="demomenu">
                <LinkContainer to="/demo/co2-sensor">
                  <NavDropdown.Item>{t("co2_demo")}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/demo/complex-sensor">
                  <NavDropdown.Item>{t("complex_demo")}</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              */}

              {userInfo ? (
                <NavDropdown title={userInfo.first_name} id="username">
                  <NavDropdown.Item onClick={logoutHandler}>
                    <i className="fas fa-door-open"></i>
                    {t("logout")}
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <NavDropdown title={t("loginmenu")} id="login">
                  <LinkContainer to="/login">
                    <NavDropdown.Item>
                      <i className="fas fa-user"></i>
                      {t("login")}
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              <NavDropdown title={t("language_selector")} id="langmenu">
                <NavDropdown.Item onClick={handleOnclick}>
                  <img
                    value="tr"
                    alt="Türkiye"
                    src="http://purecatamphetamine.github.io/country-flag-icons/3x2/TR.svg"
                  />
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleOnclick}>
                  <img
                    value="en"
                    alt="Great Britian"
                    src="http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg"
                  />
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
