export const TRANSLATIONS_TR = {
  //HEADER STUFF
  admin_menu: "Admİn Menüsü",
  user_menu: "Kullanıcı Menüsü",
  demo_menu: "Demo Cİhazlar",

  complex_demo: "Karma Sensör",
  co2_demo: "CO2 Sensörü",
  users: "Kullanıcılar",
  sensors: "Sensörler",
  eir: "EIR",
  neffes: "Neffes",
  ahu_monitoring: "Santral İzleme",
  products: "Ürünler",

  loginmenu: "Kullanıcı",
  logout: "Çıkış Yap",
  login: "GİRİŞ YAP",
  service: "Servis",

  language_selector: "CHANGE LANGUAGE",

  demo_complex_sensor: "Demo Karma Sensör",
  demo_co2_sensor: "Demo CO2 Sensör",

  boiler: "Kazanlar",

  //FOOTER STUFF
  footer: "Telif Hakkı ©Apex Otomasyon'a Aittir",

  //USER SCREENS
  //LOGIN
  username: "Kullanıcı Adı",
  password: "Şifre",
  enter_username: "Kullanıcı Adı Girin",
  enter_password: "Şifre Girin",
  login_button: "Giriş Yap",
  new_user: "Yeni Kullanıcı Mısınız?",
  signup: "Buraya Tıklayın",

  //REGISTER
  register: "ÜYE OL",
  first_name: "Ad",
  enter_first_name: "Lütfen adınız girin",
  last_name: "Soyad",
  enter_last_name: "Lütfen soyadınızı girin",
  enter_user_name: "Kullanıcı adı girin",
  email: "E-Mail",
  enter_email: "Lütfen e-mail adresinizi girin",
  confirm_password: "Şifrenizi Tekrarlayın",
  enter_confirm_password: "Şifrenizi tekrarlayın",
  already_user: "Zaten kullanıcı mısınız?",
  password_dont_match: "Şifreler birbirinden farklı",

  //USERS SCREEN
  user_delete_check: "Bu Kullanıcıyı Silmek İstediğinizden Emin Misiniz?",
  name: "İsim",
  comp_name: "Fİrma",
  is_admin: "YÖNETİCİ",
  edit: "DEĞİŞTİR",
  delete: "SİL",
  create_new_comp: "Yenİ Fİrma Oluştur",
  enter_comp_name: "Lütfen Firma Adı Girin",
  create: "Oluştur",

  //EDIT USER SCREEN
  company: "Firma",

  //SENSORS SCREENS
  //ADMIN SENSORS SCREEN
  create_sensor: "Yeni Sensör Oluştur",
  mac_id: "MAC ID",
  details: "Detaylar",
  status: "Durum",

  //ADMIN EDIT SENSOR SCREEN
  sensor_details: "Sensör Detayları",
  device_delete: "Bu sensörü silmek istediğinizden emin misiniz?",
  enter_name: "Lütfen isim girin",
  enter_device_id: "Lütfen cihaz id'si girin",
  save: "Kaydet",
  device_id: "Cihaz ID'si",

  //ADMIN SENSOR DETAILS SCREEN
  temperature: "Sıcaklık",
  humidity: "Nem",
  time_date: "Saat-Tarih",

  //EIR SCREENS
  //ADMIN EIR SCREEN
  create_eir_device: "EIR Cihazı Oluştur",

  //ADMIN EDIT EIR SCREEN
  eir_details: "EIR Detayları",

  //ADMIN EIR DETAILS SCREEN
  eir_state: "Cihaz Durumu",
  hepa_drop: "Hepa Basınç Kaybı",
  g3_drop: "G3 Basınç Kaybı",
  hepa_state: "Hepa Durumu",
  g3_state: "G3 Durumu",
  work_hours: "Çalışma Saati",

  //NEFFES SCREENS
  //ADMIN NEFFES SCREEN
  neffes_devices: "Neffes Cihazları",
  create_neffes_device: "Yeni Neffes Oluştur",

  //ADMIN NEFFES EDIT SCREEN
  neffes_details: "Neffes Detayları",
  neffes_delete: "Bu Neffes'i silmek istediğinizden emin misiniz?",

  //ADMIN NEFFES DETAILS SCREEN
  web_override: "Web Kontrol",
  web_power: "Web On-Off",
  web_uvc: "Web UVC",
  power: "GÜÇ",
  uvc: "UVC",
  fan_speed: "Fan Hızı",
  uvc_hours: "UVC Saati",

  //ADMIN CREATE NEFFES SCREEN
  create_neffes: "Neffes Oluştur",

  //GATEWAY SCREENS
  //ADMIN GATEWAYS SCREEN
  gateway_devices: "İzleme Cihazları",
  create_gateway_device: "Yeni İzleme Cihazı Oluştur",

  //ADMIN EDIT GATEWAY SCREEN
  gateway_details: "İzleme Cihazı Detayları",

  //ADMIN GATEWAY DIAGRAM SCREEN
  empty: "Boş",
  default: "Varsayılan",
  detailed_view: "Detaylı Görünüm",
  latest_date: "GRAFİKTEKİ SON TARİH",

  //PRODUCT SCREENS
  general: "Genel Bilgiler",
  documents: "Dökümanlar",
  tr_download: "Türkçe Datasheet İndirmek İçin Tıklayın",
  en_download: "İngilizce Datasheet İndirmek İçin Tıklayın",

  //SERVICE SCREENS
  projects: "Projeler",
  create_project: "Proje Oluştur",
  units: "Santraller",
  create_unit: "Santral Oluştur",
  comments: "Yorumlar",
  create_comment: "Yorum Oluştur",
  new_picture: "Yeni Yorum",
  picture: "Resim",
  comment: "Yorum",
};
