import {
  COMPLEX_DATA_REQUEST,
  COMPLEX_DATA_SUCCESS,
  COMPLEX_DATA_FAIL,
  COMPLEX_UPDATE_REQUEST,
  COMPLEX_UPDATE_SUCCESS,
  COMPLEX_UPDATE_FAIL,
  CO2_DATA_REQUEST,
  CO2_DATA_SUCCESS,
  CO2_DATA_FAIL,
  CO2_UPDATE_REQUEST,
  CO2_UPDATE_SUCCESS,
  CO2_UPDATE_FAIL,
} from "../constants/demoConstants";

export const complexDetailsReducer = (
  state = { complexdetails: {} },
  action
) => {
  switch (action.type) {
    case COMPLEX_DATA_REQUEST:
      return { loading: true, success: false };

    case COMPLEX_DATA_SUCCESS:
      return { loading: false, success: true, complexdetails: action.payload };

    case COMPLEX_DATA_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const complexUpdateReducer = (
  state = { complexupdates: {} },
  action
) => {
  switch (action.type) {
    case COMPLEX_UPDATE_REQUEST:
      return { loading: true, success: false };

    case COMPLEX_UPDATE_SUCCESS:
      return { loading: false, success: true, complexupdates: action.payload };

    case COMPLEX_UPDATE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const co2DetailsReducer = (state = { co2details: {} }, action) => {
  switch (action.type) {
    case CO2_DATA_REQUEST:
      return { loading: true, success: false };

    case CO2_DATA_SUCCESS:
      return { loading: false, success: true, co2details: action.payload };

    case CO2_DATA_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const co2UpdateReducer = (state = { co2updates: {} }, action) => {
  switch (action.type) {
    case CO2_UPDATE_REQUEST:
      return { loading: true, success: false };

    case CO2_UPDATE_SUCCESS:
      return { loading: false, success: true, co2updates: action.payload };

    case CO2_UPDATE_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};
