export const COMPLEX_DATA_REQUEST = "COMPLEX_DATA_REQUEST";
export const COMPLEX_DATA_SUCCESS = "COMPLEX_DATA_SUCCESS";
export const COMPLEX_DATA_FAIL = "COMPLEX_DATA_FAIL";

export const COMPLEX_UPDATE_REQUEST = "COMPLEX_UPDATE_REQUEST";
export const COMPLEX_UPDATE_SUCCESS = "COMPLEX_UPDATE_SUCCESS";
export const COMPLEX_UPDATE_FAIL = "COMPLEX_UPDATE_FAIL";

export const CO2_DATA_REQUEST = "CO2_DATA_REQUEST";
export const CO2_DATA_SUCCESS = "CO2_DATA_SUCCESS";
export const CO2_DATA_FAIL = "CO2_DATA_FAIL";

export const CO2_UPDATE_REQUEST = "CO2_UPDATE_REQUEST";
export const CO2_UPDATE_SUCCESS = "CO2_UPDATE_SUCCESS";
export const CO2_UPDATE_FAIL = "CO2_UPDATE_FAIL";
