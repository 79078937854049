import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Form, Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { listUsers, deleteUser } from "../../actions/userActions";
import FormContainer from "../../components/FormContainer";
import { useTranslation } from "react-i18next";

function UsersScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [customer, setCustomer] = useState("");
  const { t } = useTranslation();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete, error: errorDelete } = userDelete;

  useEffect(() => {
    if (userInfo && userInfo.is_staff) {
      dispatch(listUsers());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo, successDelete]);

  const deleteHandler = (id) => {
    if (window.confirm(t("user_delete_check"))) {
      dispatch(deleteUser(id));
    }
  };

  const createCustomer = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        "/api/users/customercreate/",
        customer,
        config
      );
    } catch (error) {}
  };

  return (
    <div>
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      <h1>{t("users")}</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped responsive hover className="table-sm">
          <thead>
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">{t("name")}</th>
              <th className="text-center">{t("username")}</th>
              <th className="text-center">{t("email")}</th>
              <th className="text-center">{t("comp_name")}</th>
              <th className="text-center">{t("is_admin")}</th>
              <th className="text-center">{t("edit")}</th>
              <th className="text-center">{t("delete")}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="align-middle text-center">{user.id}</td>
                <td className="align-middle text-center">{user.first_name}</td>
                <td className="align-middle text-center">{user.username}</td>
                <td className="align-middle text-center">{user.email}</td>
                <td className="align-middle text-center">
                  {user.customer && user.customer.name}
                </td>
                <td className="align-middle text-center">
                  {user.is_staff ? (
                    <i className="fas fa-check" style={{ color: "green" }}>
                      {" "}
                    </i>
                  ) : (
                    <i className="fas fa-times" style={{ color: "red" }}>
                      {" "}
                    </i>
                  )}
                </td>
                <td className="align-middle text-center">
                  <LinkContainer to={`/staff/user/${user.id}/edit`}>
                    <Button
                      variant="transparent"
                      className="btn-sm"
                      style={{ color: "red" }}
                    >
                      <i className="fas fa-edit"></i>
                    </Button>
                  </LinkContainer>
                </td>
                <td className="align-middle text-center">
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(user.id)}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </td>
                <td className="align-middle text-center"></td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <FormContainer>
        <h4>{t("create_new_comp")}</h4>
        <Form onSubmit={createCustomer}>
          <Form.Group controlId="text">
            <Form.Label>{t("comp_name")}:</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("enter_comp_name")}
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Button className="mt-2" type="submit" variant="primary">
            {t("create")}
          </Button>
        </Form>
      </FormContainer>
    </div>
  );
}

export default UsersScreen;
