import {
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
} from "../constants/productConstants";

export const productsReducer = (state = { products: {} }, action) => {
  switch (action.type) {
    case PRODUCTS_REQUEST:
      return { loading: true, success: false };

    case PRODUCTS_SUCCESS:
      return { loading: false, success: true, products: action.payload };

    case PRODUCTS_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};

export const productDetailsReducer = (
  state = { productDetails: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true, success: false };

    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, success: true, productDetails: action.payload };

    case PRODUCT_DETAILS_FAIL:
      return { loading: false, success: true, error: action.payload };

    default:
      return state;
  }
};
