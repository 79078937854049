import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getGatewayDiagram,
  getGatewayDiagramUpdates,
} from "../../actions/gatewayActions";
import Loader from "../../components/Loader";
import moment from "moment";
import "moment-timezone";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";
import ReactFlow, { useNodesState, useEdgesState } from "react-flow-renderer";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Row, Col } from "react-bootstrap";
import FlowChart from "../../components/FlowChart";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";

function UserGatewayDiagramScreen() {
  const params = useParams();
  const productId = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const bottom_y = 240;
  const top_y = -80;

  const [globalTimezone, setGlobalTimezone] = useGlobal("globalTimezone");

  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [nodes, setNodes] = useNodesState();
  const [edges, setEdges] = useEdgesState();
  const [nodes2, setNodes2] = useNodesState();
  const [edges2, setEdges2] = useEdgesState();
  const [nodes3, setNodes3] = useNodesState();
  const [edges3, setEdges3] = useEdgesState();
  const [timezone, setTimezone] = useState({});
  const [dateSelected, setDateSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [dateString, setDateString] = useState("");
  const [excelCreation, setExcelCreation] = useState(false);

  useEffect(() => {
    const timeZoneFromStorage = localStorage.getItem("timeZone")
      ? JSON.parse(localStorage.getItem("timeZone"))
      : null;

    if (
      timeZoneFromStorage != null &&
      timezone.value != timeZoneFromStorage.value
    ) {
      setTimezone(timeZoneFromStorage);
    }
  }, [globalTimezone]);

  const changeDatetimeByTimezone = (datetime, timezone) => {
    if (timezone == null) {
      timezone = "Europe/Moscow";
    }
    return moment.utc(datetime).tz(timezone).format();
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const gatewayDiagram = useSelector((state) => state.gatewayDiagram);
  const { loading, error, success, gatewaydiagram } = gatewayDiagram;

  const gatewayDiagramUpdate = useSelector(
    (state) => state.gatewayDiagramUpdate
  );
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    gatewaydiagramupdates,
  } = gatewayDiagramUpdate;

  useEffect(() => {
    if (updateSuccess) {
      setUpdateData(gatewaydiagramupdates);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (success) {
      setUpdateData(gatewaydiagram);
      setUpdating(true);
    }
  }, [success]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userInfo) {
        dispatch(getGatewayDiagramUpdates(productId));
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userInfo) {
      dispatch(getGatewayDiagram(productId));
    } else if (!userInfo) {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo, productId]);

  const createObject = (data_point, data_text) => {
    let data_object = {};
    switch (data_point.length) {
      case 0:
        data_object["label"] = <div style={{ fontSize: 10 }}>{t("empty")}</div>;
        break;
      case 1:
        data_object["label"] = (
          <div>
            <div style={{ fontSize: 10 }}>{data_text[0]}</div>
            <div style={{ color: "red" }}>{data_point[0]}</div>
          </div>
        );
        break;
      case 2:
        data_object["label"] = (
          <div>
            <div style={{ fontSize: 10 }}>{data_text[0]}</div>
            <div style={{ color: "red" }}>{data_point[0]}</div>
            <div style={{ fontSize: 10 }}>{data_text[1]}</div>
            <div style={{ color: "red" }}>{data_point[1]}</div>
          </div>
        );
        break;
      case 3:
        data_object["label"] = (
          <div>
            <div style={{ fontSize: 10 }}>{data_text[0]}</div>
            <div style={{ color: "red" }}>{data_point[0]}</div>
            <div style={{ fontSize: 10 }}>{data_text[1]}</div>
            <div style={{ color: "red" }}>{data_point[1]}</div>
            <div style={{ fontSize: 10 }}>{data_text[2]}</div>
            <div style={{ color: "red" }}>{data_point[2]}</div>
          </div>
        );
        break;
      case 4:
        data_object["label"] = (
          <div>
            <div style={{ fontSize: 10 }}>{data_text[0]}</div>
            <div style={{ color: "red" }}>{data_point[0]}</div>
            <div style={{ fontSize: 10 }}>{data_text[1]}</div>
            <div style={{ color: "red" }}>{data_point[1]}</div>
            <div style={{ fontSize: 10 }}>{data_text[2]}</div>
            <div style={{ color: "red" }}>{data_point[2]}</div>
            <div style={{ fontSize: 10 }}>{data_text[3]}</div>
            <div style={{ color: "red" }}>{data_point[3]}</div>
          </div>
        );
        break;
      case 5:
        data_object["label"] = (
          <div>
            <div style={{ fontSize: 10 }}>{data_text[0]}</div>
            <div style={{ color: "red" }}>{data_point[0]}</div>
            <div style={{ fontSize: 10 }}>{data_text[1]}</div>
            <div style={{ color: "red" }}>{data_point[1]}</div>
            <div style={{ fontSize: 10 }}>{data_text[2]}</div>
            <div style={{ color: "red" }}>{data_point[2]}</div>
            <div style={{ fontSize: 10 }}>{data_text[3]}</div>
            <div style={{ color: "red" }}>{data_point[3]}</div>
            <div style={{ fontSize: 10 }}>{data_text[4]}</div>
            <div style={{ color: "red" }}>{data_point[4]}</div>
          </div>
        );
        break;
      case 6:
        data_object["label"] = (
          <div>
            <div style={{ fontSize: 10 }}>{data_text[0]}</div>
            <div style={{ color: "red" }}>{data_point[0]}</div>
            <div style={{ fontSize: 10 }}>{data_text[1]}</div>
            <div style={{ color: "red" }}>{data_point[1]}</div>
            <div style={{ fontSize: 10 }}>{data_text[2]}</div>
            <div style={{ color: "red" }}>{data_point[2]}</div>
            <div style={{ fontSize: 10 }}>{data_text[3]}</div>
            <div style={{ color: "red" }}>{data_point[3]}</div>
            <div style={{ fontSize: 10 }}>{data_text[4]}</div>
            <div style={{ color: "red" }}>{data_point[4]}</div>
            <div style={{ fontSize: 10 }}>{data_text[5]}</div>
            <div style={{ color: "red" }}>{data_point[5]}</div>
          </div>
        );
        break;
      case 7:
        data_object["label"] = (
          <div>
            <div style={{ fontSize: 10 }}>{data_text[0]}</div>
            <div style={{ color: "red" }}>{data_point[0]}</div>
            <div style={{ fontSize: 10 }}>{data_text[1]}</div>
            <div style={{ color: "red" }}>{data_point[1]}</div>
            <div style={{ fontSize: 10 }}>{data_text[2]}</div>
            <div style={{ color: "red" }}>{data_point[2]}</div>
            <div style={{ fontSize: 10 }}>{data_text[3]}</div>
            <div style={{ color: "red" }}>{data_point[3]}</div>
            <div style={{ fontSize: 10 }}>{data_text[4]}</div>
            <div style={{ color: "red" }}>{data_point[4]}</div>
            <div style={{ fontSize: 10 }}>{data_text[5]}</div>
            <div style={{ color: "red" }}>{data_point[5]}</div>
            <div style={{ fontSize: 10 }}>{data_text[6]}</div>
            <div style={{ color: "red" }}>{data_point[6]}</div>
          </div>
        );
        break;
      case 8:
        data_object["label"] = (
          <div>
            <div style={{ fontSize: 10 }}>{data_text[0]}</div>
            <div style={{ color: "red" }}>{data_point[0]}</div>
            <div style={{ fontSize: 10 }}>{data_text[1]}</div>
            <div style={{ color: "red" }}>{data_point[1]}</div>
            <div style={{ fontSize: 10 }}>{data_text[2]}</div>
            <div style={{ color: "red" }}>{data_point[2]}</div>
            <div style={{ fontSize: 10 }}>{data_text[3]}</div>
            <div style={{ color: "red" }}>{data_point[3]}</div>
            <div style={{ fontSize: 10 }}>{data_text[4]}</div>
            <div style={{ color: "red" }}>{data_point[4]}</div>
            <div style={{ fontSize: 10 }}>{data_text[5]}</div>
            <div style={{ color: "red" }}>{data_point[5]}</div>
            <div style={{ fontSize: 10 }}>{data_text[6]}</div>
            <div style={{ color: "red" }}>{data_point[6]}</div>
            <div style={{ fontSize: 10 }}>{data_text[7]}</div>
            <div style={{ color: "red" }}>{data_point[7]}</div>
          </div>
        );
        break;
      case 9:
        data_object["label"] = (
          <div>
            <div style={{ fontSize: 10 }}>{data_text[0]}</div>
            <div style={{ color: "red" }}>{data_point[0]}</div>
            <div style={{ fontSize: 10 }}>{data_text[1]}</div>
            <div style={{ color: "red" }}>{data_point[1]}</div>
            <div style={{ fontSize: 10 }}>{data_text[2]}</div>
            <div style={{ color: "red" }}>{data_point[2]}</div>
            <div style={{ fontSize: 10 }}>{data_text[3]}</div>
            <div style={{ color: "red" }}>{data_point[3]}</div>
            <div style={{ fontSize: 10 }}>{data_text[4]}</div>
            <div style={{ color: "red" }}>{data_point[4]}</div>
            <div style={{ fontSize: 10 }}>{data_text[5]}</div>
            <div style={{ color: "red" }}>{data_point[5]}</div>
            <div style={{ fontSize: 10 }}>{data_text[6]}</div>
            <div style={{ color: "red" }}>{data_point[6]}</div>
            <div style={{ fontSize: 10 }}>{data_text[7]}</div>
            <div style={{ color: "red" }}>{data_point[7]}</div>
            <div style={{ fontSize: 10 }}>{data_text[8]}</div>
            <div style={{ color: "red" }}>{data_point[8]}</div>
          </div>
        );
        break;
      case 10:
        data_object["label"] = (
          <div>
            <div style={{ fontSize: 10 }}>{data_text[0]}</div>
            <div style={{ color: "red" }}>{data_point[0]}</div>
            <div style={{ fontSize: 10 }}>{data_text[1]}</div>
            <div style={{ color: "red" }}>{data_point[1]}</div>
            <div style={{ fontSize: 10 }}>{data_text[2]}</div>
            <div style={{ color: "red" }}>{data_point[2]}</div>
            <div style={{ fontSize: 10 }}>{data_text[3]}</div>
            <div style={{ color: "red" }}>{data_point[3]}</div>
            <div style={{ fontSize: 10 }}>{data_text[4]}</div>
            <div style={{ color: "red" }}>{data_point[4]}</div>
            <div style={{ fontSize: 10 }}>{data_text[5]}</div>
            <div style={{ color: "red" }}>{data_point[5]}</div>
            <div style={{ fontSize: 10 }}>{data_text[6]}</div>
            <div style={{ color: "red" }}>{data_point[6]}</div>
            <div style={{ fontSize: 10 }}>{data_text[7]}</div>
            <div style={{ color: "red" }}>{data_point[7]}</div>
            <div style={{ fontSize: 10 }}>{data_text[8]}</div>
            <div style={{ color: "red" }}>{data_point[8]}</div>
            <div style={{ fontSize: 10 }}>{data_text[9]}</div>
            <div style={{ color: "red" }}>{data_point[9]}</div>
          </div>
        );
        break;
      default:
        data_object["label"] = (
          <div style={{ fontSize: 10 }}>{t("default")}</div>
        );
    }

    return data_object;
  };

  const createPoint = (data_arr, group_arr, unit_arr, name_arr, mult_arr) => {
    let text_point_1 = [];
    let text_point_2 = [];
    let text_point_3 = [];
    let text_point_4 = [];
    let text_point_5 = [];
    let text_point_6 = [];
    let text_point_7 = [];
    let text_point_8 = [];
    let text_point_9 = [];
    let text_point_10 = [];
    let text_point_11 = [];
    let text_point_12 = [];
    let text_point_13 = [];
    let text_point_14 = [];
    let text_point_15 = [];
    let text_point_16 = [];
    let text_point_17 = [];
    let text_point_18 = [];
    let text_point_19 = [];
    let text_point_20 = [];
    let text_point_21 = [];
    let text_point_22 = [];
    let text_point_23 = [];
    let text_point_24 = [];
    let text_point_25 = [];
    let text_point_26 = [];
    let text_point_27 = [];
    let text_point_28 = [];
    let text_point_29 = [];
    let text_point_30 = [];
    let data_point_1 = [];
    let data_point_2 = [];
    let data_point_3 = [];
    let data_point_4 = [];
    let data_point_5 = [];
    let data_point_6 = [];
    let data_point_7 = [];
    let data_point_8 = [];
    let data_point_9 = [];
    let data_point_10 = [];
    let data_point_11 = [];
    let data_point_12 = [];
    let data_point_13 = [];
    let data_point_14 = [];
    let data_point_15 = [];
    let data_point_16 = [];
    let data_point_17 = [];
    let data_point_18 = [];
    let data_point_19 = [];
    let data_point_20 = [];
    let data_point_21 = [];
    let data_point_22 = [];
    let data_point_23 = [];
    let data_point_24 = [];
    let data_point_25 = [];
    let data_point_26 = [];
    let data_point_27 = [];
    let data_point_28 = [];
    let data_point_29 = [];
    let data_point_30 = [];

    for (let i = 0; i < group_arr.length; i++) {
      if (group_arr[i] == 1) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_1.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_1.push(text_2);
      }
      if (group_arr[i] == 2) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_2.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_2.push(text_2);
      }
      if (group_arr[i] == 3) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_3.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_3.push(text_2);
      }
      if (group_arr[i] == 4) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_4.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_4.push(text_2);
      }
      if (group_arr[i] == 5) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_5.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_5.push(text_2);
      }
      if (group_arr[i] == 6) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_6.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_6.push(text_2);
      }
      if (group_arr[i] == 7) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_7.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_7.push(text_2);
      }
      if (group_arr[i] == 8) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_8.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_8.push(text_2);
      }
      if (group_arr[i] == 9) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_9.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_9.push(text_2);
      }
      if (group_arr[i] == 10) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_10.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_10.push(text_2);
      }
      if (group_arr[i] == 11) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_11.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_11.push(text_2);
      }
      if (group_arr[i] == 12) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_12.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_12.push(text_2);
      }
      if (group_arr[i] == 13) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_13.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_13.push(text_2);
      }
      if (group_arr[i] == 14) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_14.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_14.push(text_2);
      }
      if (group_arr[i] == 15) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_15.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_15.push(text_2);
      }
      if (group_arr[i] == 16) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_16.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_16.push(text_2);
      }
      if (group_arr[i] == 17) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_17.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_17.push(text_2);
      }
      if (group_arr[i] == 18) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_18.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_18.push(text_2);
      }
      if (group_arr[i] == 19) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_19.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_19.push(text_2);
      }
      if (group_arr[i] == 20) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_20.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_20.push(text_2);
      }
      if (group_arr[i] == 21) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_21.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_21.push(text_2);
      }
      if (group_arr[i] == 22) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_22.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_22.push(text_2);
      }
      if (group_arr[i] == 23) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_23.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_23.push(text_2);
      }
      if (group_arr[i] == 24) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_24.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_24.push(text_2);
      }
      if (group_arr[i] == 25) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_25.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_25.push(text_2);
      }
      if (group_arr[i] == 26) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_26.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_26.push(text_2);
      }
      if (group_arr[i] == 27) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_27.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_27.push(text_2);
      }
      if (group_arr[i] == 28) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_28.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_28.push(text_2);
      }
      if (group_arr[i] == 29) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_29.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_29.push(text_2);
      }
      if (group_arr[i] == 30) {
        let text = "";
        text += name_arr[i];
        text += ": ";
        text_point_30.push(text);
        let text_2 = "";
        if (typeof data_arr[i] === "string") {
          text_2 += data_arr[i];
        } else {
          text_2 += (data_arr[i] * mult_arr[i]).toFixed(2);
        }
        if (unit_arr[i] != null) {
          text_2 += " ";
          text_2 += unit_arr[i];
        }
        data_point_30.push(text_2);
      }
    }
    return [
      data_point_1,
      data_point_2,
      data_point_3,
      data_point_4,
      data_point_5,
      data_point_6,
      data_point_7,
      data_point_8,
      data_point_9,
      data_point_10,
      data_point_11,
      data_point_12,
      data_point_13,
      data_point_14,
      data_point_15,
      data_point_16,
      data_point_17,
      data_point_18,
      data_point_19,
      data_point_20,
      data_point_21,
      data_point_22,
      data_point_23,
      data_point_24,
      data_point_25,
      data_point_26,
      data_point_27,
      data_point_28,
      data_point_29,
      data_point_30,
      text_point_1,
      text_point_2,
      text_point_3,
      text_point_4,
      text_point_5,
      text_point_6,
      text_point_7,
      text_point_8,
      text_point_9,
      text_point_10,
      text_point_11,
      text_point_12,
      text_point_13,
      text_point_14,
      text_point_15,
      text_point_16,
      text_point_17,
      text_point_18,
      text_point_19,
      text_point_20,
      text_point_21,
      text_point_22,
      text_point_23,
      text_point_24,
      text_point_25,
      text_point_26,
      text_point_27,
      text_point_28,
      text_point_29,
      text_point_30,
    ];
  };
  useEffect(() => {
    if (success) {
      const data_arr = gatewaydiagram.data_arr;
      const group_arr = gatewaydiagram.group_arr;
      const unit_arr = gatewaydiagram.unit_arr;
      const name_arr = gatewaydiagram.name_arr;
      const mult_arr = gatewaydiagram.multiplier_arr;

      const ahu_type = gatewaydiagram.ahu_type.id;

      let all_data = createPoint(
        data_arr,
        group_arr,
        unit_arr,
        name_arr,
        mult_arr
      );

      let data_1 = all_data[0];
      let data_2 = all_data[1];
      let data_3 = all_data[2];
      let data_4 = all_data[3];
      let data_5 = all_data[4];
      let data_6 = all_data[5];
      let data_7 = all_data[6];
      let data_8 = all_data[7];
      let data_9 = all_data[8];
      let data_10 = all_data[9];
      let data_11 = all_data[10];
      let data_12 = all_data[11];
      let data_13 = all_data[12];
      let data_14 = all_data[13];
      let data_15 = all_data[14];
      let data_16 = all_data[15];
      let data_17 = all_data[16];
      let data_18 = all_data[17];
      let data_19 = all_data[18];
      let data_20 = all_data[19];
      let data_21 = all_data[20];
      let data_22 = all_data[21];
      let data_23 = all_data[22];
      let data_24 = all_data[23];
      let data_25 = all_data[24];
      let data_26 = all_data[25];
      let data_27 = all_data[26];
      let data_28 = all_data[27];
      let data_29 = all_data[28];
      let data_30 = all_data[29];
      let text_1 = all_data[30];
      let text_2 = all_data[31];
      let text_3 = all_data[32];
      let text_4 = all_data[33];
      let text_5 = all_data[34];
      let text_6 = all_data[35];
      let text_7 = all_data[36];
      let text_8 = all_data[37];
      let text_9 = all_data[38];
      let text_10 = all_data[39];
      let text_11 = all_data[40];
      let text_12 = all_data[41];
      let text_13 = all_data[42];
      let text_14 = all_data[43];
      let text_15 = all_data[44];
      let text_16 = all_data[45];
      let text_17 = all_data[46];
      let text_18 = all_data[47];
      let text_19 = all_data[48];
      let text_20 = all_data[49];
      let text_21 = all_data[50];
      let text_22 = all_data[51];
      let text_23 = all_data[52];
      let text_24 = all_data[53];
      let text_25 = all_data[54];
      let text_26 = all_data[55];
      let text_27 = all_data[56];
      let text_28 = all_data[57];
      let text_29 = all_data[58];
      let text_30 = all_data[59];

      const data_1_object = createObject(data_1, text_1);
      const data_2_object = createObject(data_2, text_2);
      const data_3_object = createObject(data_3, text_3);
      const data_4_object = createObject(data_4, text_4);
      const data_5_object = createObject(data_5, text_5);
      const data_6_object = createObject(data_6, text_6);
      const data_7_object = createObject(data_7, text_7);
      const data_8_object = createObject(data_8, text_8);
      const data_9_object = createObject(data_9, text_9);
      const data_10_object = createObject(data_10, text_10);
      const data_11_object = createObject(data_11, text_11);
      const data_12_object = createObject(data_12, text_12);
      const data_13_object = createObject(data_13, text_13);
      const data_14_object = createObject(data_14, text_14);
      const data_15_object = createObject(data_15, text_15);
      const data_16_object = createObject(data_16, text_16);
      const data_17_object = createObject(data_17, text_17);
      const data_18_object = createObject(data_18, text_18);
      const data_19_object = createObject(data_19, text_19);
      const data_20_object = createObject(data_20, text_20);
      const data_21_object = createObject(data_21, text_21);
      const data_22_object = createObject(data_22, text_22);
      const data_23_object = createObject(data_23, text_23);
      const data_24_object = createObject(data_24, text_24);
      const data_25_object = createObject(data_25, text_25);
      const data_26_object = createObject(data_26, text_26);
      const data_27_object = createObject(data_27, text_27);
      const data_28_object = createObject(data_28, text_28);
      const data_29_object = createObject(data_29, text_29);
      const data_30_object = createObject(data_30, text_30);

      if (ahu_type == 1) {
        console.log(gatewaydiagram.ahu_count)
        if (gatewaydiagram.ahu_count != 1){
          const new_nodes = [
            {
              id: "1",
              sourcePosition: "right",
              type: "input",
              className: "dark-node",
              data: data_1_object,
              position: { x: -875, y: 80 },
            },
            {
              id: "2",
              targetPosition: "left",
              sourcePosition: "right",
              className: "dark-node",
              data: data_2_object,
              position: { x: -675, y: top_y },
            },
            {
              id: "3",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_3_object,
              position: { x: -475, y: top_y },
            },
            {
              id: "4",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_4_object,
              position: { x: -275, y: top_y },
            },
            {
              id: "5",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_5_object,
              position: { x: -75, y: top_y },
            },
            {
              id: "6",
              targetPosition: "left",
              sourcePosition: "right",
              className: "dark-node",
              data: data_6_object,
              position: { x: 125, y: top_y },
            },
            {
              id: "7",
              targetPosition: "left",
              type: "output",
              className: "dark-node",
              data: data_7_object,
              position: { x: 325, y: top_y },
            },
            {
              id: "8",
              type: "output",
              className: "dark-node",
              data: data_8_object,
              position: { x: -875, y: 320 },
            },
            {
              id: "9",
              type: "output",
              targetPosition: "left",
              className: "dark-node",
              data: data_9_object,
              position: { x: 325, y: bottom_y },
            },
            {
              id: "10",
              type: "output",
              className: "dark-node",
              data: data_10_object,
              position: { x: -875, y: -160 },
            },
          ]
          setNodes(new_nodes);
        }
        else{
          const new_nodes = [
            {
              id: "1",
              sourcePosition: "right",
              type: "input",
              className: "dark-node",
              data: data_1_object,
              position: { x: -875, y: 80 },
            },
            {
              id: "2",
              targetPosition: "left",
              sourcePosition: "right",
              className: "dark-node",
              data: data_2_object,
              position: { x: -675, y: top_y },
            },
            {
              id: "3",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_3_object,
              position: { x: -475, y: top_y },
            },
            {
              id: "4",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_4_object,
              position: { x: -275, y: top_y },
            },
            {
              id: "5",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_5_object,
              position: { x: -75, y: top_y },
            },
            {
              id: "6",
              targetPosition: "left",
              sourcePosition: "right",
              className: "dark-node",
              data: data_6_object,
              position: { x: 125, y: top_y },
            },
            {
              id: "7",
              targetPosition: "left",
              type: "output",
              className: "dark-node",
              data: data_7_object,
              position: { x: 325, y: top_y },
            },
            {
              id: "8",
              type: "output",
              className: "dark-node",
              data: data_8_object,
              position: { x: -875, y: 320 },
            },
            {
              id: "9",
              type: "output",
              targetPosition: "left",
              className: "dark-node",
              data: data_9_object,
              position: { x: 325, y: bottom_y },
            },
            {
              id: "10",
              type: "output",
              className: "dark-node",
              data: data_10_object,
              position: { x: -875, y: -160 },
            },
            {
              id: "11",
              type: "output",
              className: "dark-node",
              data: data_11_object,
              position: { x: -675, y: 320 },
            },
          ];
        setNodes(new_nodes);
        }


        const new_edges = [
          {
            id: "1-2",
            source: "1",
            type: "smoothstep",
            target: "2",
            animated: true,
          },
          {
            id: "2-3",
            source: "2",
            type: "smoothstep",
            target: "3",
            animated: true,
          },
          {
            id: "3-4",
            source: "3",
            type: "smoothstep",
            target: "4",
            animated: true,
          },
          {
            id: "4-5",
            source: "4",
            type: "smoothstep",
            target: "5",
            animated: true,
          },
          {
            id: "5-6",
            source: "5",
            type: "smoothstep",
            target: "6",
            animated: true,
          },
          {
            id: "6-7",
            source: "6",
            type: "smoothstep",
            target: "7",
            animated: true,
          },
          {
            id: "1-9",
            source: "1",
            type: "smoothstep",
            target: "9",
            animated: true,
          },
        ];

        const new_nodes2 = [
          {
            id: "11",
            sourcePosition: "right",
            type: "input",
            className: "dark-node",
            data: data_11_object,
            position: { x: -875, y: 80 },
          },
          {
            id: "12",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_12_object,
            position: { x: -675, y: top_y },
          },
          {
            id: "13",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_13_object,
            position: { x: -475, y: top_y },
          },
          {
            id: "14",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_14_object,
            position: { x: -275, y: top_y },
          },
          {
            id: "15",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_15_object,
            position: { x: -75, y: top_y },
          },
          {
            id: "16",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_16_object,
            position: { x: 125, y: top_y },
          },
          {
            id: "17",
            targetPosition: "left",
            type: "output",
            className: "dark-node",
            data: data_17_object,
            position: { x: 325, y: top_y },
          },
          {
            id: "18",
            type: "output",
            className: "dark-node",
            data: data_18_object,
            position: { x: -875, y: 320 },
          },
          {
            id: "19",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_19_object,
            position: { x: 325, y: bottom_y },
          },
          {
            id: "20",
            type: "output",
            className: "dark-node",
            data: data_20_object,
            position: { x: -875, y: -160 },
          },
        ];

        const new_edges2 = [
          {
            id: "11-12",
            source: "11",
            type: "smoothstep",
            target: "12",
            animated: true,
          },
          {
            id: "12-13",
            source: "12",
            type: "smoothstep",
            target: "13",
            animated: true,
          },
          {
            id: "13-14",
            source: "13",
            type: "smoothstep",
            target: "14",
            animated: true,
          },
          {
            id: "14-15",
            source: "14",
            type: "smoothstep",
            target: "15",
            animated: true,
          },
          {
            id: "15-16",
            source: "15",
            type: "smoothstep",
            target: "16",
            animated: true,
          },
          {
            id: "16-17",
            source: "16",
            type: "smoothstep",
            target: "17",
            animated: true,
          },
          {
            id: "11-19",
            source: "11",
            type: "smoothstep",
            target: "19",
            animated: true,
          },
        ];
        const new_nodes3 = [
          {
            id: "21",
            sourcePosition: "right",
            type: "input",
            className: "dark-node",
            data: data_21_object,
            position: { x: -875, y: 80 },
          },
          {
            id: "22",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_22_object,
            position: { x: -675, y: top_y },
          },
          {
            id: "23",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_23_object,
            position: { x: -475, y: top_y },
          },
          {
            id: "24",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_24_object,
            position: { x: -275, y: top_y },
          },
          {
            id: "25",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_25_object,
            position: { x: -75, y: top_y },
          },
          {
            id: "26",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_26_object,
            position: { x: 125, y: top_y },
          },
          {
            id: "27",
            targetPosition: "left",
            type: "output",
            className: "dark-node",
            data: data_27_object,
            position: { x: 325, y: top_y },
          },
          {
            id: "28",
            type: "output",
            className: "dark-node",
            data: data_28_object,
            position: { x: -875, y: 320 },
          },
          {
            id: "29",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_29_object,
            position: { x: 325, y: bottom_y },
          },
          {
            id: "30",
            type: "output",
            className: "dark-node",
            data: data_30_object,
            position: { x: -875, y: -160 },
          },
        ];

        const new_edges3 = [
          {
            id: "21-22",
            source: "21",
            type: "smoothstep",
            target: "22",
            animated: true,
          },
          {
            id: "22-23",
            source: "22",
            type: "smoothstep",
            target: "23",
            animated: true,
          },
          {
            id: "23-24",
            source: "23",
            type: "smoothstep",
            target: "24",
            animated: true,
          },
          {
            id: "24-25",
            source: "24",
            type: "smoothstep",
            target: "25",
            animated: true,
          },
          {
            id: "25-26",
            source: "25",
            type: "smoothstep",
            target: "26",
            animated: true,
          },
          {
            id: "26-27",
            source: "26",
            type: "smoothstep",
            target: "27",
            animated: true,
          },
          {
            id: "21-29",
            source: "21",
            type: "smoothstep",
            target: "29",
            animated: true,
          },
        ];

        setEdges(new_edges);

        setNodes2(new_nodes2);
        setEdges2(new_edges2);

        setNodes3(new_nodes3);
        setEdges3(new_edges3);
      } else if (ahu_type == 2) {
        const new_nodes = [
          {
            id: "1",
            type: "output",
            className: "dark-node",
            data: data_1_object,
            position: { x: 0, y: -350 },
          },
          {
            id: "2",
            type: "output",
            className: "dark-node",
            data: data_2_object,
            position: { x: -525, y: -350 },
          },
          {
            id: "3",
            type: "output",
            className: "dark-node",
            data: data_3_object,
            position: { x: 350, y: -350 },
          },
          {
            id: "4",
            type: "output",
            className: "dark-node",
            data: data_4_object,
            position: { x: -700, y: -320 },
          },
          {
            id: "5",
            sourcePosition: "right",
            type: "input",
            className: "dark-node",
            data: data_5_object,
            position: { x: -875, y: 0 },
          },
          {
            id: "6",
            targetPosition: "left",
            type: "output",
            className: "dark-node",
            data: data_6_object,
            position: { x: 525, y: 0 },
          },
          {
            id: "7",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_7_object,
            position: { x: -350, y: 0 },
          },
          {
            id: "8",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_8_object,
            position: { x: 0, y: 0 },
          },
          {
            id: "9",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_9_object,
            position: { x: -525, y: 0 },
          },
          {
            id: "10",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_10_object,
            position: { x: 350, y: 0 },
          },
          {
            id: "11",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_11_object,
            position: { x: -175, y: 0 },
          },
          {
            id: "12",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_12_object,
            position: { x: -700, y: 0 },
          },
          {
            id: "13",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_13_object,
            position: { x: 175, y: 0 },
          },
          {
            id: "14",
            type: "output",
            data: data_14_object,
            position: { x: -875, y: -320 },
          },
        ];

        const new_edges = [
          {
            id: "5-12",
            source: "5",
            type: "smoothstep",
            target: "12",
            animated: true,
          },
          {
            id: "12-9",
            source: "12",
            type: "smoothstep",
            target: "9",
            animated: true,
          },
          {
            id: "9-7",
            source: "9",
            type: "smoothstep",
            target: "7",
            animated: true,
          },
          {
            id: "7-11",
            source: "7",
            type: "smoothstep",
            target: "11",
            animated: true,
          },
          {
            id: "11-8",
            source: "11",
            type: "smoothstep",
            target: "8",
            animated: true,
          },
          {
            id: "8-13",
            source: "8",
            type: "smoothstep",
            target: "13",
            animated: true,
          },
          {
            id: "13-10",
            source: "13",
            type: "smoothstep",
            target: "10",
            animated: true,
          },
          {
            id: "10-6",
            source: "10",
            type: "smoothstep",
            target: "6",
            animated: true,
          },
        ];

        setNodes(new_nodes);
        setEdges(new_edges);
      } else if (ahu_type == 3) {
        const new_nodes = [
          {
            id: "1",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_1_object,
            position: { x: -600, y: -550 },
          },
          {
            id: "2",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_2_object,
            position: { x: -400, y: -550 },
          },
          {
            id: "3",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_3_object,
            position: { x: -200, y: -550 },
          },
          {
            id: "4",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_4_object,
            position: { x: -800, y: -300 },
          },
          {
            id: "5",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_5_object,
            position: { x: -600, y: -300 },
          },
          {
            id: "6",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_6_object,
            position: { x: -200, y: -300 },
          },
          {
            id: "7",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_7_object,
            position: { x: 0, y: -300 },
          },
          {
            id: "8",
            type: "output",
            className: "dark-node",
            data: data_8_object,
            position: { x: -400, y: -50 },
          },
          {
            id: "9",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_9_object,
            position: { x: -800, y: -50 },
          },
          {
            id: "10",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_10_object,
            position: { x: -600, y: -50 },
          },
          {
            id: "11",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_11_object,
            position: { x: -200, y: -50 },
          },
          {
            id: "12",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_12_object,
            position: { x: 0, y: -50 },
          },
          {
            id: "13",
            type: "output",
            className: "dark-node",
            data: data_13_object,
            position: { x: -400, y: 150 },
          },
        ];

        const new_edges = [
          {
            id: "1-2",
            source: "1",
            type: "smoothstep",
            target: "2",
            animated: true,
          },
          {
            id: "2-3",
            source: "2",
            type: "smoothstep",
            target: "3",
            animated: true,
          },
          {
            id: "4-5",
            source: "4",
            type: "smoothstep",
            target: "5",
            animated: true,
          },
          {
            id: "6-7",
            source: "6",
            type: "smoothstep",
            target: "7",
            animated: true,
          },
          {
            id: "9-10",
            source: "9",
            type: "smoothstep",
            target: "10",
            animated: true,
          },
          {
            id: "11-12",
            source: "11",
            type: "smoothstep",
            target: "12",
            animated: true,
          },
        ];

        setNodes(new_nodes);
        setEdges(new_edges);
      }
      else if (ahu_type == 4) {
        const new_nodes = [
          {
            id: "1",
            type: "input",
            sourcePosition: "bottom",
            className: "dark-node",
            data: data_1_object,
            position: { x: -600, y: -550 },
          },
          {
            id: "2",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_2_object,
            position: { x: -600, y: -150 },
          },
          {
            id: "3",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_3_object,
            position: { x: -400, y: -150 },
          },
          {
            id: "4",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_4_object,
            position: { x: -200, y: -150 },
          },
          {
            id: "5",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_5_object,
            position: { x: 0, y: -150 },
          },
          {
            id: "6",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_6_object,
            position: { x: 200, y: -150 },
          },
          {
            id: "7",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_7_object,
            position: { x: 400, y: -150 },
          },
          {
            id: "8",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_8_object,
            position: { x: 600, y: -150 },
          },
          {
            id: "9",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_9_object,
            position: { x: 800, y: -150 },
          },
          {
            id: "10",
            targetPosition: "right",
            className: "dark-node",
            data: data_10_object,
            position: { x: -600, y: -350 },
          },
          {
            id: "11",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_11_object,
            position: { x: 400, y: -350 },
          },
          {
            id: "12",
            type: "input",
            sourcePosition: "left",
            className: "dark-node",
            data: data_12_object,
            position: { x: 800, y: -350 },
          },
          {
            id: "13",
            type: "input",
            className: "dark-node",
            data: data_13_object,
            position: { x: -400, y: -550 },
          },

        ];

        const new_edges = [
          {
            id: "2-3",
            source: "2",
            type: "smoothstep",
            target: "3",
            animated: true,
          },
          {
            id: "3-4",
            source: "3",
            type: "smoothstep",
            target: "4",
            animated: true,
          },
          {
            id: "4-5",
            source: "4",
            type: "smoothstep",
            target: "5",
            animated: true,
          },
          {
            id: "5-6",
            source: "5",
            type: "smoothstep",
            target: "6",
            animated: true,
          },
          {
            id: "6-7",
            source: "6",
            type: "smoothstep",
            target: "7",
            animated: true,
          },
          {
            id: "7-8",
            source: "7",
            type: "smoothstep",
            target: "8",
            animated: true,
          },
          {
            id: "8-9",
            source: "8",
            type: "smoothstep",
            target: "9",
            animated: true,
          },
          {
            id: "12-11",
            source: "12",
            type: "smoothstep",
            target: "11",
            animated: true,
          },
          {
            id: "11-10",
            source: "11",
            type: "smoothstep",
            target: "10",
            animated: true,
          },
        ];

        setNodes(new_nodes);
        setEdges(new_edges);
      }
      else if (ahu_type == 5) {
        const new_nodes = [
          {
            id: "1",
            type: "input",
            sourcePosition: "bottom",
            className: "dark-node",
            data: data_1_object,
            position: { x: -600, y: -550 },
          },
          {
            id: "2",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_2_object,
            position: { x: -600, y: -150 },
          },
          {
            id: "3",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_3_object,
            position: { x: -400, y: -150 },
          },
          /*{
            id: "4",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_4_object,
            position: { x: -200, y: -150 },
          },*/
          {
            id: "5",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_5_object,
            position: { x: 0, y: -150 },
          },
          {
            id: "6",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_6_object,
            position: { x: 200, y: -150 },
          },
          {
            id: "7",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_7_object,
            position: { x: 400, y: -150 },
          },
          {
            id: "8",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_8_object,
            position: { x: 600, y: -150 },
          },
          {
            id: "9",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_9_object,
            position: { x: 800, y: -150 },
          },
          {
            id: "10",
            targetPosition: "right",
            className: "dark-node",
            data: data_10_object,
            position: { x: -600, y: -350 },
          },
          {
            id: "11",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_11_object,
            position: { x: 400, y: -350 },
          },
          {
            id: "12",
            type: "input",
            sourcePosition: "left",
            className: "dark-node",
            data: data_12_object,
            position: { x: 800, y: -350 },
          },
          {
            id: "13",
            type: "input",
            className: "dark-node",
            data: data_13_object,
            position: { x: -400, y: -550 },
          },

        ];

        const new_edges = [
          {
            id: "2-3",
            source: "2",
            type: "smoothstep",
            target: "3",
            animated: true,
          },
          {
            id: "3-5",
            source: "3",
            type: "smoothstep",
            target: "5",
            animated: true,
          },
          /*{
            id: "4-5",
            source: "4",
            type: "smoothstep",
            target: "5",
            animated: true,
          },*/
          {
            id: "5-6",
            source: "5",
            type: "smoothstep",
            target: "6",
            animated: true,
          },
          {
            id: "6-7",
            source: "6",
            type: "smoothstep",
            target: "7",
            animated: true,
          },
          {
            id: "7-8",
            source: "7",
            type: "smoothstep",
            target: "8",
            animated: true,
          },
          {
            id: "8-9",
            source: "8",
            type: "smoothstep",
            target: "9",
            animated: true,
          },
          {
            id: "12-11",
            source: "12",
            type: "smoothstep",
            target: "11",
            animated: true,
          },
          {
            id: "11-10",
            source: "11",
            type: "smoothstep",
            target: "10",
            animated: true,
          },
        ];

        setNodes(new_nodes);
        setEdges(new_edges);
      }
    }
  }, [success]);

  useEffect(() => {
    if (updateSuccess) {
      const data_arr = gatewaydiagramupdates.data_arr;
      const group_arr = gatewaydiagramupdates.group_arr;
      const unit_arr = gatewaydiagramupdates.unit_arr;
      const name_arr = gatewaydiagramupdates.name_arr;
      const mult_arr = gatewaydiagramupdates.multiplier_arr;

      const ahu_type = gatewaydiagram.ahu_type.id;

      let all_data = createPoint(
        data_arr,
        group_arr,
        unit_arr,
        name_arr,
        mult_arr
      );

      let data_1 = all_data[0];
      let data_2 = all_data[1];
      let data_3 = all_data[2];
      let data_4 = all_data[3];
      let data_5 = all_data[4];
      let data_6 = all_data[5];
      let data_7 = all_data[6];
      let data_8 = all_data[7];
      let data_9 = all_data[8];
      let data_10 = all_data[9];
      let data_11 = all_data[10];
      let data_12 = all_data[11];
      let data_13 = all_data[12];
      let data_14 = all_data[13];
      let data_15 = all_data[14];
      let data_16 = all_data[15];
      let data_17 = all_data[16];
      let data_18 = all_data[17];
      let data_19 = all_data[18];
      let data_20 = all_data[19];
      let data_21 = all_data[20];
      let data_22 = all_data[21];
      let data_23 = all_data[22];
      let data_24 = all_data[23];
      let data_25 = all_data[24];
      let data_26 = all_data[25];
      let data_27 = all_data[26];
      let data_28 = all_data[27];
      let data_29 = all_data[28];
      let data_30 = all_data[29];
      let text_1 = all_data[30];
      let text_2 = all_data[31];
      let text_3 = all_data[32];
      let text_4 = all_data[33];
      let text_5 = all_data[34];
      let text_6 = all_data[35];
      let text_7 = all_data[36];
      let text_8 = all_data[37];
      let text_9 = all_data[38];
      let text_10 = all_data[39];
      let text_11 = all_data[40];
      let text_12 = all_data[41];
      let text_13 = all_data[42];
      let text_14 = all_data[43];
      let text_15 = all_data[44];
      let text_16 = all_data[45];
      let text_17 = all_data[46];
      let text_18 = all_data[47];
      let text_19 = all_data[48];
      let text_20 = all_data[49];
      let text_21 = all_data[50];
      let text_22 = all_data[51];
      let text_23 = all_data[52];
      let text_24 = all_data[53];
      let text_25 = all_data[54];
      let text_26 = all_data[55];
      let text_27 = all_data[56];
      let text_28 = all_data[57];
      let text_29 = all_data[58];
      let text_30 = all_data[59];

      const data_1_object = createObject(data_1, text_1);
      const data_2_object = createObject(data_2, text_2);
      const data_3_object = createObject(data_3, text_3);
      const data_4_object = createObject(data_4, text_4);
      const data_5_object = createObject(data_5, text_5);
      const data_6_object = createObject(data_6, text_6);
      const data_7_object = createObject(data_7, text_7);
      const data_8_object = createObject(data_8, text_8);
      const data_9_object = createObject(data_9, text_9);
      const data_10_object = createObject(data_10, text_10);
      const data_11_object = createObject(data_11, text_11);
      const data_12_object = createObject(data_12, text_12);
      const data_13_object = createObject(data_13, text_13);
      const data_14_object = createObject(data_14, text_14);
      const data_15_object = createObject(data_15, text_15);
      const data_16_object = createObject(data_16, text_16);
      const data_17_object = createObject(data_17, text_17);
      const data_18_object = createObject(data_18, text_18);
      const data_19_object = createObject(data_19, text_19);
      const data_20_object = createObject(data_20, text_20);
      const data_21_object = createObject(data_21, text_21);
      const data_22_object = createObject(data_22, text_22);
      const data_23_object = createObject(data_23, text_23);
      const data_24_object = createObject(data_24, text_24);
      const data_25_object = createObject(data_25, text_25);
      const data_26_object = createObject(data_26, text_26);
      const data_27_object = createObject(data_27, text_27);
      const data_28_object = createObject(data_28, text_28);
      const data_29_object = createObject(data_29, text_29);
      const data_30_object = createObject(data_30, text_30);
      if (ahu_type == 1) {
        console.log(gatewaydiagram.ahu_count)
        if (gatewaydiagram.ahu_count != 1){
          const new_nodes = [
            {
              id: "1",
              sourcePosition: "right",
              type: "input",
              className: "dark-node",
              data: data_1_object,
              position: { x: -875, y: 80 },
            },
            {
              id: "2",
              targetPosition: "left",
              sourcePosition: "right",
              className: "dark-node",
              data: data_2_object,
              position: { x: -675, y: top_y },
            },
            {
              id: "3",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_3_object,
              position: { x: -475, y: top_y },
            },
            {
              id: "4",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_4_object,
              position: { x: -275, y: top_y },
            },
            {
              id: "5",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_5_object,
              position: { x: -75, y: top_y },
            },
            {
              id: "6",
              targetPosition: "left",
              sourcePosition: "right",
              className: "dark-node",
              data: data_6_object,
              position: { x: 125, y: top_y },
            },
            {
              id: "7",
              targetPosition: "left",
              type: "output",
              className: "dark-node",
              data: data_7_object,
              position: { x: 325, y: top_y },
            },
            {
              id: "8",
              type: "output",
              className: "dark-node",
              data: data_8_object,
              position: { x: -875, y: 320 },
            },
            {
              id: "9",
              type: "output",
              targetPosition: "left",
              className: "dark-node",
              data: data_9_object,
              position: { x: 325, y: bottom_y },
            },
            {
              id: "10",
              type: "output",
              className: "dark-node",
              data: data_10_object,
              position: { x: -875, y: -160 },
            },
          ]
          setNodes(new_nodes);
        }
        else{
          const new_nodes = [
            {
              id: "1",
              sourcePosition: "right",
              type: "input",
              className: "dark-node",
              data: data_1_object,
              position: { x: -875, y: 80 },
            },
            {
              id: "2",
              targetPosition: "left",
              sourcePosition: "right",
              className: "dark-node",
              data: data_2_object,
              position: { x: -675, y: top_y },
            },
            {
              id: "3",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_3_object,
              position: { x: -475, y: top_y },
            },
            {
              id: "4",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_4_object,
              position: { x: -275, y: top_y },
            },
            {
              id: "5",
              sourcePosition: "right",
              targetPosition: "left",
              className: "dark-node",
              data: data_5_object,
              position: { x: -75, y: top_y },
            },
            {
              id: "6",
              targetPosition: "left",
              sourcePosition: "right",
              className: "dark-node",
              data: data_6_object,
              position: { x: 125, y: top_y },
            },
            {
              id: "7",
              targetPosition: "left",
              type: "output",
              className: "dark-node",
              data: data_7_object,
              position: { x: 325, y: top_y },
            },
            {
              id: "8",
              type: "output",
              className: "dark-node",
              data: data_8_object,
              position: { x: -875, y: 320 },
            },
            {
              id: "9",
              type: "output",
              targetPosition: "left",
              className: "dark-node",
              data: data_9_object,
              position: { x: 325, y: bottom_y },
            },
            {
              id: "10",
              type: "output",
              className: "dark-node",
              data: data_10_object,
              position: { x: -875, y: -160 },
            },
            {
              id: "11",
              type: "output",
              className: "dark-node",
              data: data_11_object,
              position: { x: -675, y: 320 },
            },
          ];
        setNodes(new_nodes);
        }


        const new_edges = [
          {
            id: "1-2",
            source: "1",
            type: "smoothstep",
            target: "2",
            animated: true,
          },
          {
            id: "2-3",
            source: "2",
            type: "smoothstep",
            target: "3",
            animated: true,
          },
          {
            id: "3-4",
            source: "3",
            type: "smoothstep",
            target: "4",
            animated: true,
          },
          {
            id: "4-5",
            source: "4",
            type: "smoothstep",
            target: "5",
            animated: true,
          },
          {
            id: "5-6",
            source: "5",
            type: "smoothstep",
            target: "6",
            animated: true,
          },
          {
            id: "6-7",
            source: "6",
            type: "smoothstep",
            target: "7",
            animated: true,
          },
          {
            id: "1-9",
            source: "1",
            type: "smoothstep",
            target: "9",
            animated: true,
          },
        ];

        const new_nodes2 = [
          {
            id: "11",
            sourcePosition: "right",
            type: "input",
            className: "dark-node",
            data: data_11_object,
            position: { x: -875, y: 80 },
          },
          {
            id: "12",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_12_object,
            position: { x: -675, y: top_y },
          },
          {
            id: "13",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_13_object,
            position: { x: -475, y: top_y },
          },
          {
            id: "14",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_14_object,
            position: { x: -275, y: top_y },
          },
          {
            id: "15",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_15_object,
            position: { x: -75, y: top_y },
          },
          {
            id: "16",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_16_object,
            position: { x: 125, y: top_y },
          },
          {
            id: "17",
            targetPosition: "left",
            type: "output",
            className: "dark-node",
            data: data_17_object,
            position: { x: 325, y: top_y },
          },
          {
            id: "18",
            type: "output",
            className: "dark-node",
            data: data_18_object,
            position: { x: -875, y: 320 },
          },
          {
            id: "19",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_19_object,
            position: { x: 325, y: bottom_y },
          },
          {
            id: "20",
            type: "output",
            className: "dark-node",
            data: data_20_object,
            position: { x: -875, y: -160 },
          },
        ];

        const new_edges2 = [
          {
            id: "11-12",
            source: "11",
            type: "smoothstep",
            target: "12",
            animated: true,
          },
          {
            id: "12-13",
            source: "12",
            type: "smoothstep",
            target: "13",
            animated: true,
          },
          {
            id: "13-14",
            source: "13",
            type: "smoothstep",
            target: "14",
            animated: true,
          },
          {
            id: "14-15",
            source: "14",
            type: "smoothstep",
            target: "15",
            animated: true,
          },
          {
            id: "15-16",
            source: "15",
            type: "smoothstep",
            target: "16",
            animated: true,
          },
          {
            id: "16-17",
            source: "16",
            type: "smoothstep",
            target: "17",
            animated: true,
          },
          {
            id: "11-19",
            source: "11",
            type: "smoothstep",
            target: "19",
            animated: true,
          },
        ];
        const new_nodes3 = [
          {
            id: "21",
            sourcePosition: "right",
            type: "input",
            className: "dark-node",
            data: data_21_object,
            position: { x: -875, y: 80 },
          },
          {
            id: "22",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_22_object,
            position: { x: -675, y: top_y },
          },
          {
            id: "23",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_23_object,
            position: { x: -475, y: top_y },
          },
          {
            id: "24",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_24_object,
            position: { x: -275, y: top_y },
          },
          {
            id: "25",
            sourcePosition: "right",
            targetPosition: "left",
            className: "dark-node",
            data: data_25_object,
            position: { x: -75, y: top_y },
          },
          {
            id: "26",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_26_object,
            position: { x: 125, y: top_y },
          },
          {
            id: "27",
            targetPosition: "left",
            type: "output",
            className: "dark-node",
            data: data_27_object,
            position: { x: 325, y: top_y },
          },
          {
            id: "28",
            type: "output",
            className: "dark-node",
            data: data_28_object,
            position: { x: -875, y: 320 },
          },
          {
            id: "29",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_29_object,
            position: { x: 325, y: bottom_y },
          },
          {
            id: "30",
            type: "output",
            className: "dark-node",
            data: data_30_object,
            position: { x: -875, y: -160 },
          },
        ];

        const new_edges3 = [
          {
            id: "21-22",
            source: "21",
            type: "smoothstep",
            target: "22",
            animated: true,
          },
          {
            id: "22-23",
            source: "22",
            type: "smoothstep",
            target: "23",
            animated: true,
          },
          {
            id: "23-24",
            source: "23",
            type: "smoothstep",
            target: "24",
            animated: true,
          },
          {
            id: "24-25",
            source: "24",
            type: "smoothstep",
            target: "25",
            animated: true,
          },
          {
            id: "25-26",
            source: "25",
            type: "smoothstep",
            target: "26",
            animated: true,
          },
          {
            id: "26-27",
            source: "26",
            type: "smoothstep",
            target: "27",
            animated: true,
          },
          {
            id: "21-29",
            source: "21",
            type: "smoothstep",
            target: "29",
            animated: true,
          },
        ];

        setEdges(new_edges);

        setNodes2(new_nodes2);
        setEdges2(new_edges2);

        setNodes3(new_nodes3);
        setEdges3(new_edges3);
      } else if (ahu_type == 2) {
        const new_nodes = [
          {
            id: "1",
            type: "output",
            className: "dark-node",
            data: data_1_object,
            position: { x: 0, y: -350 },
          },
          {
            id: "2",
            type: "output",
            className: "dark-node",
            data: data_2_object,
            position: { x: -525, y: -350 },
          },
          {
            id: "3",
            type: "output",
            className: "dark-node",
            data: data_3_object,
            position: { x: 350, y: -350 },
          },
          {
            id: "4",
            type: "output",
            className: "dark-node",
            data: data_4_object,
            position: { x: -700, y: -320 },
          },
          {
            id: "5",
            sourcePosition: "right",
            type: "input",
            className: "dark-node",
            data: data_5_object,
            position: { x: -875, y: 0 },
          },
          {
            id: "6",
            targetPosition: "left",
            type: "output",
            className: "dark-node",
            data: data_6_object,
            position: { x: 525, y: 0 },
          },
          {
            id: "7",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_7_object,
            position: { x: -350, y: 0 },
          },
          {
            id: "8",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_8_object,
            position: { x: 0, y: 0 },
          },
          {
            id: "9",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_9_object,
            position: { x: -525, y: 0 },
          },
          {
            id: "10",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_10_object,
            position: { x: 350, y: 0 },
          },
          {
            id: "11",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_11_object,
            position: { x: -175, y: 0 },
          },
          {
            id: "12",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_12_object,
            position: { x: -700, y: 0 },
          },
          {
            id: "13",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_13_object,
            position: { x: 175, y: 0 },
          },
          {
            id: "14",
            type: "output",
            data: data_14_object,
            position: { x: -875, y: -320 },
          },
        ];

        const new_edges = [
          {
            id: "5-12",
            source: "5",
            type: "smoothstep",
            target: "12",
            animated: true,
          },
          {
            id: "12-9",
            source: "12",
            type: "smoothstep",
            target: "9",
            animated: true,
          },
          {
            id: "9-7",
            source: "9",
            type: "smoothstep",
            target: "7",
            animated: true,
          },
          {
            id: "7-11",
            source: "7",
            type: "smoothstep",
            target: "11",
            animated: true,
          },
          {
            id: "11-8",
            source: "11",
            type: "smoothstep",
            target: "8",
            animated: true,
          },
          {
            id: "8-13",
            source: "8",
            type: "smoothstep",
            target: "13",
            animated: true,
          },
          {
            id: "13-10",
            source: "13",
            type: "smoothstep",
            target: "10",
            animated: true,
          },
          {
            id: "10-6",
            source: "10",
            type: "smoothstep",
            target: "6",
            animated: true,
          },
        ];

        setNodes(new_nodes);
        setEdges(new_edges);
      } else if (ahu_type == 3) {
        const new_nodes = [
          {
            id: "1",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_1_object,
            position: { x: -600, y: -550 },
          },
          {
            id: "2",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_2_object,
            position: { x: -400, y: -550 },
          },
          {
            id: "3",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_3_object,
            position: { x: -200, y: -550 },
          },
          {
            id: "4",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_4_object,
            position: { x: -800, y: -300 },
          },
          {
            id: "5",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_5_object,
            position: { x: -600, y: -300 },
          },
          {
            id: "6",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_6_object,
            position: { x: -200, y: -300 },
          },
          {
            id: "7",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_7_object,
            position: { x: 0, y: -300 },
          },
          {
            id: "8",
            type: "output",
            className: "dark-node",
            data: data_8_object,
            position: { x: -400, y: -50 },
          },
          {
            id: "9",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_9_object,
            position: { x: -800, y: -50 },
          },
          {
            id: "10",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_10_object,
            position: { x: -600, y: -50 },
          },
          {
            id: "11",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_11_object,
            position: { x: -200, y: -50 },
          },
          {
            id: "12",
            type: "output",
            targetPosition: "left",
            className: "dark-node",
            data: data_12_object,
            position: { x: 0, y: -50 },
          },
          {
            id: "13",
            type: "output",
            className: "dark-node",
            data: data_13_object,
            position: { x: -400, y: 150 },
          },
        ];

        const new_edges = [
          {
            id: "1-2",
            source: "1",
            type: "smoothstep",
            target: "2",
            animated: true,
          },
          {
            id: "2-3",
            source: "2",
            type: "smoothstep",
            target: "3",
            animated: true,
          },
          {
            id: "4-5",
            source: "4",
            type: "smoothstep",
            target: "5",
            animated: true,
          },
          {
            id: "6-7",
            source: "6",
            type: "smoothstep",
            target: "7",
            animated: true,
          },
          {
            id: "9-10",
            source: "9",
            type: "smoothstep",
            target: "10",
            animated: true,
          },
          {
            id: "11-12",
            source: "11",
            type: "smoothstep",
            target: "12",
            animated: true,
          },
        ];

        setNodes(new_nodes);
        setEdges(new_edges);
      }      else if (ahu_type == 4) {
        const new_nodes = [
          {
            id: "1",
            type: "input",
            sourcePosition: "bottom",
            className: "dark-node",
            data: data_1_object,
            position: { x: -600, y: -550 },
          },
          {
            id: "2",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_2_object,
            position: { x: -600, y: -150 },
          },
          {
            id: "3",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_3_object,
            position: { x: -400, y: -150 },
          },
          {
            id: "4",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_4_object,
            position: { x: -200, y: -150 },
          },
          {
            id: "5",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_5_object,
            position: { x: 0, y: -150 },
          },
          {
            id: "6",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_6_object,
            position: { x: 200, y: -150 },
          },
          {
            id: "7",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_7_object,
            position: { x: 400, y: -150 },
          },
          {
            id: "8",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_8_object,
            position: { x: 600, y: -150 },
          },
          {
            id: "9",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_9_object,
            position: { x: 800, y: -150 },
          },
          {
            id: "10",
            targetPosition: "right",
            className: "dark-node",
            data: data_10_object,
            position: { x: -600, y: -350 },
          },
          {
            id: "11",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_11_object,
            position: { x: 400, y: -350 },
          },
          {
            id: "12",
            type: "input",
            sourcePosition: "left",
            className: "dark-node",
            data: data_12_object,
            position: { x: 800, y: -350 },
          },
          {
            id: "13",
            type: "input",
            className: "dark-node",
            data: data_13_object,
            position: { x: -400, y: -550 },
          },

        ];

        const new_edges = [
          {
            id: "2-3",
            source: "2",
            type: "smoothstep",
            target: "3",
            animated: true,
          },
          {
            id: "3-4",
            source: "3",
            type: "smoothstep",
            target: "4",
            animated: true,
          },
          {
            id: "4-5",
            source: "4",
            type: "smoothstep",
            target: "5",
            animated: true,
          },
          {
            id: "5-6",
            source: "5",
            type: "smoothstep",
            target: "6",
            animated: true,
          },
          {
            id: "6-7",
            source: "6",
            type: "smoothstep",
            target: "7",
            animated: true,
          },
          {
            id: "7-8",
            source: "7",
            type: "smoothstep",
            target: "8",
            animated: true,
          },
          {
            id: "8-9",
            source: "8",
            type: "smoothstep",
            target: "9",
            animated: true,
          },
          {
            id: "12-11",
            source: "12",
            type: "smoothstep",
            target: "11",
            animated: true,
          },
          {
            id: "11-10",
            source: "11",
            type: "smoothstep",
            target: "10",
            animated: true,
          },
        ];

        setNodes(new_nodes);
        setEdges(new_edges);
      }
      else if (ahu_type == 5) {
        const new_nodes = [
          {
            id: "1",
            type: "input",
            sourcePosition: "bottom",
            className: "dark-node",
            data: data_1_object,
            position: { x: -600, y: -550 },
          },
          {
            id: "2",
            type: "input",
            sourcePosition: "right",
            className: "dark-node",
            data: data_2_object,
            position: { x: -600, y: -150 },
          },
          {
            id: "3",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_3_object,
            position: { x: -400, y: -150 },
          },
          /*{
            id: "4",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_4_object,
            position: { x: -200, y: -150 },
          },*/
          {
            id: "5",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_5_object,
            position: { x: 0, y: -150 },
          },
          {
            id: "6",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_6_object,
            position: { x: 200, y: -150 },
          },
          {
            id: "7",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_7_object,
            position: { x: 400, y: -150 },
          },
          {
            id: "8",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_8_object,
            position: { x: 600, y: -150 },
          },
          {
            id: "9",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_9_object,
            position: { x: 800, y: -150 },
          },
          {
            id: "10",
            targetPosition: "right",
            className: "dark-node",
            data: data_10_object,
            position: { x: -600, y: -350 },
          },
          {
            id: "11",
            targetPosition: "left",
            sourcePosition: "right",
            className: "dark-node",
            data: data_11_object,
            position: { x: 400, y: -350 },
          },
          {
            id: "12",
            type: "input",
            sourcePosition: "left",
            className: "dark-node",
            data: data_12_object,
            position: { x: 800, y: -350 },
          },
          {
            id: "13",
            type: "input",
            className: "dark-node",
            data: data_13_object,
            position: { x: -400, y: -550 },
          },

        ];

        const new_edges = [
          {
            id: "2-3",
            source: "2",
            type: "smoothstep",
            target: "3",
            animated: true,
          },
          {
            id: "3-5",
            source: "3",
            type: "smoothstep",
            target: "5",
            animated: true,
          },
          /*{
            id: "4-5",
            source: "4",
            type: "smoothstep",
            target: "5",
            animated: true,
          },*/
          {
            id: "5-6",
            source: "5",
            type: "smoothstep",
            target: "6",
            animated: true,
          },
          {
            id: "6-7",
            source: "6",
            type: "smoothstep",
            target: "7",
            animated: true,
          },
          {
            id: "7-8",
            source: "7",
            type: "smoothstep",
            target: "8",
            animated: true,
          },
          {
            id: "8-9",
            source: "8",
            type: "smoothstep",
            target: "9",
            animated: true,
          },
          {
            id: "12-11",
            source: "12",
            type: "smoothstep",
            target: "11",
            animated: true,
          },
          {
            id: "11-10",
            source: "11",
            type: "smoothstep",
            target: "10",
            animated: true,
          },
        ];

        setNodes(new_nodes);
        setEdges(new_edges);
      }
    }
  }, [updateSuccess]);

  const excelOutput = () => {
    fetchExcel();
    if (dateSelected) {
      console.log(dateString);
      axios({
        url: `/api/output/gateway/${productId}/excel_out/${dateString}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${gatewaydiagram.name + " " + dateString}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      });
    } else {
      let date_string = formatDate(startDate);
      setDateString(date_string);
      console.log(date_string);
      axios({
        url: `/api/output/gateway/${productId}/excel_out/${formatDate(
          startDate
        )}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${gatewaydiagram.name + "  " + date_string}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleChange = (date) => {
    setIsOpen(!isOpen);
    setStartDate(date);

    let real_date = formatDate(date);
    setDateSelected(true);
    setDateString(real_date);
  };

  const formatDate = (date) => {
    let real_date =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);

    return real_date;
  };

  const fetchExcel = () => {
    setExcelCreation(true);

    setTimeout(() => {
      setExcelCreation(false);
    }, 30000);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <Col>
              <h1>
                {gatewaydiagram.name}

                <LinkContainer to={`/user/gateways`}>
                  <Button variant="transparent" style={{ color: "red" }}>
                    <i className="fas fa-arrow-left"></i>
                  </Button>
                </LinkContainer>
              </h1>
            </Col>
            <Col className="text-end" xs={3}>
              {excelCreation ? (
                <Loader />
              ) : (
                <>
                  <Button
                    className="my-3"
                    variant="transparent"
                    style={{ color: "red" }}
                    onClick={(e) => excelOutput()}
                  >
                    <i
                      className="fas fa-file-excel"
                      style={{ color: "green" }}
                    ></i>
                  </Button>
                  <Button
                    className="my-3  w-75"
                    variant="primary"
                    onClick={handleClick}
                  >
                    {format(startDate, "yyyy-MM-dd")}
                  </Button>
                  {isOpen && (
                    <DatePicker
                      selected={startDate}
                      onChange={handleChange}
                      dateFormat="yyyy-MM-dd"
                      inline
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
          <FlowChart
            nodes={nodes}
            edges={edges}
            nodes2={nodes2}
            edges2={edges2}
            nodes3={nodes3}
            edges3={edges3}
            ahu_count={gatewaydiagram.ahu_count}
            admin_screen={false}
          />
        </>
      )}
    </>
  );
}

export default UserGatewayDiagramScreen;
