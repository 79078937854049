import {
  COMPLEX_DATA_REQUEST,
  COMPLEX_DATA_SUCCESS,
  COMPLEX_DATA_FAIL,
  COMPLEX_UPDATE_REQUEST,
  COMPLEX_UPDATE_SUCCESS,
  COMPLEX_UPDATE_FAIL,
  CO2_DATA_REQUEST,
  CO2_DATA_SUCCESS,
  CO2_DATA_FAIL,
  CO2_UPDATE_REQUEST,
  CO2_UPDATE_SUCCESS,
  CO2_UPDATE_FAIL,
} from "../constants/demoConstants";
import axios from "axios";

export const getComplexDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: COMPLEX_DATA_REQUEST,
    });

    const { data } = await axios.get(`/api/devices/demo-complex/`, {});

    dispatch({
      type: COMPLEX_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPLEX_DATA_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getComplexUpdates = () => async (dispatch) => {
  try {
    dispatch({
      type: COMPLEX_UPDATE_REQUEST,
    });

    const { data } = await axios.get(`/api/devices/demo-complex/`, {});

    dispatch({
      type: COMPLEX_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPLEX_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getCO2Details = () => async (dispatch) => {
  try {
    dispatch({
      type: CO2_DATA_REQUEST,
    });

    const { data } = await axios.get(`/api/devices/demo-co2/`, {});

    dispatch({
      type: CO2_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CO2_DATA_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getCO2Updates = () => async (dispatch) => {
  try {
    dispatch({
      type: CO2_UPDATE_REQUEST,
    });

    const { data } = await axios.get(`/api/devices/demo-co2/`, {});

    dispatch({
      type: CO2_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CO2_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
