import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import Product from "../../components/Product";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

import { getProducts } from "../../actions/productActions";
import { useGlobal } from "reactn";

function ProductsScreen() {
  const dispatch = useDispatch();
  const productsState = useSelector((state) => state.productsState);
  const { loading, success, error, products } = productsState;

  const [language, setLanguage] = useGlobal("language");

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        success && (
          <div>
            <Row className="mb-5">
              {products.map((product) => (
                <Col key={product.id} sm={12} md={6} lg={4} xl={3}>
                  {language == "en" ? (
                    <Product product={product} language="en" />
                  ) : (
                    <Product product={product} language="tr" />
                  )}
                </Col>
              ))}
            </Row>
          </div>
        )
      )}
    </div>
  );
}

export default ProductsScreen;
