import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { getUserCustomers } from "../../actions/userActions";
import FormContainer from "../../components/FormContainer";
import { useTranslation } from "react-i18next";

function AdminCreateSensorScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [customer, setCustomer] = useState(-1);
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const customerState = useSelector((state) => state.userCustomer);
  const {
    error: errorCustomers,
    loading: loadingCustomers,
    success: customerSuccess,
    customers,
  } = customerState;

  useEffect(() => {
    if (userInfo && userInfo.is_staff) {
      dispatch(getUserCustomers());
    } else if (!userInfo) {
      navigate("/login");
    } else if (!userInfo.is_staff) {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo]);

  const editSensor = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const postData = {
        customer,
        uid: id,
        name,
      };

      await axios.post(`/api/devices/sensor-create/`, postData, config);
    } catch (error) {}

    navigate(`/staff/sensors`);
  };

  return (
    <div>
      <h1>{t("create_sensor")}</h1>
      {loadingCustomers ? (
        <Loader />
      ) : errorCustomers ? (
        <Message variant="danger">{errorCustomers}</Message>
      ) : (
        customerSuccess && (
          <FormContainer>
            <Form onSubmit={editSensor}>
              <Form.Group controlId="text">
                <Form.Label>{t("name")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("enter_name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="text">
                <Form.Label>{t("device_id")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("enter_device_id")}
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="customer">
                <Form.Label>{t("company")}:</Form.Label>
                <Form.Select
                  value={customer}
                  onChange={(e) => setCustomer(e.target.value)}
                >
                  <option value="-1" key="-1">
                    ---
                  </option>
                  {customers.map((customer) => (
                    <option value={customer.id} key={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Button className="mt-2" type="submit" variant="primary">
                {t("save")}
              </Button>
            </Form>
          </FormContainer>
        )
      )}
    </div>
  );
}

export default AdminCreateSensorScreen;
