import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

function SearchBox(is_staff) {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const params = useParams();
  const productId = params.id;

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword) {
      if (is_staff) {
        navigate(`/staff/projects/${productId}/?keyword=${keyword}`);
      } else {
        navigate(`/user/projects/${productId}/?keyword=${keyword}`);
      }
    } else {
      navigate(`/staff/projects/${productId}/`);
    }
  };
  return (
    <Form onSubmit={submitHandler} className="d-flex">
      <Form.Control
        type="text"
        name="keyword"
        onChange={(e) => setKeyword(e.target.value)}
        className="mr-sm-2 ml-sm-5"
      ></Form.Control>

      <Button type="submit" variant="outline-success" className="mx-2 p-2">
        Submit
      </Button>
    </Form>
  );
}

export default SearchBox;
