import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import FormContainer from "../../components/FormContainer";
import { useTranslation } from "react-i18next";

function AddPictureScreen() {
  const params = useParams();
  const projectId = params.projectid;
  const unitId = params.unitid;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [image, setImage] = useState("");
  const [comment, setComment] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("comment", comment);
    formData.append("unit_id", unitId);
    try {
      const config = {
        headers: {
          "Content-type": `multipart/form-data`,
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post(`/api/service/upload-comment/`, formData, config);
    } catch (error) {
      console.log(error);
    }
    navigate(`/staff/projects/${projectId}/${unitId}`);
  };

  return (
    <div>
      <FormContainer>
        <h1>{t("new_picture")}</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="image">
            <Form.Label>{t("picture")}:</Form.Label>
            <Form.Control
              className="my-3"
              type="file"
              label={t("picture")}
              custom="true"
              onChange={(e) => setImage(e.target.files[0])}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>{t("comment")}:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={t("comment")}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button className="mt-2" type="submit" variant="primary">
            {t("save")}
          </Button>
        </Form>
      </FormContainer>
    </div>
  );
}

export default AddPictureScreen;
