import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import Chart from "../../components/Chart";
import { getEIRDetails, getEIRUpdates } from "../../actions/deviceActions";
import { LinkContainer } from "react-router-bootstrap";
import moment from "moment";
import "moment-timezone";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";

function AdminEIRDetailsScreen() {
  const params = useParams();
  const { t } = useTranslation();
  const productId = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data_1, setData_1] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [timezone, setTimezone] = useState({});
  const [globalTimezone, setGlobalTimezone] = useGlobal("globalTimezone");

  useEffect(() => {
    const timeZoneFromStorage = localStorage.getItem("timeZone")
      ? JSON.parse(localStorage.getItem("timeZone"))
      : null;

    if (
      timeZoneFromStorage != null &&
      timezone.value != timeZoneFromStorage.value
    ) {
      setTimezone(timeZoneFromStorage);
    }
  }, [globalTimezone]);

  const changeDatetimeByTimezone = (datetime, timezone) => {
    if (timezone == null) {
      timezone = "Europe/Moscow";
    }
    return moment.utc(datetime).tz(timezone).format();
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const detailsEIR = useSelector((state) => state.detailsEIR);
  const { loading, error, success, eirdetails } = detailsEIR;

  const updatesEIR = useSelector((state) => state.updatesEIR);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    eirupdates,
  } = updatesEIR;

  useEffect(() => {
    if (updateSuccess) {
      setUpdateData(eirupdates);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (success) {
      setUpdateData(eirdetails);
      setUpdating(true);
    }
  }, [success]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userInfo && userInfo.is_staff) {
        dispatch(getEIRUpdates(productId));
      }
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.is_staff) {
      dispatch(getEIRDetails(productId));
    } else if (!userInfo) {
      navigate("/login");
    } else if (!userInfo.is_staff) {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo, productId]);

  useEffect(() => {
    if (success) {
      let data_1 = [];
      eirdetails.related_data.forEach((data) => {
        let obj_1 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          HEPA: Number(data.data_2),
          G3: Number(data.data_3),
        };

        data_1.push(obj_1);
      });

      setData_1(data_1.reverse());
    }
  }, [success, eirdetails, timezone]);

  useEffect(() => {
    if (updateSuccess) {
      let data_1 = [];
      eirupdates.related_data.forEach((data) => {
        let obj_1 = {
          name: changeDatetimeByTimezone(data.posted, timezone.value).substring(
            11,
            19
          ),
          HEPA: Number(data.data_2),
          G3: Number(data.data_3),
        };

        data_1.push(obj_1);
      });

      setData_1(data_1.reverse());
    }
  }, [updateSuccess, eirupdates, timezone]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        updating && (
          <>
            <h1>
              {eirdetails.name}

              <LinkContainer to={`/staff/eir/${eirdetails.id}/edit`}>
                <Button variant="transparent" style={{ color: "red" }}>
                  <i className="fas fa-pen-to-square"></i>
                </Button>
              </LinkContainer>
            </h1>
            <Row>
              <Col xs={12}>
                <Chart data={data_1} type={5} />
              </Col>
            </Row>

            <Table striped responsive hover className="table-sm">
              <thead>
                <tr>
                  <th className="text-center">{t("eir_state")}</th>
                  <th className="text-center">{t("hepa_drop")}</th>
                  <th className="text-center">{t("g3_drop")}</th>
                  <th className="text-center">{t("hepa_state")}</th>
                  <th className="text-center">{t("g3_state")}</th>
                  <th className="text-center">{t("work_hours")}</th>
                  <th className="text-center">{t("time_date")}</th>
                </tr>
              </thead>
              <tbody>
                {updateData.related_data.map((data) => (
                  <tr key={data.id}>
                    <td className="align-middle text-center">
                      {data.data_1} {eirdetails.data_1_unit.data_unit}
                    </td>
                    <td className="align-middle text-center">
                      {data.data_2} {eirdetails.data_2_unit.data_unit}
                    </td>
                    <td className="align-middle text-center">
                      {data.data_3} {eirdetails.data_3_unit.data_unit}
                    </td>
                    <td className="align-middle text-center">
                      {data.data_4} {eirdetails.data_4_unit.data_unit}
                    </td>
                    <td className="align-middle text-center">
                      {data.data_5} {eirdetails.data_5_unit.data_unit}
                    </td>
                    <td className="align-middle text-center">
                      {data.data_6} {eirdetails.data_6_unit.data_unit}
                    </td>
                    <td className="align-middle text-center">
                      {changeDatetimeByTimezone(
                        data.posted,
                        timezone.value
                      ).substring(11, 19)}{" "}
                      -{" "}
                      {changeDatetimeByTimezone(
                        data.posted,
                        timezone.value
                      ).substring(0, 10)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )
      )}
    </div>
  );
}

export default AdminEIRDetailsScreen;
