import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Row, Col, Image, Tabs, Tab, Table } from "react-bootstrap";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { getProductDetails } from "../../actions/productActions";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";

function SingleProductScreen() {
  const params = useParams();
  const productId = params.id;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [language, setLanguage] = useGlobal("language");
  const productDetailsState = useSelector((state) => state.productDetailsState);
  const { error, loading, success, productDetails } = productDetailsState;

  useEffect(() => {
    dispatch(getProductDetails(productId));
  }, [dispatch, productId]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        success && (
          <div>
            <Row>
              <Col md={5} className="text-center">
                <Image
                  src={productDetails.image}
                  alt={productDetails.name}
                  fluid
                />
              </Col>
              <Col md={7}>
                <Tabs
                  defaultActiveKey="description"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  fill
                >
                  <Tab eventKey="description" title={t("general")}>
                    <h3 className="text-center">{productDetails.name}</h3>
                    {language == "en" ? (
                      <pre className="text-center">
                        {productDetails.description_en}
                      </pre>
                    ) : (
                      <pre className="text-center">
                        {productDetails.description_tr}
                      </pre>
                    )}
                  </Tab>
                  {(productDetails.datasheet_en ||
                    productDetails.datasheet_tr) && (
                    <Tab eventKey="docs" title={t("documents")}>
                      {productDetails.datasheet_en && (
                        <div className="text-center">
                          <i className="fas fa-file">
                            <a
                              href={productDetails.datasheet_en}
                              className="mx-1"
                              download
                            >
                              {t("en_download")}
                            </a>
                          </i>
                        </div>
                      )}
                      {productDetails.datasheet_tr && (
                        <div className="text-center">
                          <i className="fas fa-file">
                            <a
                              href={productDetails.datasheet_tr}
                              className="mx-1"
                              download
                            >
                              {t("tr_download")}
                            </a>
                          </i>
                        </div>
                      )}
                    </Tab>
                  )}
                </Tabs>
              </Col>
            </Row>
          </div>
        )
      )}
    </div>
  );
}

export default SingleProductScreen;
