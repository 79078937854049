export const USER_GATEWAY_REQUEST = "USER_GATEWAY_REQUEST";
export const USER_GATEWAY_SUCCESS = "USER_GATEWAY_SUCCESS";
export const USER_GATEWAY_FAIL = "USER_GATEWAY_FAIL";
export const USER_GATEWAY_RESET = "USER_GATEWAY_RESET";

export const ADMIN_GATEWAY_REQUEST = "ADMIN_GATEWAY_REQUEST";
export const ADMIN_GATEWAY_SUCCESS = "ADMIN_GATEWAY_SUCCESS";
export const ADMIN_GATEWAY_FAIL = "ADMIN_GATEWAY_FAIL";
export const ADMIN_GATEWAY_RESET = "ADMIN_GATEWAY_RESET";

export const GATEWAY_DATA_REQUEST = "GATEWAY_DATA_REQUEST";
export const GATEWAY_DATA_SUCCESS = "GATEWAY_DATA_SUCCESS";
export const GATEWAY_DATA_FAIL = "GATEWAY_DATA_FAIL";

export const GATEWAY_UPDATE_REQUEST = "GATEWAY_UPDATE_REQUEST";
export const GATEWAY_UPDATE_SUCCESS = "GATEWAY_UPDATE_SUCCESS";
export const GATEWAY_UPDATE_FAIL = "GATEWAY_UPDATE_FAIL";

export const GATEWAY_DIAGRAM_REQUEST = "GATEWAY_DIAGRAM_REQUEST";
export const GATEWAY_DIAGRAM_SUCCESS = "GATEWAY_DIAGRAM_SUCCESS";
export const GATEWAY_DIAGRAM_FAIL = "GATEWAY_DIAGRAM_FAIL";

export const GATEWAY_DIAGRAM_UPDATE_REQUEST = "GATEWAY_DIAGRAM_UPDATE_REQUEST";
export const GATEWAY_DIAGRAM_UPDATE_SUCCESS = "GATEWAY_DIAGRAM_UPDATE_SUCCESS";
export const GATEWAY_DIAGRAM_UPDATE_FAIL = "GATEWAY_DIAGRAM_UPDATE_FAIL";

export const GATEWAY_GROUP_REQUEST = "GATEWAY_GROUP_REQUEST";
export const GATEWAY_GROUP_SUCCESS = "GATEWAY_GROUP_SUCCESS";
export const GATEWAY_GROUP_FAIL = "GATEWAY_GROUP_FAIL";

export const GATEWAY_GROUP_UPDATE_REQUEST = "GATEWAY_GROUP_UPDATE_REQUEST";
export const GATEWAY_GROUP_UPDATE_SUCCESS = "GATEWAY_GROUP_UPDATE_SUCCESS";
export const GATEWAY_GROUP_UPDATE_FAIL = "GATEWAY_GROUP_UPDATE_FAIL";

export const USER_BOILER_REQUEST = "USER_BOILER_REQUEST";
export const USER_BOILER_SUCCESS = "USER_BOILER_SUCCESS";
export const USER_BOILER_FAIL = "USER_BOILER_FAIL";
export const USER_BOILER_RESET = "USER_BOILER_RESET";

export const ADMIN_BOILER_REQUEST = "ADMIN_BOILER_REQUEST";
export const ADMIN_BOILER_SUCCESS = "ADMIN_BOILER_SUCCESS";
export const ADMIN_BOILER_FAIL = "ADMIN_BOILER_FAIL";
export const ADMIN_BOILER_RESET = "ADMIN_BOILER_RESET";

export const BOILER_ERRORS_REQUEST = "BOILER_ERRORS_REQUEST";
export const BOILER_ERRORS_SUCCESS = "BOILER_ERRORS_SUCCESS";
export const BOILER_ERRORS_FAIL = "BOILER_ERRORS_FAIL";
export const BOILER_ERRORS_RESET = "BOILER_ERRORS_RESET";
